import { actionTypes } from "react-redux-firebase";
import { call, takeEvery } from "redux-saga/effects";

// Local
import analytics from "../../analytics";

// Types
import type { SagaIterator } from "redux-saga";

function* logout(): SagaIterator {
  yield call(analytics.logout);
}

export default function* logoutSaga(): SagaIterator {
  yield takeEvery(actionTypes.LOGOUT, logout);
}
