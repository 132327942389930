import { DateTime } from "luxon";
import firebase from "firebase/compat/app";
// Store
import { appStore } from "../../index";
// Locals
import { defaultDateFormatOption } from "../../workspace/detail/dateFormatOptionSelect";

export const getDisplayDateFormat = (): string => {
  const dateFormatFromDB = appStore.getState().firestore.data?.currentWsDetail?.dateFormat;
  return dateFormatFromDB || defaultDateFormatOption;
};

// Used for formatting the value given by MUI Date pickers
export const dateToFormatted = (dateFromInput: DateTime): string =>
  dateFromInput.toFormat(getDisplayDateFormat());

export const firestoreTimestampToFormatted = (
  date?: firebase.firestore.Timestamp | null,
  dateFormat?: string,
): string => {
  if (!date?.seconds) return "";
  return DateTime.fromSeconds(date.seconds).toFormat(dateFormat ?? defaultDateFormatOption);
};

export const getCurrentYear = (): string => DateTime.utc().year.toString();

// Used for updating item
// Making sure it gets timestamp or null
export const firebaseTimestampOrNull = (
  value?: firebase.firestore.Timestamp | DateTime | null,
): firebase.firestore.Timestamp | null => {
  if (value) {
    return value instanceof DateTime
      ? firebase.firestore.Timestamp.fromMillis(
          DateTime.fromObject({
            ...value.toObject(),
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
            zone: "utc",
          }).toMillis(),
        )
      : value;
  }
  return null;
};

export const nonEmptyStringOrNull = (value?: string | null): string | null => value || null;

export const enforceObject = (value?: Record<string, boolean> | null): Record<string, boolean> =>
  value || {};

export const camelCaseToText = (field: string): string => {
  const result = field.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
