import React from "react";
import firebase from "firebase/compat/app";

// Material UI
import MenuItem from "@material-ui/core/MenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Locals
import { menuItemIconMargin } from "../../globalStyles";

const LogoutMenuItem: React.FC = () => (
  <MenuItem onClick={() => firebase.auth().signOut()} data-cy="menu-user-logout">
    <ExitToAppIcon style={menuItemIconMargin} />
    Logout
  </MenuItem>
);

export default LogoutMenuItem;
