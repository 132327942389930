import { Option } from "./types";
import { EnqueueSnackbar } from "../snackbar/hooks";

// Checks if inputValue isn't already one of options title and returns boolean
export const isNotAlreadyAnOption = (options: Option[], inputValue: string): boolean =>
  options.every((option) => option.title !== inputValue);

export const hideDuplicates = (
  newValuesArray: (string | Option)[],
  enqueueSnackbar: EnqueueSnackbar,
): string[] =>
  newValuesArray.reduce<string[]>((arr, item: Option | string) => {
    let showWarningSnack = false;
    if (typeof item === "string") {
      if (!arr.includes(item)) {
        // Adding new value
        arr.push(item);
      } else {
        showWarningSnack = true;
      }
    } else if (item.inputValue) {
      if (!arr.includes(item.inputValue)) {
        // Adding new value
        arr.push(item.inputValue);
      } else {
        showWarningSnack = true;
      }
    } else if (!arr.includes(item.title)) {
      // Already existing value
      arr.push(item.title);
    } else {
      showWarningSnack = true;
    }
    if (showWarningSnack)
      enqueueSnackbar("New value cannot be added because it might already exists.", "warning");
    return arr;
  }, []);
