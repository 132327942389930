import type { ReactReduxFirebaseConfig } from "react-redux-firebase";

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  databaseURL: string;
  storageBucket: string;
}

/**
 * Get current firebase config
 */
const firebase = (): FirebaseConfig =>
  JSON.parse(
    (process.env.REACT_APP_ENV === "dev"
      ? process.env.REACT_APP_FIREBASE_CONFIG_DEV
      : process.env.REACT_APP_FIREBASE_CONFIG_PROD) || "{}",
  );

const firebaseFunctions = {
  location: "europe-west1",
};

const email = {
  support: "support@listemiq.com",
  info: "info@listemiq.com",
};

const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  enableLogging: true,
  userProfile: "users",
  useFirestoreForProfile: true,
};

const productionDomain = "app.listemiq.com";

const config = {
  firebase,
  rrfConfig,
  firebaseFunctions,
  productionDomain,
  email,
};

export default config;
