import React from "react";
import TextField from "@material-ui/core/TextField";
import { FilterSpecification, FilterType } from "./types";
import useStyles from "./styles";

interface Props {
  field: string;
  label: string;
  onChange: (newValue: FilterSpecification, field: string) => void;
  value?: FilterSpecification | null;
  focus?: boolean;
}

const FilterContainsString: React.FC<Props> = ({ field, focus, label, onChange, value }) => {
  const classes = useStyles();
  const handleOnChange = React.useCallback(
    (event) => {
      const { value: newValue } = event.target;
      onChange({ containsStr: newValue, filterType: FilterType.ContainsStr }, field);
    },
    [field, onChange],
  );

  return (
    <>
      <span className={classes.filterLabel}>{label}</span>
      <TextField
        autoComplete="off"
        autoFocus={focus}
        onChange={handleOnChange}
        value={value?.containsStr || ""}
        // chrome and firefox overrids autocomplete when name === "name"
        name={field === "name" ? "lname" : field}
        placeholder={`Search ${label}...`}
        inputProps={{ className: classes.filterContainStringInput }}
      />
    </>
  );
};

export default FilterContainsString;
