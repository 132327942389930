import React from "react";

import firebase from "firebase/compat/app";
import { useParams } from "react-router";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { ResponsivePie } from "@nivo/pie";

import { camelCaseToText } from "../../helpers/format";
import useStyles from "../styles";
import { CheckboxMenu } from "../../components";
import { itemKeys } from "../../items/types";
import { WsParams } from "../../routes";
import { useUpdateWorkspace } from "../api";
import { useCurrentWsDetail } from "../../firebase";

interface IProps {
  data: Record<string, { id: string; value: number }[]>;
}

const ChartStatistics: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();
  const shownItemKeys = Object.keys(data);
  const { workspaceId } = useParams<WsParams>();
  const { statisticsToShow = [] } = useCurrentWsDetail();
  const updateWorkspace = useUpdateWorkspace();

  // #https://gitlab.com/profiq/all/inventory-manager/inventory/-/issues/214
  // const hideValue = React.useCallback((field: string, value: string) => {
  //   //firestore().doc(`${workspaceId}/`)
  // }, []);

  const handleItemSelected = React.useCallback(
    (field: string, checked: boolean) => {
      if (checked) {
        updateWorkspace(
          {
            statisticsToShow: firebase.firestore.FieldValue.arrayUnion({
              type: "pie",
              field,
              hiddenValues: [],
            }),
          },
          workspaceId,
        );
      } else {
        const currentStatisticData = statisticsToShow.find(
          (stat) => stat.type === "pie" && stat.field === field,
        );
        updateWorkspace(
          {
            statisticsToShow: firebase.firestore.FieldValue.arrayRemove(currentStatisticData),
          },
          workspaceId,
        );
      }
    },
    [workspaceId, updateWorkspace, statisticsToShow],
  );

  return (
    <>
      <Typography variant="h5" className={classes.interactiveTitle}>
        Chart statistics
        <CheckboxMenu
          handleItemSelected={handleItemSelected}
          options={itemKeys.map((key) => ({
            title: camelCaseToText(key),
            value: key,
            checked: shownItemKeys.includes(key),
          }))}
        />
      </Typography>
      <br />
      <Grid container justify="space-around">
        {Object.entries(data).map(([field, fieldData]) => (
          <Grid item xs={4} style={{ height: 400, marginTop: 40 }} key={field}>
            <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
              {camelCaseToText(field)}
            </Typography>
            <ResponsivePie
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              data={fieldData}
              margin={{ top: 20, right: 40, bottom: 80, left: 40 }}
              innerRadius={0.1}
              padAngle={1}
              theme={{ labels: { text: { fontSize: 16 } }, legends: { text: { fontSize: 16 } } }}
              colors={{ scheme: "blues" }}
              borderWidth={1}
              radialLabelsTextXOffset={6}
              radialLabelsLinkDiagonalLength={16}
              radialLabelsLinkHorizontalLength={24}
              radialLabelsLinkStrokeWidth={2}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  translateY: 56,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "black",
                  symbolSize: 18,
                  symbolShape: "circle",
                  // onClick: ({ id }) => hideValue(field, id as string),
                },
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ChartStatistics;
