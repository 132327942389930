import React from "react";

// MUI
import HistoryIcon from "@material-ui/icons/History";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

// Locals
import Items from "./items";
import { firestoreQuery } from "../../constants";
import { camelCaseToText } from "../../helpers/format";

// Hooks
import useStyles from "./styles";
import { useLoading, useCurrentWsDetail } from "../../firebase";

// Types
import type { HistoryItemType } from "./types";
import Typography from "@material-ui/core/Typography";

const baseFields: Record<string, string[]> = {
  item: [
    "name",
    "serialNumber",
    "internalNumber",
    "invoiceNumber",
    "notes",
    "user",
    "bought",
    "warrantyUntil",
    "extendedWarranty",
  ],
  user: ["displayName", "role", "item", "notificationSettings"],
};

interface Props {
  type: "item" | "user";
  data: HistoryItemType[];
  hideTitle?: boolean;
  expandable?: boolean;
}

const TimeLine: React.FC<Props> = ({ hideTitle, data, expandable = true, type }) => {
  const classes = useStyles();
  const [filterField, setFilterField] = React.useState<string>("All");
  const { fields } = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);

  const currentBaseFields = baseFields[type];
  const options =
    type === "item" && fields ? currentBaseFields.concat(Object.keys(fields)) : currentBaseFields;

  const filteredData = React.useMemo(() => {
    const newData = [...data];
    return (filterField === "All"
      ? newData
      : newData.filter((item) => item.field === filterField)
    ).reverse();
  }, [data, filterField]);

  return (
    <div>
      <div className={classes.header}>
        {hideTitle ? (
          <Tooltip title="History of the changes of the item. You can also filter based on the specific field">
            <HistoryIcon className={classes.icon} />
          </Tooltip>
        ) : (
          <Typography className={`myitems-history-title ${classes.title}`}>History</Typography>
        )}
        {!loading && (
          <TextField
            onChange={(e) => setFilterField(e.target.value)}
            select
            value={filterField}
            size="small"
          >
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {camelCaseToText(option)}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <Items data={filteredData} expandable={expandable} />
    </div>
  );
};

export default TimeLine;
