import React from "react";

// Material UI
import Button from "@material-ui/core/Button";
import type { ButtonProps } from "@material-ui/core/Button";

// Locals
import Loading from "../../components/loading";

interface Props extends ButtonProps {
  loading?: boolean;
}

const ConfirmButton: React.FC<Props> = React.forwardRef(
  ({ children, loading, ...props }, ref) => (
    <Button
      disabled={loading}
      data-cy={"btn-confirm"}
      ref={ref}
      variant="contained"
      color="secondary"
      {...props}
    >
      {loading ? <Loading size={19} /> : children}
    </Button>
  ),
);

export default ConfirmButton;
