import { spawn } from "redux-saga/effects";

// Local
import { loginSaga } from "./login";
import { logoutSaga } from "./logout";

// Type
import type { SagaIterator } from "redux-saga";

export default function* authenticationSaga(): SagaIterator {
  yield spawn(loginSaga);
  yield spawn(logoutSaga);
}
