import routes from "../../routes";

// path for redirecting to based on type prop
export const getRedirectPath = (
  type: "item" | "userInfoItem",
  workspaceId: string,
  userId?: string | null,
): string => {
  if (!workspaceId) return "";

  if (type === "item") return routes.workspaceItems(workspaceId);

  if (!userId) return "";

  return routes.workspaceUserInfo(workspaceId, userId);
};

/**
 * Used to generate url route to redirect back from upload dialog.
 * @param type
 * @param workspaceId
 * @param itemId
 * @param userId
 */
export const getRedirectPathFromAttachFiles = (
  workspaceId: string,
  itemId: string,
  type?: "item" | "userInfoItem",
  userId?: string | null,
): string => {
  if (!type || !workspaceId || !itemId) return "";

  if (type === "item") return routes.workspaceItemsDetail(workspaceId, itemId);

  if (!userId) return "";

  return routes.workspaceUserInfoItemDetail(workspaceId, userId, itemId);
};

/**
 * Used for generate url to open upload dialog.
 * For example it can differ if we want to open the dialog in "Items" section or in "My Items" section.
 * @param type
 * @param workspaceId
 * @param itemId
 * @param userId
 */
export const getUploadDialogPath = (
  workspaceId: string,
  itemId: string,
  type?: "item" | "userInfoItem",
  userId?: string | null,
): string => {
  if (!type) return "";

  if (type === "item") return routes.workspaceItemsDetailAttachFiles(workspaceId, itemId);

  if (!userId) return "";

  return routes.workspaceUserInfoItemDetailAttachFiles(workspaceId, userId, itemId);
};

// open prop based on type and pathname
export const shouldOpenDrawer = (
  type: "item" | "userInfoItem",
  pathname: string,
  workspaceId?: string,
  userId?: string,
  itemId?: string,
): boolean => {
  if (!workspaceId || !itemId) return false;

  const isRelevantPathToItem =
    pathname === routes.workspaceItemsDetail(workspaceId, itemId) ||
    pathname === routes.workspaceItemsDetailAttachFiles(workspaceId, itemId);
  const isRelevantPathToUserInfoItem =
    pathname === routes.workspaceUserInfoItemDetail(workspaceId, userId, itemId) ||
    pathname === routes.workspaceUserInfoItemDetailAttachFiles(workspaceId, userId, itemId);

  switch (type) {
    case "item":
      return itemId !== "add" && isRelevantPathToItem;

    case "userInfoItem":
      return !!userId && isRelevantPathToUserInfoItem;

    default:
      return false;
  }
};

/**
 * Used in single item body to verify if drawer should be closed on click away for example.
 * @param pathname
 * @param workspaceId
 * @param itemId
 * @param userId
 */
export const avoidClosing = (
  pathname: string,
  workspaceId?: string,
  itemId?: string,
  userId?: string,
): boolean => {
  if (!workspaceId || !itemId) return false;
  return (
    pathname === routes.workspaceItemsDetailAttachFiles(workspaceId, itemId) ||
    pathname === routes.workspaceUserInfoItemDetailAttachFiles(workspaceId, userId, itemId)
  );
};
