import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    // Center card on page
    marginLeft: "auto",
    marginRight: "auto",
  },

  createButton: {
    // Move to the right
    marginLeft: "auto",
  },
}));

export default useStyles;
