import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

// MUI
import Typography from "@material-ui/core/Typography";

// Locals
import { firestoreQuery, localStorageKey } from "../../constants";
import { useUserId } from "../../authentication/hooks";
import { useData } from "../../firebase/hooks";
import useStyles from "../styles";

// Types
import type { SingleItemState } from "../../items/types";
import type { WsParams } from "../../routes";
import { Grid } from "@material-ui/core";

const RecentlyViewedItems: React.FC = () => {
  const classes = useStyles();

  const { workspaceId } = useParams<WsParams>();
  const userId = useUserId();
  const items = useData<Record<string, SingleItemState>>(firestoreQuery.wsItems);

  const lastItems = React.useMemo<React.ElementType[]>(() => {
    if (items && userId) {
      const lastItemsString =
        localStorage.getItem(localStorageKey.lastItems(workspaceId, userId)) ?? "[]";
      const lastItems = JSON.parse(lastItemsString);
      return lastItems
        .filter((itemId: string) => items[itemId])
        .map((itemId: string) => {
          const itemName = items[itemId].name;
          return (
            <Link className={classes.link} to={`/ws/${workspaceId}/items/${itemId}`} key={itemId}>
              {itemName}
            </Link>
          );
        });
    }
    return [];
  }, [classes.link, items, userId, workspaceId]);

  return (
    <>
      <Typography variant="h5">Items You have recently viewed</Typography>
      <br />
      <Grid data-cy="home-recently-viewed" container direction="column">
        {lastItems.length ? (
          lastItems
        ) : (
          <Typography variant="h6" align="center">
            None
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default RecentlyViewedItems;
