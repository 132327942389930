import { actionTypes } from "react-redux-firebase";
import { call, takeEvery } from "redux-saga/effects";
import firebase from "firebase/compat/app";

// Local
import analytics from "../../analytics";

// Types
import type { Action } from "redux";
import type { SagaIterator } from "redux-saga";

interface LoginAction extends Action {
  auth: firebase.User;
}

function* login({ auth: { uid } }: LoginAction): SagaIterator {
  yield call(analytics.login, uid);
}

export default function* loginSaga(): SagaIterator {
  yield takeEvery(actionTypes.LOGIN, login);
}
