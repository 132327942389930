import React from "react";

import { Route } from "react-router";

import TableActionAddItem from "./tableActionAddItem";
import defaultColumns from "./columns";
import { prepareDataForTable } from "./helpers";
import Table from "../../components/table";
import { useCurrentWsDetail, useData, useLoading } from "../../firebase";
import { SingleItemState } from "../types";
import { User } from "../../users/types";
import routes from "../../routes";
import { RowData } from "../../components/table/types";
import { firestoreQuery } from "../../constants";
import { useIsAdmin } from "../../authentication/hooks";
import ConfirmationDialog from "../../components/confirmationDialog";
import { useRemoveItem } from "../api";

interface IProps {
  workspaceId?: string;
  push: (path: string) => void;
}

const ItemTable: React.FC<IProps> = ({ workspaceId, push }) => {
  const { isAdmin } = useIsAdmin();
  const removeItem = useRemoveItem();
  const { archivedStatusList, name, dateFormat } = useCurrentWsDetail() ?? {};
  const loading = useLoading([firestoreQuery.wsItems, firestoreQuery.wsUsers]);
  const items = useData<Record<string, SingleItemState>>(firestoreQuery.wsItems);
  const users = useData<User>(firestoreQuery.wsUsers);
  const [idToDelete, setIdToDelete] = React.useState<string | undefined>();

  const handleDeleteItem = React.useCallback(() => {
    setIdToDelete(undefined);
    workspaceId && removeItem(workspaceId, idToDelete as string);
  }, [idToDelete, removeItem, workspaceId]);

  const onRowClick = React.useCallback(
    (_: unknown, selectedRow?: RowData) =>
      push(routes.workspaceItemsDetail(workspaceId, selectedRow?.id)),
    [push, workspaceId],
  );

  const tableActions = React.useMemo(
    () =>
      isAdmin
        ? [
            {
              icon: "queue",
              tooltip: "Edit SubItems",
              onClick: (_: unknown, row: RowData | RowData[]) =>
                push(routes.addSubItemToWorkspaceItem(workspaceId, (row as RowData).id)),
            },
            {
              icon: "delete",
              tooltip: "Remove item",
              onClick: (_: unknown, row: RowData | RowData[]) => {
                if (!(row as RowData).id || !workspaceId) return null;
                setIdToDelete((row as RowData).id);
              },
            },
          ]
        : [],
    [isAdmin, push, workspaceId],
  );

  const columns = React.useMemo(() => defaultColumns(dateFormat), [dateFormat]);

  return (
    <>
      <ConfirmationDialog
        open={Boolean(idToDelete)}
        title="Do you really want to delete this item?"
        message="Clicking continue will permanently delete the item from database."
        onCancel={() => setIdToDelete(undefined)}
        onConfirm={handleDeleteItem}
      />
      <Route>
        {({ location, history }) => (
          <Table
            toolbarProps={{
              addDataComponent: <TableActionAddItem />,
              filtering: true,
            }}
            title={`${name} - Items`}
            columns={columns}
            data={prepareDataForTable(items, users, archivedStatusList)}
            secondaryData={prepareDataForTable(items, users, archivedStatusList, true)}
            isLoading={loading}
            onRowClick={onRowClick}
            type="item"
            enableSwitch
            actions={tableActions}
            search={location.search}
            replace={history.replace}
          />
        )}
      </Route>
    </>
  );
};

export default ItemTable;
