import { DateTime } from "luxon";
import firebase from "firebase/compat/app";

import { Role, userRoles } from "../../users/userRoles";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isNameLengthOk = (name: string, maxLength = 30): boolean =>
  name.length >= 3 && name.length <= maxLength;

const isNameValid = (name: string, maxLength = 30): boolean =>
  !!name && isNameLengthOk(name, maxLength);

const isValidDate = (date: DateTime | firebase.firestore.Timestamp): boolean => {
  const newDate =
    date instanceof DateTime
      ? firebase.firestore.Timestamp.fromDate((date as DateTime).toJSDate())
      : date;
  return !isNaN(newDate.seconds);
};

const isValidEmail = (email: string): boolean => emailRegex.test(email);

const isValidRole = (role: string): boolean => userRoles.includes(role as Role);

export { isNameLengthOk, isNameValid, isValidDate, isValidEmail, isValidRole };
