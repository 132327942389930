import makeStyles from "@material-ui/core/styles/makeStyles";

import { userTableMaxWidth } from "../globalStyles";

const useStyles = makeStyles((theme) => ({
  nameField: {
    display: "flex",
  },
  nameGrid: {
    display: "inline-grid",
    "grid-template-columns": "auto auto",
  },
  nameText: {
    margin: "auto",
    paddingLeft: 12,
  },
  formControlRole: {
    minWidth: 80,
  },
  content: {
    display: "grid",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  addButton: {
    backgroundColor: theme.palette.secondary.light,
  },
  tableWrap: {
    maxWidth: userTableMaxWidth,
    marginLeft: "auto",
    marginRight: "auto",
  },

  addUserBody: {
    minWidth: 600,
    padding: theme.spacing(5),
    // content layout
    display: "grid",
    grid: "auto-flow / 3fr 1fr 1fr .4fr",
    gridGap: theme.spacing(3, 4),
  },
  removeActionsMarginTop: {
    marginTop: -30,
  },
}));

export default useStyles;
