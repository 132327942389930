import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getRedirectPathFromAttachFiles, getUploadDialogPath } from "./handlers";

// Locals
import { UploadDialog } from "../../components/fileUpload";
import routes from "../../routes";
import { useAttachFilesToItem } from "../api";
import useCustomSnackbar from "../../components/snackbar";

// Types
import type { WsItemParams } from "../../routes";

/**
 * @params onUploadCb Function invoked after clicking upload.
 * @params type Important for generating proper url paths.
 */
interface Props {
  onUploadCb: () => void;
  type?: "item" | "userInfoItem";
}

const AttachFiles: React.FC<Props> = ({ onUploadCb, type }) => {
  // Hooks
  const attachFiles = useAttachFilesToItem();
  const { push } = useHistory();
  const { enqueueSnackbar } = useCustomSnackbar();
  // Url params
  const { itemId, userId, workspaceId } = useParams<WsItemParams>();
  // Local state
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleOnChange = React.useCallback((files) => {
    setFiles(files);
  }, []);

  const handleOnDelete = React.useCallback(
    (deletedFile: File) => {
      const newFiles = files.filter((file) => file !== deletedFile);
      setFiles(newFiles);
    },
    [files],
  );

  const handleOnConfirmClick = React.useCallback(async () => {
    if (!files || !files.length) enqueueSnackbar("There are no files selected.", "info");
    if (!workspaceId || !itemId || !files || !files.length) return;

    setLoading(true);

    try {
      await attachFiles(files, workspaceId, itemId);
      enqueueSnackbar("Files uploaded successfully", "success");
      setFiles([]);
      setLoading(false);
      onUploadCb();
      push(routes.workspaceItemsDetail(workspaceId, itemId));
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [attachFiles, enqueueSnackbar, files, itemId, onUploadCb, push, workspaceId]);

  return (
    <UploadDialog
      onConfirm={handleOnConfirmClick}
      onChange={handleOnChange}
      onDelete={handleOnDelete}
      loading={loading}
      redirectTo={getUploadDialogPath(workspaceId, itemId, type, userId)}
      redirectBack={getRedirectPathFromAttachFiles(workspaceId, itemId, type, userId)}
    />
  );
};

export default AttachFiles;
