import makeStyles from "@material-ui/core/styles/makeStyles";
import { cardLookStyle } from "../globalStyles";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      display: "flex",
    },
    "& .ReactVirtualized__Table__row": {
      borderTop: "1px solid rgba(224, 224, 224, 1)",
      display: "flex",
      "&:focus": {
        outline: "none",
      },
    },
  },
  flexContainer: {
    display: "flex",
    height: theme.spacing(6),
  },
  wrapper: {
    ...cardLookStyle,
    backgroundColor: "white",
    marginTop: theme.spacing(2),
    height: `calc(100vh - ${theme.spacing(8) + 50}px - 2.375rem)`,
    width: "100%",
    display: "flex",
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  messageRowWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  messageContainer: {
    height: 200,
    overflow: "auto",
    border: "1px solid black",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(0, 3),
  },
}));

export default useStyles;
