import React from "react";

// Material UI
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// Locals
import { ConnectedAutoCompleteSelect } from "../../components/autocompleteSelect";

// Styles
import useStyles from "../styles";

interface Option {
  inputValue?: string;
  value?: string | number;
  title: string;
}

interface Props {
  name: string;
  title: string;
  value?: string;
  autocompleteSelect?: boolean;
  handleOnChange: (value: string, name: string) => void;
  error?: boolean;
  options?: Option[];
  dataCy?: string;
}

const UserInfoEditableDataRow: React.FC<Props> = ({
  name,
  title,
  value,
  autocompleteSelect,
  error,
  options,
  dataCy,
  handleOnChange,
}) => {
  const classes = useStyles();
  const InputTextResize = {
    classes: {
      input: classes.resize,
    },
  };

  const eventOnChangeHandler = React.useCallback(
    (event) => {
      if (!event?.target?.name) return;
      const { name, value } = event.target;
      handleOnChange(value as string, name as string);
    },
    [handleOnChange],
  );

  const textField = (
    <TextField
      name={name}
      data-cy={dataCy}
      size="small"
      onChange={eventOnChangeHandler}
      value={value}
      error={error}
      className={classes.editableInput}
      InputProps={InputTextResize}
    />
  );

  const autocomplete = (
    <ConnectedAutoCompleteSelect
      name={name}
      data-cy={dataCy}
      value={value}
      setValue={handleOnChange}
      options={options}
      className={classes.editableInput}
      size="small"
      error={error}
      InputProps={InputTextResize}
    />
  );

  const InputField = autocompleteSelect ? autocomplete : textField;

  return (
    <tr>
      <th style={{ textAlign: "right" }}>
        <Typography variant="body2" className={classes.smallMarginRight}>
          {title}:
        </Typography>
      </th>
      <th style={{ textAlign: "left" }}>{InputField}</th>
    </tr>
  );
};

export default UserInfoEditableDataRow;
