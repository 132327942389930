import React from "react";

import { Link, useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { useCurrentWsDetail } from "../firebase";
import useStyles from "./styles";
import { firestoreTimestampToFormatted } from "../helpers/format";
import routes from "../routes";
import CurrentInventoryCheck from "./currentInventoryCheck";
import CloseInventoryCheckModal from "./closeInventoryCheckModal";

import type { WsParams } from "../routes";

const InventoryCheckList: React.FC = () => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();
  const { inventoryCheck, dateFormat, activeInventoryCheckId } = useCurrentWsDetail();
  const [showCloseModal, setShowCloseModal] = React.useState<string>();

  return (
    <>
      <CloseInventoryCheckModal
        open={!!showCloseModal}
        inventoryCheckId={showCloseModal}
        close={() => setShowCloseModal(undefined)}
      />
      <div className={classes.title}>
        <Typography variant="h5">Stock-Taking</Typography>
        <Typography variant="subtitle2">
          Check availability of all items in your inventory
        </Typography>
      </div>
      <Button
        disabled={!!activeInventoryCheckId}
        className={classes.addButton}
        color="secondary"
        variant="contained"
        size="medium"
        component={Link}
        to={routes.addInventoryCheck(workspaceId)}
      >
        Create new stock-taking
      </Button>
      <div className={classes.gridCards}>
        <div className={classes.wrapper}>
          {inventoryCheck ? (
            <List>
              {Object.entries(inventoryCheck).map(([id, check]) => (
                <ListItem component={Link} to={routes.inventoryCheckDetail(workspaceId, id)}>
                  <ListItemText
                    primary={
                      firestoreTimestampToFormatted(check.startedAt, dateFormat) +
                      (check.endedAt ? "" : " - In Progress")
                    }
                    secondary={
                      check.endedAt
                        ? `Finished at: ${firestoreTimestampToFormatted(
                            check.endedAt,
                            dateFormat,
                          )}`
                        : `Items verified: ${check.itemsVerifiedCount} / ${check.itemsTotalCount}`
                    }
                  />
                  {check.endedAt ? undefined : (
                    <ListItemSecondaryAction>
                      <Button onClick={() => setShowCloseModal(id)}>Close</Button>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="subtitle1" className={classes.cardTitle}>
              No Stock-Taking history
            </Typography>
          )}
        </div>
        <CurrentInventoryCheck />
      </div>
    </>
  );
};

export default InventoryCheckList;
