import React from "react";

// Material UI
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

// Styles
import useStyles from "./styles";

export interface ToggleProps {
  name: string;
  title?: string;
  value?: boolean;
  dataCy?: string;
  handleOnChange: (value: boolean, name: string) => void;
}

const Toggle: React.FC<ToggleProps> = ({ name, title, value, dataCy, handleOnChange }) => {
  const classes = useStyles();

  const onChange = React.useCallback(
    (e) => {
      handleOnChange(e.target.checked, name);
    },
    [handleOnChange, name],
  );

  return (
    <>
      {title && (
        <Typography variant="body2" className={classes.smallMarginRight}>
          {title}:
        </Typography>
      )}
      <Switch data-cy={dataCy || "toggle"} checked={value} onChange={onChange} />
    </>
  );
};

export default Toggle;
