import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  link: {
    borderRadius: 15,
    padding: "15px 0",
    color: theme.palette.secondary.main,
    fontSize: 17,
    lineHeight: "17px",
    transition: "background-color linear 50ms, padding-left linear 50ms, font-size linear 50ms",
    "&:hover": {
      color: "black",
      fontSize: 19,
      lineHeight: "19px",
      padding: 14,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  itemsLink: {
    fontSize: "1.125rem",
    marginTop: 15,
    display: "block",
    width: "fit-content",
    color: theme.palette.secondary.main,
  },
  interactiveTitle: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wsDetailFieldsActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: theme.spacing(1, 0),
  },
  body: {
    alignItems: "stretch",
    gridTemplateColumns: "3fr 2fr 3fr",
    display: "grid",
    gridGap: theme.spacing(3, 2),
  },
  cardPadding: {
    padding: theme.spacing(3),
  },
  middleColumn: {
    gridColumn: 2,
    gridRow: 1,
  },
  rowEnd: {
    gridRow: 1,
    gridColumn: 3,
  },
  doubleRowSpan: {
    gridRow: "2 / span 2",
    gridColumn: "2 / span 2",
  },
  statisticRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  basicInfoRow: {
    display: "grid",
    alignItems: "center",
    grid: "auto-flow / 1fr 2fr",
  },
  basicInfoLabel: {
    margin: 0,
    marginRight: 5,
    color: theme.palette.text.secondary,
  },
  basicInfoValue: {
    color: theme.palette.text.primary,
  },
  fullWidthRow: {
    gridColumn: "span 3",
  },
}));

export default useStyles;
