import React from "react";

import firebase from "firebase/compat/app";
import { Link, useParams } from "react-router-dom";

// Hooks & Helpers
import routes from "../../routes";
import { firestoreTimestampToFormatted } from "../../helpers/format";
import { useData } from "../../firebase";
import useStyles from "./styles";
import { firestoreQuery } from "../../constants";

// Types
import type { HistoryItemType } from "./types";
import type { WorkspaceUser } from "../../users/types";
import type { SingleItemState } from "../../items/types";
import type { WsUserParams } from "../../routes";

interface Props {
  data: HistoryItemType;
  dateFormat: string;
  workspaceId: string;
}

const camelCaseToText = (field: string): string => {
  const result = field.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const Template: React.FC<Props> = ({ data, dateFormat, workspaceId }) => {
  const { userId } = useParams<WsUserParams>();
  const classes = useStyles();
  const userChangeData: Array<WorkspaceUser> = useData(
    firestoreQuery.wsUsers,
    data.field === "user" || data.field === "item"
      ? [data.before as string, data.after as string]
      : [],
  );
  const itemData: [] | SingleItemState = useData(
    firestoreQuery.wsItems,
    data.field === "item" ? data.itemId : [],
  );

  const beforeUserLink = userChangeData[0] ? (
    <Link
      className={classes.spanText}
      to={routes.workspaceUserInfo(workspaceId, data.before as string)}
    >
      <span className={classes.spanText}>{userChangeData[0].displayName}</span>
    </Link>
  ) : null;

  const afterUserLink = userChangeData[1] ? (
    <Link
      className={classes.spanText}
      to={routes.workspaceUserInfo(workspaceId, data.after as string)}
    >
      <span className={classes.spanText}>{userChangeData[1]?.displayName}</span>
    </Link>
  ) : null;

  const itemLink =
    itemData && Object.keys(itemData).length && (itemData as SingleItemState).name ? (
      <Link
        className={classes.spanText}
        to={routes.workspaceItemsDetail(workspaceId, data.itemId)}
      >
        <span className={classes.spanText}>{(itemData as SingleItemState).name}</span>
      </Link>
    ) : null;

  switch (data.field) {
    case "item":
      if (data.before === userId) {
        if (data.after)
          return (
            <>
              reassigned {itemLink} from{" "}
              <span className={classes.spanText}>{userChangeData[0]?.displayName}</span> to{" "}
              {afterUserLink}
            </>
          );
        return <>unassigned {itemLink}</>;
      }
      if (data.before)
        return (
          <>
            reassigned {itemLink} from {beforeUserLink} to{" "}
            <span className={classes.spanText}>{userChangeData[1].displayName}</span>
          </>
        );
      return <>assigned {itemLink}</>;
    case "notificationSettings":
      return <>turned notifications {(data.after as { all?: boolean })?.all ? "on" : "off"}</>;
    case "bought":
    case "warrantyUntil":
      return (
        <>
          changed {camelCaseToText(data.field)} from{" "}
          <span className={classes.spanText}>
            {firestoreTimestampToFormatted(
              data.before as firebase.firestore.Timestamp,
              dateFormat,
            ) || "no date"}
          </span>{" "}
          to{" "}
          <span className={classes.spanText}>
            {firestoreTimestampToFormatted(
              data.after as firebase.firestore.Timestamp,
              dateFormat,
            ) || "no date"}
          </span>
        </>
      );
    case "user":
      return (
        <>
          assigned this item from{" "}
          {beforeUserLink ? (
            <>
              {beforeUserLink}
              <span className={classes.spanText}>{` (${userChangeData[0].email})`}</span>
            </>
          ) : (
            <span className={classes.spanText}>nobody </span>
          )}{" "}
          to{" "}
          {afterUserLink ? (
            <>
              {afterUserLink}
              <span className={classes.spanText}>{` (${userChangeData[1].email})`}</span>
            </>
          ) : (
            <span className={classes.spanText}>nobody</span>
          )}
        </>
      );
    case "name":
      return (
        <>
          renamed this item from{" "}
          <span className={classes.spanText}>{data.before || "no value"}</span> to{" "}
          <span className={classes.spanText}>{data.after || "no value"}</span>
        </>
      );
    case "notes":
      return (
        <>
          changed Note from{" "}
          <span className={classes.truncatedSpanText}>{data.before || "no value"}</span> to{" "}
          <span className={classes.truncatedSpanText}>{data.after || "no value"}</span>
        </>
      );
  }
  return (
    <>
      changed {camelCaseToText(data.field)} from{" "}
      <span className={classes.spanText}>{data.before || "no value"}</span> to{" "}
      <span className={classes.spanText}>{data.after || "no value"}</span>
    </>
  );
};

export default Template;
