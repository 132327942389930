import React from "react";

// MUI
import TextField from "@material-ui/core/TextField";

// Locals
import DatePicker from "../../datePicker";
import { getDateInterval, getIntervalInDays } from "../helpers/filter";

// Types
import { FilterSpecification, FilterType } from "./types";

// Styles
import useStyles from "./styles";

/**
 * Used for filtering by date
 */
interface Props {
  field: string;
  label: string;
  onChange: (newValue: FilterSpecification | null, field: string) => void;
  value?: FilterSpecification;
  focus?: boolean;
}

const FilterDate: React.FC<Props> = ({ field, focus, label, onChange, value }) => {
  const classes = useStyles();

  const handleOnChange = React.useCallback(
    (newValue, name) => {
      if (!name) return;

      onChange({ ...value, [name]: newValue, filterType: FilterType.Date }, field);
    },
    [field, onChange, value],
  );

  const handleOnDaysChange = React.useCallback(
    (e) => {
      if (field !== "warrantyUntil") return;
      if (!e.target.value) return onChange(null, field);

      const interval = getDateInterval(parseInt(e.target.value));
      onChange({ ...interval, filterType: FilterType.Date }, field);
    },
    [field, onChange],
  );

  /**
   * Used for seeing date range as number of days in case of date warranty filters.
   */
  const additional = React.useMemo(() => {
    if (field !== "warrantyUntil") return null;

    return (
      <>
        <span className={classes.filterDateSubLabel}>or in</span>
        <TextField
          type="number"
          value={value ? getIntervalInDays(value) : ""}
          onChange={handleOnDaysChange}
          style={{ width: "60px", marginRight: "1em" }}
        />
        <span className={classes.filterDateSubLabel}>days</span>
      </>
    );
  }, [classes.filterDateSubLabel, field, handleOnDaysChange, value]);

  return (
    <div className={classes.filterDateRow}>
      <span className={classes.filterLabel}>{label}</span>
      <div className={classes.filterDateInputsSection}>
        <span className={classes.filterDateSubLabel}>from</span>
        <DatePicker
          className={classes.marginRight}
          autoFocus={focus}
          value={value?.lte || null}
          onChange={(date) => handleOnChange(date, "lte")}
        />

        <span className={classes.filterDateSubLabel}>to</span>
        <DatePicker
          className={classes.marginRight}
          value={value?.gte || null}
          onChange={(date) => handleOnChange(date, "gte")}
        />
        {additional}
      </div>
    </div>
  );
};

export default FilterDate;
