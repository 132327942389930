import { functions } from "../firebase/firebase";
import firebase from "firebase/compat/app";

// Local
import { useUserId } from "../authentication/hooks";
import { useCurrentWsDetail } from "../firebase";
import useCustomSnackbar from "../components/snackbar";

// Types
import type { Fields, WsDetail, TierDetail } from "./types";

interface CreateWorkspace {
  (data: Partial<WsDetail>): Promise<void>;
}
interface UseCreateWorkspace {
  (): CreateWorkspace;
}
export const useCreateWorkspace: UseCreateWorkspace = () => {
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (data) => {
    enqueueSnackbar(`Creating workspace ${data.name} ...`, "info");

    // Create Workspace
    await functions().httpsCallable("createWorkspace")(data);

    // Refresh token to get new WS role claims
    await firebase.auth().currentUser?.getIdToken(true);

    enqueueSnackbar(`Created workspace ${data.name}!`, "success");
  };
};

interface UpdateWorkspace {
  (
    data: Record<keyof WsDetail, firebase.firestore.FieldValue | string | Fields | null>,
    id: string,
  ): Promise<void>;
}
interface UseUpdateWorkspace {
  (): UpdateWorkspace;
}

export const useUpdateWorkspace: UseUpdateWorkspace = () => {
  const loggedInUserId = useUserId() as string;

  return async (data, id) => {
    const wsRef = firebase.firestore().doc(`workspaces/${id}`);
    // info about last update
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    // info about who updated
    data.updatedBy = loggedInUserId;

    return await wsRef.update(data);
  };
};

interface GetWsTierDetail {
  (workspaceId: string): Promise<TierDetail>;
}
interface UseGetWsTierDetail {
  (): GetWsTierDetail;
}

export const useGetWsTierDetail: UseGetWsTierDetail = () => {
  return async (workspaceId) => {
    return (await functions().httpsCallable("getWorkspaceTierDetail")(workspaceId)).data;
  };
};

/////////////////////////////
///         FIELDS        ///
/////////////////////////////

/**
 * ---------------------------
 * USE UPDATE WORKSPACE FIELDS
 * ---------------------------
 */
interface UpdateWorkspaceFields {
  (newFields: Fields, wsId: string): Promise<void>;
}

interface UseUpdateWorkspaceFields {
  (suppressSnack?: boolean): UpdateWorkspaceFields;
}

/**
 * Used in workspace options for managing field values.
 * Allows us to add and remove multiple values in multiple fields at once.
 * This function returns callback accepting following parameters:
 * @param suppressSnack suppress notification about successful action
 */
export const useUpdateWorkspaceFields: UseUpdateWorkspaceFields = (suppressSnack) => {
  const updateWorkspace = useUpdateWorkspace();
  const currentWsFields = useCurrentWsDetail()?.fields;
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (newFields: Fields, wsId: string) => {
    try {
      // Preparing new ws data.
      const data = {
        fields: { ...currentWsFields, ...newFields },
      };
      // Updating.
      await updateWorkspace(data, wsId);
      if (!suppressSnack) enqueueSnackbar("Workspace fields were updated.", "success");
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `Something went wrong with updating workspace fields. ${err?.message}.`,
        "error",
      );
    }
  };
};

/**
 * -----------------------------
 * USE ADD WORKSPACE FIELD VALUE
 * -----------------------------
 */
interface AddWorkspaceFieldValue {
  (newValue: string, fieldId: string, wsId: string): Promise<void>;
}
interface UseAddWorkspaceFieldValue {
  (suppressSnack?: boolean): AddWorkspaceFieldValue;
}
/**
 * Used for adding new field value in "Single item view" or "Add item view" for example.
 * Allows us to add one value for chosen field.
 * This function returns a callback accepting following parameters:
 * @param suppressSnack suppress notification about successful action
 */
export const useAddWorkspaceFieldValue: UseAddWorkspaceFieldValue = (suppressSnack) => {
  const updateWorkspace = useUpdateWorkspace();
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (newValue: string, fieldId: string, wsId: string) => {
    try {
      // Preparing new field value data.
      const data = {
        [`fields.${fieldId}.values`]: firebase.firestore.FieldValue.arrayUnion(newValue),
      };
      // Updating.
      await updateWorkspace(data, wsId);
      if (!suppressSnack) enqueueSnackbar(`Value ${newValue} was added to ${fieldId}`, "success");
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `Something went wrong with adding ${newValue} to ${fieldId}. ${err?.message}.`,
        "error",
      );
    }
  };
};
