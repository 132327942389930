import React from "react";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import { camelCaseToText } from "../../helpers/format";

export type Option = {
  value: string;
  title: string;
  checked?: boolean;
};

interface IProps {
  overrideButton?: React.Component;
  options: Record<string, Option[]> | Option[];
  handleItemSelected: (value: string, checked: boolean, field?: string) => void;
}

const CheckboxMenu: React.FC<IProps> = ({ overrideButton, options, handleItemSelected }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const selectedKey = React.useRef<string>("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {overrideButton ?? (
        <Button onClick={handleClick} color="secondary" variant="outlined">
          Add
        </Button>
      )}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Grid container direction="column">
          {options instanceof Array
            ? options.map(({ title, value, checked }) => (
                <MenuItem onClick={() => handleItemSelected(value, !checked)} key={value}>
                  <FormControlLabel control={<Checkbox checked={checked} />} label={title} />
                </MenuItem>
              ))
            : Object.entries(options).map(([key]) => (
                <MenuItem
                  key={key}
                  onClick={(e) => {
                    selectedKey.current = key;
                    setAnchorEl(null);
                    setAnchorEl2(e.currentTarget);
                  }}
                >
                  {camelCaseToText(key)}
                </MenuItem>
              ))}
        </Grid>
      </Menu>
      {!(options instanceof Array) && (
        <Menu
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={() => setAnchorEl2(null)}
        >
          <Grid container direction="column">
            {options[selectedKey.current]?.map(({ title, value, checked }) => (
              <MenuItem
                onClick={() => handleItemSelected(value, !checked, selectedKey.current)}
                key={value}
              >
                <FormControlLabel control={<Checkbox checked={checked} />} label={title} />
              </MenuItem>
            ))}
          </Grid>
        </Menu>
      )}
    </>
  );
};
export default CheckboxMenu;
