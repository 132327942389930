// Types
import { SingleItemState, SingleItemErrorState } from "../types";

export const initialState: SingleItemState = {
  name: "",
  parent: null,
  children: null,
  serialNumber: null,
  type: null,
  status: null,
  internalNumber: null,
  costCenter: null,
  user: null,
  history: null,
  bought: null,
  notes: null,
  location: null,
  warrantyUntil: null,
  invoiceNumber: null,
  extendedWarranty: null,
  inventoryCheck: null,
  tags: [],
};

export const initialErrorState: SingleItemErrorState = {
  name: false,
  parent: false,
  children: false,
  serialNumber: false,
  type: false,
  status: false,
  internalNumber: false,
  costCenter: false,
  user: false,
  bought: false,
  warrantyUntil: false,
  extendedWarranty: false,
};
