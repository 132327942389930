import React from "react";
import { Link } from "react-router-dom";

// Material UI components
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Local
import About from "../components/about";
import { LogoutMenuItem } from "../authentication/logout";
import Expanded from "./expanded";
import Profile from "./profile";
import useStyles from "./styles";
import routes from "../routes";
import { menuItemIconMargin } from "../globalStyles";
import { localStorageKey } from "../constants";
import { useUserId } from "../authentication/hooks";

interface Props {
  withLinks?: boolean;
}
const Navigation: React.FC<Props> = ({ withLinks = false }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [aboutOpen, setAboutOpen] = React.useState<boolean>(false);
  const userId = useUserId();

  const handleMenu = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <AppBar elevation={2} position="static" component="nav" className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Button
            component={Link}
            to={routes.workspacesList}
            onClick={() => localStorage.removeItem(localStorageKey.lastUsedWorkspace(userId))}
          >
            <img src="/logo.svg" alt="Listiq" className={classes.logo} />
          </Button>
          <div
            className={classes.toolbarRight}
            style={{ justifyContent: withLinks ? "space-between" : "flex-end" }}
          >
            {withLinks ? <Expanded /> : null}
            <IconButton
              className={classes.profileButton}
              data-cy="menu-user"
              onClick={handleMenu}
              color="inherit"
            >
              <Profile />
              <ArrowDropDownSharpIcon style={{ marginLeft: "0.5em" }} />
            </IconButton>
          </div>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setAboutOpen(true)}>
            <InfoOutlinedIcon style={menuItemIconMargin} />
            About
          </MenuItem>
          <LogoutMenuItem />
        </Menu>
      </AppBar>
      <About open={aboutOpen} close={() => setAboutOpen(false)} />
    </>
  );
};

export default Navigation;
