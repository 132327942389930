import firebase from "firebase/compat/app";

// Locals
import useCustomSnackbar from "../../components/snackbar";
import { useUserId } from "../../authentication/hooks";
import { findRemovedKeys, unassignFromParent, updateItems } from "../api/helpers";

interface UpdateSubItems {
  (wsId: string, itemId: string, newChildren: Record<string, unknown>): Promise<void>;
}

interface UseUpdateSubItems {
  (): UpdateSubItems;
}

export const useUpdateSubItems: UseUpdateSubItems = () => {
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (wsId: string, itemId: string, newChildren: Record<string, unknown>) => {
    try {
      await firebase.firestore().runTransaction(async (transaction) => {
        const wsItems = firebase.firestore().collection(`workspaces/${wsId}/items`);
        const parentItem = firebase.firestore().doc(`workspaces/${wsId}/items/${itemId}`);
        const parentItemDoc = await parentItem.get();
        const parentItemData = parentItemDoc.data();

        /**
         * ------------------------------------------------------------------
         * Updating parentId, status, ... of the new sub-items.
         * ------------------------------------------------------------------
         */
        const newChildrenIds = Object.keys(newChildren);

        const fieldsToBeUpSynced = {
          // New children should have some of their fields (status, location, ..) synced with the parent item.
          // status: nonEmptyStringOrNull(oldItemData?.status),
          // user: nonEmptyStringOrNull(oldItemData?.user),
          // location: nonEmptyStringOrNull(oldItemData?.location),
          parent: itemId,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: loggedInUserId,
        };

        await updateItems(transaction, wsItems, newChildrenIds, fieldsToBeUpSynced);

        /**
         * ------------------------------------------------------------------
         * Removing parentId, status... of sub-items unassigned from the item.
         * ------------------------------------------------------------------
         */
        const oldChildren = parentItemData?.children || {};
        const deletedChildrenIds = findRemovedKeys(oldChildren, newChildren);
        await unassignFromParent(transaction, wsItems, loggedInUserId, deletedChildrenIds);
      });
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to edit sub-items. ${err?.message}`, "error");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.error(err.message);
    }
  };
};
