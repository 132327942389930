import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";

// Material UI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// Local
import useStyles from "./styles";
import routes from "../../routes";

interface Props {
  title?: string;
  message?: (pathname: string) => JSX.Element;
}

const NotFound: React.FC<Props> = ({ title, message }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const handleBackClick = React.useCallback(() => dispatch(goBack()), [dispatch]);
  const isPopAction = history.length < 3;
  const defaultMessage = (
    <>
      We are sorry but the URL <code>{pathname}</code> does not exist on this site.
    </>
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          {title || "404 Page Not Found"}
        </Typography>
        <Typography variant="body1">{message ? message(pathname) : defaultMessage}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBackClick}
          disabled={isPopAction}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          color={isPopAction ? "secondary" : "primary"}
          component={Link}
          to={routes.workspacesList()}
        >
          Go to Home
        </Button>
      </CardActions>
    </Card>
  );
};

export default NotFound;
