import React from "react";

// Locals
import DataRow from "./dataRow";
import EditableDataRow from "./editableRow";
import Toggle from "../../components/toggle";
import { isNameValid } from "../../helpers/validations";
import ActionsPanel from "../../components/saveOrDiscardPanel";

// Hooks
import { useParams } from "react-router";
import { useIsAdmin } from "../../authentication/hooks";
import { useUpdateUser } from "../api";

// Types
import type { UserInfoState, UserInfoErrorState } from "../types";
import type { WorkspaceUser } from "../../users/types";
import type { WsParams } from "../../routes";

interface Props {
  user: WorkspaceUser;
}

// Editable fields
const initialState: UserInfoState = {
  displayName: "",
  role: "user",
  notificationSettings: { all: false },
};

const initialErrorState: UserInfoErrorState = {
  displayName: false,
  role: false,
};

const CenterInfoPanel: React.FC<Props> = ({ user }) => {
  const { isAdmin } = useIsAdmin();
  const updateUser = useUpdateUser();
  const { workspaceId } = useParams<WsParams>();
  const [state, setState] = React.useState<UserInfoState>(initialState);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [changesMade, setChangesMade] = React.useState(false);

  const setDefaultState = React.useCallback(
    () =>
      setState({
        displayName: user.displayName ?? "",
        role: user.role ?? "",
        notificationSettings: user.notificationSettings ?? { all: false },
      }),
    [user],
  );

  const handleOnChange = React.useCallback(
    (value: string | { all: boolean }, field: string) => {
      if (!field) return;

      // errors checking
      const newErrorState = { ...errorState };

      if (field === "displayName" && isNameValid(value as string)) {
        newErrorState[field] = false;
      }

      setErrorState(newErrorState);
      // -- end of errors checking

      setState({ ...state, [field]: value });
      !changesMade && setChangesMade(true);
    },
    [changesMade, errorState, state],
  );

  React.useEffect(() => {
    if (user) {
      setDefaultState();
    }
  }, [setDefaultState, user]);

  const handleOnSaveClick = React.useCallback(async () => {
    if (!workspaceId || !user.id || !state.displayName || !state.role) return null;
    let isValid = true;
    const newErrorState = { ...errorState };

    if (!isNameValid(state.displayName)) {
      isValid = false;
      newErrorState.displayName = true;
    }

    if (!isValid) {
      setErrorState(errorState);
      return;
    }

    await updateUser(workspaceId, user.id, state);
    setChangesMade(false);
  }, [errorState, state, updateUser, user.id, workspaceId]);

  const handleOnDiscardClick = React.useCallback(() => {
    setDefaultState();
    setChangesMade(false);
  }, [setDefaultState]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      {isAdmin ? (
        <table>
          <tbody>
            <EditableDataRow
              name="displayName"
              title="Display Name"
              dataCy={"userinfo-displayname"}
              value={state.displayName}
              error={errorState.displayName}
              handleOnChange={handleOnChange}
            />
            <EditableDataRow
              title="Role"
              dataCy={"userinfo-role"}
              value={state.role}
              autocompleteSelect
              name="role"
              error={errorState.role}
              options={[
                { title: "admin", value: "admin" },
                { title: "user", value: "user" },
              ]}
              handleOnChange={handleOnChange}
            />
            <DataRow
              title="Notifications"
              value={
                <Toggle
                  name="notificationSettings"
                  dataCy={"userinfo-notifications"}
                  value={state.notificationSettings.all}
                  handleOnChange={(value, name) => handleOnChange({ all: value }, name)}
                />
              }
            />
          </tbody>
        </table>
      ) : (
        <table>
          <tbody>
            <DataRow
              title="Display Name"
              dataCy={"userinfo-displayname"}
              value={user?.displayName || ""}
            />
            <DataRow title="Role" dataCy={"userinfo-role"} value={user?.role || ""} />
            <DataRow
              title="Notifications"
              value={
                <Toggle
                  name="notificationSettings"
                  dataCy={"userinfo-notifications"}
                  value={user.notificationSettings?.all}
                  handleOnChange={(value, name) => handleOnChange({ all: value }, name)}
                />
              }
            />
          </tbody>
        </table>
      )}
      <ActionsPanel
        onSaveClick={handleOnSaveClick}
        onDiscardClick={handleOnDiscardClick}
        changesMade={changesMade}
      />
    </div>
  );
};

export default CenterInfoPanel;
