import { useSelector } from "react-redux";

// Types
import { AppState } from "../store";

export interface NavigationState {
  workspace: string | null;
}

export const initialState: NavigationState = {
  workspace: null,
};

export const useSelectedWorkspace = (): string | null => {
  return useSelector<AppState, string | null>((state) => state.navigation.workspace);
};
