import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// Icons
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

// Local
import Select from "../../components/select";
import useStyles from "../styles";
import { Role, userRolesOptions } from "../userRoles";
import { menuItemIconMargin } from "../../globalStyles";
import NotificationsToggle from "./notificationsToggle";

// Types
import type { NewUserFormState, UsersToBeAddedState } from "../types";

interface Props {
  onKeyEnter: () => void;
  usersToBeAdded: UsersToBeAddedState;
  handleUsersToBeAddedChange: (newUser: NewUserFormState, key: string) => void;
  onAddMore: () => void;
  onRemove: (key: string) => void;
}

const AddUserToWsRows: React.FC<Props> = ({
  onKeyEnter,
  usersToBeAdded,
  handleUsersToBeAddedChange,
  onAddMore,
  onRemove,
}) => {
  const classes = useStyles();
  const showRemove = Object.keys(usersToBeAdded).length > 1;

  return (
    <div className={classes.addUserBody}>
      {Object.keys(usersToBeAdded).map((key, index) => (
        <React.Fragment key={key}>
          <TextField
            data-cy={"email-input"}
            onKeyPress={(e) => e.key === "Enter" && onKeyEnter()}
            error={usersToBeAdded[key].emailError}
            value={usersToBeAdded[key].email}
            onChange={(event) =>
              handleUsersToBeAddedChange(
                { ...usersToBeAdded[key], email: event.target.value },
                key,
              )
            }
            variant="outlined"
            label={`Email ${index + 1}`}
            type="email"
          />

          <FormControl className={classes.formControlRole}>
            <Select
              data-cy={"role-select"}
              inputLabel="Role"
              options={userRolesOptions}
              onChange={(event) =>
                handleUsersToBeAddedChange(
                  { ...usersToBeAdded[key], role: event.target.value as Role },
                  key,
                )
              }
              value={usersToBeAdded[key].role}
            />
          </FormControl>
          <FormControl>
            <NotificationsToggle
              onChange={(event) =>
                handleUsersToBeAddedChange(
                  { ...usersToBeAdded[key], notify: event.target.checked },
                  key,
                )
              }
              value={usersToBeAdded[key].notify}
            />
          </FormControl>
          <FormControl>
            {showRemove && (
              <IconButton onClick={() => onRemove(key)}>
                <CloseIcon />
              </IconButton>
            )}
          </FormControl>
        </React.Fragment>
      ))}
      <div>
        <Button data-cy={"add-more-btn"} size="small" onClick={onAddMore}>
          <AddIcon style={menuItemIconMargin} /> <Typography>ADD MORE</Typography>
        </Button>
      </div>
    </div>
  );
};

export default AddUserToWsRows;
