import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

// Colors
const primMain = "#BFBFBF";
const primDark = "#EDEDED";
const primLight = "#FFFFFF";

const scndMain = "#5481BF";
const scndDark = "#1C2B40";
const scndLight = "#71ACFF";

const errorColor = "#D62B37";
const successColor = "#498040";

const backgroundColor = "#EDEDED";

const tableDefaultEvenRowColor = "#FAF9FA";

// Theme creator
const theme = createMuiTheme({
  palette: {
    primary: {
      main: primMain,
      dark: primDark,
      light: primLight,
    },
    secondary: {
      main: scndMain,
      dark: scndDark,
      light: scndLight,
    },
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    background: {
      default: backgroundColor,
    },
  },
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: scndMain,
      },
    },
    MuiPickersModal: {
      dialog: {
        color: "black",
        backgroundColor: "white",
      },
      dialogRoot: {
        backgroundColor: scndMain,
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "default",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: scndMain,
        },
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(2n)": {
          backgroundColor: tableDefaultEvenRowColor,
        },
      },
    },
    MuiTableCell: {
      head: {
        textOverflow: "unset !important",
      },
    },
  },
});

export default theme;
