import React from "react";

// Material UI
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Locals
import Dialog from "../dialog";
import { ConfirmButton } from "../buttons";
import useStyles from "./styles";

// Types
import type { DialogProps } from "@material-ui/core/Dialog";

interface Props extends DialogProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmationDialog: React.FC<Props> = ({
  message,
  onCancel,
  onConfirm,
  loading,
  ...other
}) => {
  const classes = useStyles();

  const handleOnKeyPress = (e: React.KeyboardEvent) => {
    if (!loading) {
      if (e.key === "Enter") {
        onConfirm();
      } else if (e.key === "Escape") {
        onCancel();
      }
    }
  };

  return (
    <Dialog
      {...other}
      actions={
        <div className={classes.actionsContainer}>
          <Button disabled={loading} className={classes.cancelButton} onClick={onCancel}>
            No
          </Button>
          <ConfirmButton disabled={loading} onClick={onConfirm} loading={loading}>
            Yes
          </ConfirmButton>
        </div>
      }
      onKeyPress={handleOnKeyPress}
      onClose={onCancel}
    >
      <Typography>{message}</Typography>
    </Dialog>
  );
};

export default ConfirmationDialog;
