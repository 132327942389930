import React from "react";
import { Route, Switch } from "react-router-dom";

// Material UI
import makeStyles from "@material-ui/core/styles/makeStyles";

// Local
import { Create, Overview, WorkspaceSwitch } from "./workspace";
import routes from "./routes";
import Navigation from "./navigation";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2, 1),
  },
}));

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <Switch>
      <Route path={routes.workspacesList()} exact>
        <Navigation />
        <main className={classes.main}>
          <Overview />
        </main>
      </Route>
      <Route path={routes.workspaceCreate()}>
        <Navigation />
        <main className={classes.main}>
          <Create />
        </main>
      </Route>
      <Route path={routes.workspaceDetail()}>
        <Navigation withLinks />
        <main className={classes.main}>
          <WorkspaceSwitch />
        </main>
      </Route>
    </Switch>
  );
};

export default App;
