import React from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";

// Material UI
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";

// Locals
import SingleItemBody from "./singleItemBody";
import SaveOrDiscardPanel from "../../components/saveOrDiscardPanel";
import Loading from "../../components/loading";
import { beforeSubmitValidation, onChangeValidation } from "../validation";
import { initialState, initialErrorState } from "./initialState";
import History from "../../components/history";
import { firestoreQuery, localStorageKey } from "../../constants";

// Styles
import useStyles from "../styles";
import { drawerWidth, minDrawerWidth } from "../../globalStyles";

// Hooks
import { useLoading, useData } from "../../firebase";
import { useEditItem } from "../api";

// Types
import type { SingleItemState, keyType } from "../types";
import type { WsItemParams } from "../../routes";
import type { WorkspaceUser } from "../../users/types";

// Helpers
import { avoidClosing, getRedirectPath, shouldOpenDrawer } from "./handlers";
import { useIsAdmin, useUserId } from "../../authentication/hooks";

interface Props {
  type: "item" | "userInfoItem";
}

const itemPropList = [
  "name",
  "parent",
  "children",
  "serialNumber",
  "type",
  "status",
  "internalNumber",
  "user",
  "costCenter",
  "bought",
  "location",
  "notes",
  "warrantyUntil",
  "invoiceNumber",
  "extendedWarranty",
  "tags",
];

const prepareNewItem = (obj: SingleItemState): SingleItemState =>
  Object.entries(obj)
    .filter(([key]) => itemPropList.includes(key))
    .reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value,
      }),
      {},
    ) as SingleItemState;

const copyAndAddField = (
  state: SingleItemState,
  field: keyType,
  newValue?: string | string[] | boolean,
): SingleItemState =>
  ({
    ...state,
    [field]: newValue ?? null,
  } as SingleItemState);

const SingleItem: React.FC<Props> = ({ type = "item" }) => {
  const classes = useStyles();
  const editItem = useEditItem();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { workspaceId, itemId, userId: userIdParam } = useParams<WsItemParams>();
  const userId = useUserId();
  const { isAdmin } = useIsAdmin();

  // Local state
  const [state, setState] = React.useState<SingleItemState>(initialState);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [changesMade, setChangesMade] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const storeQuery = type === "item" ? firestoreQuery.wsItems : firestoreQuery.currentWsUserItems;

  const item = useData<SingleItemState>(storeQuery, itemId);
  const loading = useLoading(storeQuery);
  const users = useData<Record<string, WorkspaceUser>>(firestoreQuery.wsUsers);

  React.useEffect(() => {
    if (itemId !== "add") {
      const lastItemsString =
        localStorage.getItem(localStorageKey.lastItems(workspaceId, userId)) ?? "[]";
      let lastItems = JSON.parse(lastItemsString);
      lastItems = lastItems.filter((entry: string) => entry !== itemId);
      lastItems.unshift(itemId);
      const newLastItemsString = JSON.stringify(lastItems.slice(0, 5));
      localStorage.setItem(localStorageKey.lastItems(workspaceId, userId), newLastItemsString);
    }
  }, [itemId, userId, workspaceId]);

  const deactivatedUser = React.useMemo<boolean | undefined>(
    () => (state.user && users ? !!users[state.user]?.deactivated : undefined),
    [users, state],
  );

  const fillStateWithItem = React.useCallback(() => {
    if (itemId !== "add" && item) {
      const newItem = prepareNewItem(item);

      setState(newItem);
      setChangesMade(false);
    }
  }, [item, itemId]);

  React.useEffect(() => {
    if (item || itemId === "add") {
      document.title = `${itemId === "add" ? "Add item" : item.name} - listiq`;
    }
  }, [item, itemId]);

  const handleOnDiscardClick = React.useCallback(() => {
    fillStateWithItem();
    setErrorState(initialErrorState);
  }, [fillStateWithItem]);

  React.useEffect(fillStateWithItem, [item, fillStateWithItem]);

  const handleOnChange = React.useCallback(
    (newValue: string | string[] | boolean, field: string) => {
      if (!field) return;
      const newState = copyAndAddField(state, field as keyType, newValue);

      // errors checking
      const { changed, newErrorState } = onChangeValidation(
        field as keyType,
        newValue,
        errorState,
      );
      changed && setErrorState(newErrorState);
      // -- end of errors checking

      setState(newState);
      !changesMade && setChangesMade(true);
    },
    [errorState, changesMade, state],
  );

  const handleOnSaveClick = React.useCallback(async () => {
    if (!workspaceId || !itemId) {
      return;
    }

    setSubmitting(true);

    // Validation
    const { isValid, newErrorState } = beforeSubmitValidation(state, errorState);
    if (!isValid) {
      setErrorState(newErrorState);
      setSubmitting(false);
      return;
    }

    await editItem(workspaceId, itemId, state, deactivatedUser);
    setSubmitting(false);
    setChangesMade(false);
  }, [editItem, errorState, itemId, state, workspaceId, deactivatedUser]);

  const handleAwayClick = React.useCallback(() => {
    // We want to avoid closing if in "UploadDialog" for example.
    if (avoidClosing(pathname, workspaceId, itemId, userIdParam)) return;

    return push(getRedirectPath(type, workspaceId, userIdParam));
  }, [itemId, pathname, push, type, userIdParam, workspaceId]);

  return (
    <Drawer
      open={shouldOpenDrawer(type, pathname, workspaceId, userIdParam, itemId)}
      onClose={handleAwayClick}
      variant="persistent"
      anchor="right"
      PaperProps={{ elevation: 10, style: { width: drawerWidth, minWidth: minDrawerWidth } }}
    >
      {loading ? (
        <Loading fullScreen />
      ) : (
        <>
          <div className={classes.drawerHeader}>
            <Link to={getRedirectPath(type, workspaceId, userIdParam)}>
              <IconButton>
                <CancelIcon />
              </IconButton>
            </Link>
            <Typography variant="h5">Details of {state.name}</Typography>
          </div>
          <Divider />
          <div style={{ overflowY: "scroll" }}>
            <div className={classes.singleItemBodyWrap}>
              <SingleItemBody
                handleOnChange={handleOnChange}
                type={type}
                state={state}
                errorState={errorState}
                deactivatedUser={deactivatedUser}
              />
            </div>
            <div className={classes.drawerActions}>
              <SaveOrDiscardPanel
                changesMade={changesMade}
                onDiscardClick={handleOnDiscardClick}
                onSaveClick={handleOnSaveClick}
                loading={submitting}
                disabled={!isAdmin}
              />
            </div>
            <div className={classes.singleItemBodyWrap}>
              <History data={item?.history ?? []} type="item" hideTitle />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

export default SingleItem;
