import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

// Locals
import { useCurrentWsDetail, useLoading } from "../../firebase";
import Loading from "../loading";
import useCustomSnackbar from "../snackbar";
import { firestoreQuery } from "../../constants";

// Types
import type {
  AutocompleteRenderInputParams,
  AutocompleteProps,
} from "@material-ui/lab/Autocomplete";
import type { TextFieldProps } from "@material-ui/core/TextField";
import type { Option } from "./types";

// Styles
import useStyles from "./styles";

interface Props
  extends Pick<
      TextFieldProps,
      | "autoFocus"
      | "variant"
      | "label"
      | "size"
      | "name"
      | "InputProps"
      | "error"
      | "required"
      | "placeholder"
    >,
    Pick<AutocompleteProps<Option, true, false, true>, "limitTags"> {
  options?: Option[];
  value: string[];
  setValue: (value: string[], field: string) => void;
}

const filter = createFilterOptions<Option>();

/**
 *
 * @param autoFocus
 * @param limitTags
 * @param name In case we want this component to autoFetch options based on filed stored in DB - name has to match
 * the field name (id) stored in DB.
 * @param options If array of options provided, autoFetch will be disabled.
 * @param placeholder
 * @param setValue
 * @param value
 * @constructor
 */
const MultiAutoCompleteSelect: React.FC<Props> = ({
  // creatable,
  limitTags,
  name = "",
  options,
  placeholder = "Type and select one.",
  setValue,
  value,
  ...other
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useCustomSnackbar();

  // Fetching WS details to have the field values
  const { fields } = useCurrentWsDetail();

  const finalOptions: Option[] =
    options ||
    fields?.[name]?.values
      .filter((entry) => !value?.includes(entry))
      .map((value: string) => ({ title: value, value })) ||
    [];

  const autoCompleteOnChange = React.useCallback(
    async (_, newValue) => {
      if (typeof newValue !== "string") {
        const possibleOptions: Option[] | undefined =
          options || fields?.[name]?.values.map((value: string) => ({ title: value, value }));
        const ok = newValue.every(({ value }: { value: string }) =>
          possibleOptions?.some(({ value: option }) => value === option),
        );
        if (ok) {
          setValue(
            newValue?.map((value: { value: string }) => value.value),
            name,
          );
        } else {
          enqueueSnackbar("Please select value from the list.", "info");
        }
      }
    },
    [fields, enqueueSnackbar, name, options, setValue],
  );

  const filterOptions = React.useCallback((options, params) => filter(options, params), []);

  // Stop processing in case we don't have the data yet
  const loading = useLoading(firestoreQuery.currentWsDetail);
  if (loading) return <Loading />;

  const valuesToDisplay: Option[] =
    value && value.length
      ? (value as string[]).map((item) => ({ title: item, value: item }))
      : [];

  return (
    <Autocomplete
      multiple
      autoHighlight={true}
      limitTags={limitTags}
      value={valuesToDisplay}
      onChange={autoCompleteOnChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={finalOptions}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => option.title}
      freeSolo
      classes={{ input: classes.placeholder }}
      ChipProps={{ size: "small" }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} {...other} placeholder={placeholder} />
      )}
    />
  );
};

export default MultiAutoCompleteSelect;
