import firebase from "firebase/compat/app";
import { DateTime, Interval } from "luxon";
import { Column, MaterialTableProps } from "material-table";

// Local
import { dateToFormatted } from "../../../helpers/format";
import { FilterSpecification, FilterType, IFilters } from "../filter/types";
import { MyColumn, RowData } from "../types";

const isRegex = (value: string) => /^\/.*\/$/.test(value);

export const customFilterAndSearch = (
  toggleRegex: (regex: boolean) => void,
): ((searchValue: string, data: RowData) => boolean) => (
  searchValue: string,
  data: RowData,
): boolean => {
  let regex: RegExp | undefined;

  if (isRegex(searchValue)) {
    toggleRegex(true);
    try {
      regex = RegExp(searchValue.slice(1, -1), "i");
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        console.error(e);
      }
    }
  } else {
    toggleRegex(false);
  }

  const searchLowerCase = searchValue.toLowerCase();

  return Object.values(data).some((value) =>
    regex ? regex.test(`${value}`) : `${value}`.toLowerCase().includes(searchLowerCase),
  );
};

const doesContainSubstring = (string: string, subString?: string): boolean => {
  return string.toLowerCase().includes(subString?.toLowerCase() || "");
};

const isIncludedInArray = (string: string, arrayOfOptions?: string[]): boolean => {
  if (!arrayOfOptions?.length) return true;
  // Transforming into lower case
  const optionsLowerCase = arrayOfOptions.map((option: string) => option.toLocaleLowerCase());

  return optionsLowerCase.includes(string.toLocaleLowerCase());
};

const isInDateRange = (
  timeRange: { lte?: DateTime; gte?: DateTime },
  value: firebase.firestore.Timestamp,
): boolean => {
  let isOk = true;

  const valueToCheck = value.seconds;

  const lteDate = timeRange.lte?.toMillis() ? timeRange.lte?.toMillis() / 1000 : null;
  const gteDate = timeRange.gte?.toMillis() ? timeRange.gte?.toMillis() / 1000 : null;

  if (gteDate && gteDate < valueToCheck) isOk = false;
  if (lteDate && lteDate > valueToCheck) isOk = false;

  return isOk;
};

// TODO: Add some comments explanations here
const doesItemMatchFilterConditions = (filters: IFilters, item: RowData): boolean => {
  const filterKeys = Object.keys(filters);
  // If no filter conditions -> item will match them.
  if (!filterKeys.length) return true;

  return filterKeys.every((filterKey) => {
    // If item has not value in a field we want to filter by -> we do not include the item.
    if (filters[filterKey].filterType !== FilterType.Bool && !item[filterKey]) return false;

    const filterCondition = filters[filterKey];
    // Columns are filtered by ( does string contain substring ) method.
    switch (filterCondition.filterType) {
      case FilterType.ContainsStr:
        return doesContainSubstring(item[filterKey] as string, filterCondition?.containsStr);

      case FilterType.Bool:
        return (item[filterKey] || "false") === filterCondition.bool;

      case FilterType.MultiSelect:
        return isIncludedInArray(item[filterKey] as string, filterCondition.includedIn);

      case FilterType.Date:
        return isInDateRange(filterCondition, item[filterKey] as firebase.firestore.Timestamp);

      default:
        return true;
    }
  });
};

export const filterData = (filters: IFilters, data: RowData[]): RowData[] =>
  Object.keys(filters).length
    ? data.filter((item) => {
        if (item.parent) {
          const parentData = data.find((el) => el.id === item.parent);
          if (parentData && doesItemMatchFilterConditions(filters, parentData)) return true;
        }

        return doesItemMatchFilterConditions(filters, item);
      })
    : (data as RowData[]);

export const prepareLabel = (filter: FilterSpecification, key: string): string => {
  const base = `${key}:`;

  switch (filter.filterType) {
    case FilterType.ContainsStr:
      return `${base} ${filter.containsStr || ""}`;

    case FilterType.Date:
      return filter.gte
        ? filter.lte
          ? `${base} between ${dateToFormatted(filter.lte)} and ${dateToFormatted(filter.gte)}`
          : `${base} before ${dateToFormatted(filter.gte)}`
        : filter.lte
        ? `${base} after ${dateToFormatted(filter.lte)}`
        : "";

    case FilterType.Bool:
      return `${base} ${filter.bool}`;

    default:
      return `${base} ${filter.containsStr || ""}`;
  }
};

/**
 * Used for making and interval from today up to date (today + addDays).
 * @param addDays
 */
const zeroTime = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const getDateInterval = (addDays: number): { gte: DateTime; lte: DateTime } => {
  const now = DateTime.local().set(zeroTime);
  const then = DateTime.local().plus({ days: addDays }).set(zeroTime);

  return { gte: then, lte: now };
};

/**
 * This function is used for calculating number of days between gte and lte dates if any.
 * @param value expecting object of shape { gte?: DateTime, lte?: DateTime }
 */
export const getIntervalInDays = (value?: { gte?: DateTime; lte?: DateTime }): number => {
  if (!value?.gte || !value?.lte) return 0;
  // If beginning date is not today - we dont want to display anything at the days select in warranty until filter
  if (+value.lte !== +DateTime.local().set(zeroTime)) return 0;

  const interval = Interval.fromDateTimes(value.lte, value.gte).toDuration("days").toObject();

  return interval?.days || 0;
};

export const handleParentChildData = (row: RowData, rows: RowData[]): RowData | undefined =>
  rows.find((a) => a.id === row.parent);

export const getCurrentShownColumns = (
  columnsState: MaterialTableProps<RowData>["columns"],
): string[] => columnsState.filter((col) => !col.hidden).map((col) => col.title as string) || [];

export const prepareColumnsFromLocalStorage = (
  shownColumns: string[],
  allColumns: Column<RowData>[],
): Column<RowData>[] => [
  ...allColumns
    .filter((column) => shownColumns.includes(column.title as string))
    .map((column, index) =>
      shownColumns[index] === column.title
        ? { ...column, hidden: false }
        : {
            ...allColumns.find((entry) => entry.title === shownColumns[index]),
            hidden: false,
          },
    ),
  ...allColumns
    .filter((column) => !shownColumns.includes(column.title as string))
    .map((column) => ({ ...column, hidden: true })),
];

export const parseQSToFilters = (search: string, columns: MyColumn[]): IFilters => {
  const filtersFromQS = new URLSearchParams(search);

  const filters: IFilters = {};

  filtersFromQS.forEach((value, key) => {
    const [dateKey, dateFilter] = key.split("_") as [string, "lte" | "gte" | undefined];

    if (dateFilter) {
      const date = DateTime.fromISO(value as string);
      if (filters[dateKey]) {
        filters[dateKey][dateFilter] = date;
      } else {
        filters[dateKey] = {
          filterType: FilterType.Date,
          [dateFilter]: date,
        };
      }
    } else {
      const filterType =
        columns.find((col) => col.field === key)?.filterType ?? FilterType.ContainsStr;

      switch (filterType) {
        case FilterType.ContainsStr:
          filters[key] = { filterType, containsStr: value as string };
          break;
        case FilterType.Bool:
          filters[key] = { filterType, bool: value };
          break;
        case FilterType.MultiSelect:
          if (filters[key]) {
            filters[key].includedIn?.push(value);
          } else {
            filters[key] = {
              filterType,
              includedIn: [value],
            };
          }
          break;
      }
    }
  });

  return filters;
};

export const parseFiltersToQS = (filters: IFilters): string => {
  const urlQueryString = new URLSearchParams();

  Object.entries(filters)
    .filter(
      ([_, value]) => value.filterType !== FilterType.MultiSelect || value.includedIn?.length,
    )
    .forEach(([key, value]) => {
      switch (value.filterType) {
        case FilterType.ContainsStr:
          urlQueryString.append(key, value.containsStr as string);
          break;
        case FilterType.Bool:
          if (value.bool !== "") urlQueryString.append(key, value.bool as string);
          break;
        case FilterType.MultiSelect:
          value.includedIn?.forEach((entry) => urlQueryString.append(key, entry));
          break;
        case FilterType.Date:
          if (value.lte) urlQueryString.append(`${key}_lte`, value.lte.toISODate());
          if (value.gte) urlQueryString.append(`${key}_gte`, value.gte.toISODate());
      }
    });

  return urlQueryString.toString();
};

export const handleColumnDragged = (
  columnsSettingsStorageKey: string,
  columnsState: Column<RowData>[],
) => (a: number, b: number): void => {
  const columnsSettings = localStorage.getItem(columnsSettingsStorageKey);
  const parsedShownColumns: string[] = columnsSettings
    ? JSON.parse(columnsSettings)
    : columnsState.filter((col) => !col.hidden).map((col) => col.title);

  const pom = parsedShownColumns[a];
  parsedShownColumns[a] = parsedShownColumns[b];
  parsedShownColumns[b] = pom;

  localStorage.setItem(columnsSettingsStorageKey, JSON.stringify(parsedShownColumns));
};
