import React from "react";
import { useParams } from "react-router-dom";

// Material-ui
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Api
import { useDeleteFile } from "../api";

// Style
import useStyles from "../styles";

// Types
import { StorageItem } from "../types";
import { Params } from "../../routes";

// Locals
import ConfirmationDialog from "../../components/confirmationDialog";
import Loading from "../../components/loading";
import AttachmentModal from "./attachmentModal";

interface Props {
  item: StorageItem;
  onDeleteCb: () => void;
}

const Attachment: React.FC<Props> = ({ item, onDeleteCb }) => {
  const classes = useStyles();
  const { workspaceId, itemId } = useParams<Params>();
  const deleteFile = useDeleteFile();
  // Used for deleting and getting url
  const filePath = `workspaces/${workspaceId}/items/${itemId}/files/${item.dbName}`;
  // Confirm dialog state
  const [open, setOpen] = React.useState(false);
  // Loading circle state (used when waiting for signed url)
  const [loading, setLoading] = React.useState(false);

  const handleDeleteClick = React.useCallback(async () => {
    setLoading(true);
    await deleteFile(workspaceId ?? "", itemId ?? "", item.dbName);
    setLoading(false);
    setOpen(false);
    onDeleteCb();
  }, [deleteFile, item.dbName, itemId, onDeleteCb, workspaceId]);

  const title = <AttachmentModal text={item?.name} filePath={filePath} />;

  return (
    <>
      <div className={classes.attachmentItem}>
        {item.name.length > 40 ? <Tooltip title={item.name}>{title}</Tooltip> : title}
        <div className={classes.attachmentButtons}>
          {loading && <Loading size={20} />}
          <IconButton
            style={{ height: 10, width: 10, marginLeft: 10 }}
            onClick={() => setOpen(true)}
          >
            <ClearIcon style={{ height: 15, width: 15 }} />
          </IconButton>
        </div>
      </div>
      {open && (
        <ConfirmationDialog
          open={open}
          loading={loading}
          title="Delete this file?"
          message="Do you want to delete the file from storage?"
          onCancel={() => setOpen(false)}
          onConfirm={handleDeleteClick}
        />
      )}
    </>
  );
};

export default Attachment;
