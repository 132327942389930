import { SingleItemState } from "../types";
import { RowData } from "../../components/table/types";

/**
 * TODO: I am not able to use "useOrdered" firebase hook
 *       to retrieve the array of Item objects because I am getting
 *       an error that the data is not extensible. If it was possible to use this we can get
 *       rid of that extra iteration through all items (below).
 */

const prepareDataForTable = (
  state: Record<string, unknown>,
  items?: Record<string, SingleItemState>,
  itemId?: string,
): RowData[] =>
  items && itemId
    ? Object.entries(items)
        // We don't see item in the list of possible sub items if it is the same item or the item has already some sub items
        .filter(
          ([id, entry]) =>
            id !== itemId && !(entry?.children && Object.keys(entry.children).length),
        )
        .sort(([itemId1], [itemId2]) => (state[itemId1] && !state[itemId2] ? -1 : 0))
        .map(([id, item], index) => ({
          id: id,
          name: item.name ?? "",
          parent: item.parent ?? "",
          internalNumber: item.internalNumber ?? "",
          type: item.type ?? "",
          tableData: {
            id: index,
            checked: state[id] !== undefined,
          },
        }))
    : [];

export default prepareDataForTable;
