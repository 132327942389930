import React from "react";

import { DateTime } from "luxon";
import firebase from "firebase/compat/app";

// MUI
import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker";

// Locals
import { useCurrentWsDetail } from "../../firebase";
import { defaultDateFormatOption } from "../../workspace/detail/dateFormatOptionSelect";

// Types
import type { KeyboardDatePickerProps } from "@material-ui/pickers/DatePicker";

interface IProps extends KeyboardDatePickerProps {
  value: firebase.firestore.Timestamp | DateTime | null;
}

const DatePicker: React.FC<IProps> = ({ value, ...props }) => {
  const { dateFormat = defaultDateFormatOption } = useCurrentWsDetail();

  const dateTimeValue =
    value instanceof firebase.firestore.Timestamp ? DateTime.fromSeconds(value.seconds) : value;

  return (
    <KeyboardDatePicker
      placeholder={dateFormat}
      openTo="year"
      color="primary"
      autoOk
      clearable
      views={["year", "month", "date"]}
      format={dateFormat}
      value={dateTimeValue}
      {...props}
    />
  );
};

export default DatePicker;
