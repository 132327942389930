import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import type { TooltipProps } from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  tooltipText: {
    fontSize: "1rem",
  },
});

const ButtonTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Tooltip placement="top" classes={{ tooltip: classes.tooltipText }} arrow {...props}>
      {children}
    </Tooltip>
  );
};

export default ButtonTooltip;
