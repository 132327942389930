import React from "react";

// Locals
import Item from "./item";
import useStyles from "./styles";

// MUI
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Types
import type { HistoryItemType } from "./types";

interface Props {
  data: HistoryItemType[];
  expandable?: boolean;
}

const initialNumOfRowsToDisplay = 4;

const Items: React.FC<Props> = ({ data, expandable }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = React.useState<boolean>(!expandable);

  const isThereMore = expandable && !showAll && Boolean(initialNumOfRowsToDisplay < data.length);

  const dataAfterSlice = showAll ? data : data.slice(0, initialNumOfRowsToDisplay);

  return (
    <div data-cy={"user-history"} className={expandable ? undefined : classes.maxHeight}>
      <ul
        className={classes.historyItemsWrap}
        style={{ overflow: expandable ? "hidden" : undefined }}
      >
        {dataAfterSlice.map((item) => (
          <li key={`${item.actorId}/${item.field}/${item.time.toMillis()}`}>
            <Item data={item} />
          </li>
        ))}
      </ul>
      {isThereMore && (
        <>
          <div className={classes.fadeOut} />
          <div className={classes.moreHistoryBtn}>
            <Tooltip title="More" placement="top-start">
              <IconButton size="small" onClick={() => setShowAll(true)}>
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default Items;
