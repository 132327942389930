import React from "react";

import firebase from "firebase/compat/app";
import { useParams } from "react-router";

import Button from "@material-ui/core/Button";

import Dialog from "../components/dialog/dialog";
import { useUpdateWorkspace } from "../workspace/api";
import { useUserId } from "../authentication/hooks";

import type { WsParams } from "../routes";

interface IProps {
  inventoryCheckId?: string;
  open: boolean;
  close: () => void;
}

const CloseInventoryCheckModal: React.FC<IProps> = ({ inventoryCheckId, open, close }) => {
  const updateWorkspace = useUpdateWorkspace();
  const userId = useUserId();
  const { workspaceId } = useParams<WsParams>();

  const onCloseStockTaking = React.useCallback(() => {
    updateWorkspace(
      {
        activeInventoryCheckId: firebase.firestore.FieldValue.delete(),
        [`inventoryCheck.${inventoryCheckId}.endedBy`]: userId,
        [`inventoryCheck.${inventoryCheckId}.endedAt`]: firebase.firestore.FieldValue.serverTimestamp(),
      },
      workspaceId,
    ).then(close);
  }, [updateWorkspace, userId, workspaceId, close, inventoryCheckId]);

  return (
    <Dialog
      open={open}
      title="Close Stock-Taking"
      onClose={close}
      actions={
        <>
          <Button variant="outlined" onClick={close}>
            No
          </Button>
          <Button variant="contained" color="secondary" onClick={onCloseStockTaking}>
            Yes
          </Button>
        </>
      }
    >
      Do you really want to close currently active Stock-Taking?
      <br />
      Is so, no more changes can be done in the future (e.g. verify an item).
    </Dialog>
  );
};

export default CloseInventoryCheckModal;
