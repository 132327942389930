import type { SingleItemState } from "../../items/types";
import type { Statistic } from "../types";

interface ItemType {
  id: string;
  value: number;
}

const dateKeys = ["bought", "warrantyUntil"];

const modifyDateChartData = (
  chartData: Record<string, ItemType[]>,
): Record<string, ItemType[]> => {
  const modifiedChartData: Record<string, ItemType[]> = {};

  for (const key in chartData) {
    if (dateKeys.some((checkKey) => checkKey === key)) {
      modifiedChartData[key] = chartData[key].map((item) => {
        const secondsMatch = item.id.match(/seconds=(\d+)/);
        const year = secondsMatch
          ? new Date(parseInt(secondsMatch[1], 10) * 1000).getFullYear().toString()
          : item.id || "";

        return {
          id: year,
          value: item.value,
        };
      });
    } else {
      modifiedChartData[key] = chartData[key];
    }
  }

  return modifiedChartData;
};

const mergeValuesById = (obj: { items: ItemType[]; threshold: number }): ItemType[] => {
  const mergedItems: Record<string, ItemType> = {};

  for (const item of obj.items) {
    if (item.value === undefined) continue;

    const id = item.id;
    const value = item.value;

    if (!mergedItems[id]) {
      mergedItems[id] = { id, value };
    } else {
      mergedItems[id].value += value;
    }
  }

  const mergedItemsArray = Object.values(mergedItems);

  for (const item of mergedItemsArray) {
    if (item.value < obj.threshold) {
      if (!mergedItems["other"]) {
        mergedItems["other"] = { id: "other", value: 0 };
      }
      mergedItems["other"].value += item.value;
      delete mergedItems[item.id];
    }
  }

  return Object.values(mergedItems).filter(
    (item) => item.value >= obj.threshold || item.id === "other",
  );
};

const mergeIdsAndValues = (
  obj: Record<string, ItemType[]>,
  thresholdPercentage: number,
): Record<string, ItemType[]> => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && Array.isArray(obj[key])) {
      const threshold =
        obj[key].reduce((sum, item) => (item.value || 0) + sum, 0) * thresholdPercentage;
      obj[key] = mergeValuesById({ items: obj[key], threshold });
    }
  }

  return obj;
};

export const prepareStatistics = (
  items: SingleItemState[],
  statisticsToShow: Statistic[],
): [Record<string, Record<string, number>>, Record<string, { id: string; value: number }[]>] => {
  if (!(items && statisticsToShow)) return [{}, {}];
  const countData: Record<string, Record<string, number>> = {};
  const chartData: Record<string, Record<string, number>> = {};
  items.forEach((item) => {
    statisticsToShow.forEach((stat) => {
      if (stat.type === "count" && stat.value && item[stat.field] === stat.value) {
        if (countData[stat.field]) {
          countData[stat.field][stat.value] = countData[stat.field][stat.value] + 1 || 1;
        } else {
          countData[stat.field] = { [stat.value]: 1 };
        }
      } else if (
        stat.type === "pie" &&
        !stat.hiddenValues?.includes(item[stat.field] as string)
      ) {
        if (chartData[stat.field]) {
          chartData[stat.field][item[stat.field] as string] =
            chartData[stat.field][item[stat.field] as string] + 1 || 1;
        } else {
          chartData[stat.field] = { [item[stat.field] as string]: 1 };
        }
      }
    });
  });

  const dataToModify = Object.entries(chartData).reduce((prev, [currField, currData]) => {
    prev[currField] = Object.entries(currData).map(([id, value]) => ({
      id: id === "null" ? "_" : id,
      value,
    }));
    return prev;
  }, {} as Record<string, { id: string; value: number }[]>);

  const modifiedChartData = modifyDateChartData(dataToModify);
  const mergedChartData = mergeIdsAndValues(modifiedChartData, 0.05);

  return [countData, mergedChartData];
};
