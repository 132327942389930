// Material UI
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  smallMarginRight: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
