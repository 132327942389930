// Material UI
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "2.2em",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: "1.5em",
  },
  maxHeight: {
    marginTop: theme.spacing(2),
    height: theme.spacing(22),
    overflowY: "scroll",
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  historyItemsWrap: {
    margin: 0,
    padding: theme.spacing(1),
  },
  fadeOut: {
    position: "relative",
    bottom: "2em",
    height: "2em",
    backgroundImage: `linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 70%
      )`,
  },
  moreHistoryBtn: {
    position: "relative",
    bottom: "2em",
    textAlign: "center",
  },
  spanText: {
    fontWeight: 600,
    color: theme.palette.grey.A700,
  },
  historyMessage: {
    fontSize: ".7em",
    fontWeight: 400,
    color: theme.palette.grey.A700,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis" as const,
  },
  truncatedSpanText: {
    fontWeight: 600,
    whiteSpace: "nowrap" as const,
  },
}));

export default useStyles;
