import { makeActionCreator } from "redux-toolbelt";
import { useDispatch } from "react-redux";
import { useEmailsError, useEmailsLoading } from "./state";

const navActionCreator = makeActionCreator.withDefaults({ prefix: "@emails/" });

export const loadEmails = navActionCreator("LOAD_EMAILS");
export const loadEmailsSuccess = navActionCreator("LOAD_EMAILS_SUCCESS");
export const loadEmailsError = navActionCreator("LOAD_EMAILS_ERROR");

export const useLoadEmails = (): ((workspaceId: string) => void) => {
  const dispatch = useDispatch();
  const loading = useEmailsLoading();
  const error = useEmailsError();
  return (workspaceId: string) => {
    if (!(loading || error)) {
      dispatch(loadEmails(workspaceId));
    }
  };
};
