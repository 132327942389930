import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Locals
import ConfirmationDialog from "../../components/confirmationDialog";
import defaultColumns from "../../items/overview/columns";
import routes from "../../routes";
import { useUnassignItemFromUser } from "../api";
import { useCurrentWsDetail, useData } from "../../firebase";
import { useIsAdmin } from "../../authentication/hooks";
import Table from "../../components/table";

// Styles
import useStyles from "../styles";

// Types
import type { Params } from "../../routes";
import type { SingleItemState } from "../../items/types";

// Helpers
import { prepareDataForTable } from "../helpers";

// Queries
import { defaultDateFormatOption } from "../../workspace/detail/dateFormatOptionSelect";
import { firestoreQuery } from "../../constants";

interface Props {
  userName?: string; // User for confirmation message.
}

const UserItemsSection: React.FC<Props> = ({ userName = "No Name" }) => {
  const classes = useStyles();
  const { isAdmin } = useIsAdmin();
  const { dateFormat, name: wsName } = useCurrentWsDetail() ?? {};
  const { push } = useHistory();
  const unassignItemFromUser = useUnassignItemFromUser();
  const { workspaceId, userId } = useParams<Params>();
  const [idToUnassign, setIdToUnassign] = React.useState<string | null>(null);
  const [itemToUnassignName, setItemToUnassignName] = React.useState<string | null>(null);

  const items = useData<Record<string, SingleItemState>>(firestoreQuery.currentWsUserItems);

  const itemsForTable = prepareDataForTable(items, userName, userId);

  const onRowClick = React.useCallback(
    (_, selectedRow) => {
      push(routes.workspaceUserInfoItemDetail(workspaceId, userId, selectedRow?.id as string));
    },
    [push, workspaceId, userId],
  );

  const actions = isAdmin
    ? [
        {
          icon: "clear",
          tooltip: "Unassign item from user",
          onClick: (event: void, row: { id: React.SetStateAction<string | null> }) => {
            if (!row.id || !workspaceId) return null;
            setIdToUnassign(row.id);
          },
        },
      ]
    : [];

  const handleUnassignItem = React.useCallback(() => {
    if (!workspaceId || !idToUnassign) return;
    unassignItemFromUser(workspaceId, idToUnassign);
    setIdToUnassign(null);
  }, [idToUnassign, unassignItemFromUser, workspaceId]);

  React.useEffect(() => {
    if (idToUnassign) {
      const itemToBeUnassigned = itemsForTable.find((element) => element.id === idToUnassign);
      const itemToUnAssignName = (itemToBeUnassigned?.name as string) || "No Name";
      setItemToUnassignName(itemToUnAssignName);
    }
  }, [idToUnassign, itemsForTable]);

  return (
    <>
      <div className={classes.itemsSectionWrap}>
        <Table
          title={`${wsName} - ${userName}'s items`}
          data={itemsForTable}
          columns={defaultColumns(dateFormat ?? defaultDateFormatOption)}
          type="userInfoItem"
          onRowClick={onRowClick}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          actions={actions}
        />
      </div>
      <ConfirmationDialog
        open={Boolean(idToUnassign)}
        title="Unassign item from user."
        message={`Do you want to unassign item ${itemToUnassignName} from the user ${userName}?`}
        onCancel={() => setIdToUnassign(null)}
        onConfirm={handleUnassignItem}
      />
    </>
  );
};

export default UserItemsSection;
