import React from "react";

import firebase from "firebase/compat/app";
import { useParams } from "react-router";

// MUI
import Typography from "@material-ui/core/Typography";

// Locals
import Row from "./row";
import { useCurrentWsDetail, useOrdered } from "../../firebase/hooks";
import { firestoreQuery } from "../../constants";
import { CheckboxMenu, Loading } from "../../components";
import { camelCaseToText } from "../../helpers/format";
import useStyles from "../styles";
import { useUpdateWorkspace } from "../api";

// Types
import type { WorkspaceUser } from "../../users/types";
import type { Option } from "../../components/checkboxMenu/checkboxMenu";
import type { WsParams } from "../../routes";

interface IProps {
  data: Record<string, Record<string, number>>;
  allCount: number;
}

const Statistics: React.FC<IProps> = ({ data, allCount }) => {
  const users = useOrdered<WorkspaceUser>(firestoreQuery.wsUsers);
  const { fields } = useCurrentWsDetail();
  const updateWorkspace = useUpdateWorkspace();
  const shownFields = Object.keys(data);
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();

  const options = React.useMemo<Record<string, Option[]>>(() => {
    if (fields && data) {
      return Object.entries(fields).reduce((prev, [key, field]) => {
        const shownFieldKeys: string[] | undefined = shownFields.includes(key)
          ? Object.keys(data[key])
          : undefined;
        prev[key] = field.values.map((value) => ({
          title: value,
          value,
          checked: shownFieldKeys?.includes(value),
        }));
        return prev;
      }, {} as Record<string, Option[]>);
    }
    return {};
  }, [fields, data, shownFields]);

  const handleItemSelected = React.useCallback(
    (value: string, checked: boolean, field?: string) => {
      const statistic = {
        type: "count",
        field,
        value,
      };
      if (checked) {
        updateWorkspace(
          {
            statisticsToShow: firebase.firestore.FieldValue.arrayUnion(statistic),
          },
          workspaceId,
        );
      } else {
        updateWorkspace(
          {
            statisticsToShow: firebase.firestore.FieldValue.arrayRemove(statistic),
          },
          workspaceId,
        );
      }
    },
    [workspaceId, updateWorkspace],
  );

  if (!(users && fields)) return <Loading />;

  return (
    <>
      <Typography variant="h5" className={classes.interactiveTitle}>
        Statistics
        <CheckboxMenu options={options} handleItemSelected={handleItemSelected} />
      </Typography>
      <br />
      <Row title="Total item count" value={allCount} valueType="number" />
      <br />
      <Row
        title="Active user count"
        value={users.reduce((prev, { deactivated }) => {
          if (!deactivated) prev++;
          return prev;
        }, 0)}
        valueType="number"
      />
      {Object.entries(data).map(([field, valueCounts]) =>
        Object.entries(valueCounts).map(([value, count]) => (
          <div key={field}>
            <br />
            <Row
              title={`Items with ${camelCaseToText(field)} of "${value}" count`}
              value={count}
              valueType="number"
            />
          </div>
        )),
      )}
    </>
  );
};

export default Statistics;
