import React, { ChangeEvent } from "react";

import Switch from "@material-ui/core/Switch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import { HelpIcon } from "../../components/buttons";

interface IProps {
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
}

const useStyles = makeStyles({
  label: {
    fontSize: "0.8rem",
    color: "rgba(0,0,0,0.54)",
    lineHeight: 1,
  },
  labelContainer: {
    alignItems: "flex-start",
    marginLeft: 0,
  },
});

const NotificationsToggle: React.FC<IProps> = ({ onChange, value }) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" container>
      <FormControlLabel
        className={classes.labelContainer}
        classes={{ label: classes.label }}
        control={<Switch checked={value} onChange={onChange} name="notify" />}
        labelPlacement="top"
        label="Notifications"
      />
      <HelpIcon title="This will enable/disable notifications for the user. Upon that decision the user will get (or not) welcome email. And also other email notifications from the workspace. This setting can be updated later too." />
    </Grid>
  );
};

export default NotificationsToggle;
