import { default as configuredFirebase } from "../firebase/firebase";

import useCustomSnackbar from "../components/snackbar";

// Types
import type { NewUser, UsersToBeAddedState } from "./types";

interface AddUser {
  (wsId: string, wsName: string, newUser: NewUser): Promise<void>;
}

interface UseAddUser {
  (): AddUser;
}

export const useAddUser: UseAddUser = () => {
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (wsId: string, wsName: string, newUser: NewUser) => {
    try {
      const result = await configuredFirebase.functions().httpsCallable("addUserToWorkspace")({
        workspaceID: wsId,
        workspaceName: wsName,
        userEmail: newUser.email,
        userRole: newUser.role,
        notify: newUser.notify,
      });
      enqueueSnackbar(result.data.result, "success");
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to add user. ${err.message}`, "error");
      console.error(err);
    }
  };
};

interface AddMultipleUsers {
  (wsId: string, wsName: string, newUsers: UsersToBeAddedState): Promise<void>;
}

interface UseAddMultipleUsers {
  (): AddMultipleUsers;
}

export const useAddMultipleUsers: UseAddMultipleUsers = () => {
  const addUser = useAddUser();

  return async (wsId: string, wsName: string, newUsers: UsersToBeAddedState) => {
    const keysToNewUsers = Object.keys(newUsers);
    for (let index = 0; index < keysToNewUsers.length; index++) {
      const currentKey = keysToNewUsers[index];
      const currentNewuser = newUsers[currentKey];
      // TODO: Add users who were not added into array and do something with them? (Try again or invite to create and account..)
      await addUser(wsId, wsName, currentNewuser);
    }
  };
};

// interface RemoveUser {
//   (wsId: string, userId: string): Promise<void>;
// }
//
// interface UseRemoveUser {
//   (): RemoveUser;
// }
//
// export const useUserActiveStat: UseRemoveUser = () => {
//   const firestore = useFirestore();
//
//   const { enqueueSnackbar } = useCustomSnackbar();
//
//   // TODO: We should call cloud function for this operation. We need to remove WS from the user document too...
//   // Also we will most likely not remove the user but just disable it in the WS. This can be done later on.
//
//   return async (wsId: string, userId: string) => {
//     const ws = firestore.collection("workspaces").doc(wsId);
//     const user = ws.collection("users").doc(userId);
//     try {
//       await user.delete();
//       // We will rework this in the future anyway so it's OK to use just ID now
//       enqueueSnackbar(`User ${userId} has been removed from workspace.`, "success");
//     } catch (err) {
//       enqueueSnackbar(`Failed to remove user from workspace. ${err}`, "error");
//     }
//   };
// };
