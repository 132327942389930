import React from "react";
import useStyles from "../styles";

// Locals
import TimeLine from "../../components/history";

// Types
import type { WorkspaceUser } from "../../users/types";

interface Props {
  user: WorkspaceUser;
}

const RightInfoPanel: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  return (
    <div className={classes.historyWrap}>
      <TimeLine
        data-cy={"user-history"}
        data={user.history ?? []}
        expandable={false}
        type="user"
      />
    </div>
  );
};

export default RightInfoPanel;
