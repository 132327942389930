import React from "react";

// Material-ui
import Card from "@material-ui/core/Card";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import type { CardProps } from "@material-ui/core/Card";

const RaisableCard: React.FC<CardProps> = ({ children, ...other }) => {
  const [isRaised, setIsRaised] = React.useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsRaised(false)}>
      <Card raised={isRaised} onClick={() => setIsRaised(true)} {...other}>
        {children}
      </Card>
    </ClickAwayListener>
  );
};

export default RaisableCard;
