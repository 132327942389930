import React from "react";
import { useParams } from "react-router-dom";

// Material-ui
import Typography from "@material-ui/core/Typography";

// Locals
import Loading from "../../components/loading";
import { MultipleAutoCompleteSelect } from "../../components/autocompleteSelect";
import SaveOrDiscardPanel from "../../components/saveOrDiscardPanel";
import useStyles from "../styles";
import { HelpIcon } from "../../components/buttons";
import { firestoreQuery } from "../../constants";

// Redux Firebase Api
import { useCurrentWsDetail, useLoading } from "../../firebase";
import { useUpdateWorkspaceFields } from "../api";

// Types
import type { Fields } from "../types";
import type { WsParams } from "../../routes";

const tooltipTitle =
  "Deleting these values removes them from the option when creating new item. It does not affect the already created items.";

const FieldsOptions: React.FC = () => {
  const classes = useStyles();
  const { fields } = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);
  const { workspaceId } = useParams<WsParams>();
  const updateWorkspaceFields = useUpdateWorkspaceFields();
  // Local state
  const [state, setState] = React.useState<Fields>({});
  const [changesMade, setChangesMade] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const setDefaultState = React.useCallback(() => {
    if (fields) setState({ tags: { name: "Tags", type: "select", values: [] }, ...fields });
  }, [fields]);

  React.useEffect(() => {
    setDefaultState();
  }, [setDefaultState]);

  const handleDiscardClick = React.useCallback(() => {
    setDefaultState();
    setChangesMade(false);
  }, [setDefaultState]);

  const handleOnSaveClick = React.useCallback(async () => {
    setSubmitting(true);
    await updateWorkspaceFields(state as Fields, workspaceId);
    setChangesMade(false);
    setSubmitting(false);
  }, [state, updateWorkspaceFields, workspaceId]);

  const handleFieldValuesChange = React.useCallback(
    (newValues: string[], fieldId: string) => {
      const fields: Fields = { ...state };

      fields[fieldId] = { ...fields[fieldId], values: newValues };

      setState(fields);
      setChangesMade(true);
    },
    [state],
  );

  const mainContent = loading ? (
    <Loading />
  ) : (
    Object.keys(state)
      .sort()
      .map((key) => {
        const field = state[key];
        return (
          <div key={key}>
            <MultipleAutoCompleteSelect
              label={field.name}
              setValue={handleFieldValuesChange}
              placeholder="Start typing here to add new value..."
              creatable
              name={key}
              variant="outlined"
              value={field.values as string[]}
              options={[]}
            />
            <br />
          </div>
        );
      })
  );

  return (
    <>
      <Typography variant="h5">Fields</Typography>
      <br />
      <span>{mainContent}</span>
      <div className={classes.wsDetailFieldsActions}>
        <SaveOrDiscardPanel
          changesMade={changesMade}
          onDiscardClick={handleDiscardClick}
          onSaveClick={handleOnSaveClick}
          loading={submitting}
        />
        <HelpIcon title={tooltipTitle} style={{ marginLeft: 10, marginBottom: 3 }} />
      </div>
    </>
  );
};

export default FieldsOptions;
