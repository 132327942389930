import React from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import { DateTime, Duration, Interval } from "luxon";
import firebase from "firebase/compat/app";

// MUI
import { Column } from "material-table";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

// Locals
import { firestoreTimestampToFormatted } from "../../helpers/format";
import { useCurrentWsDateFormat, useData } from "../../firebase/hooks";
import { firestoreQuery } from "../../constants";
import { Table } from "../../components";
import useStyles from "../styles";

// Types
import type { RowData } from "../../components/table/types";
import { Params } from "../../routes";

const dayOptions = [30, 60, 90];

const columns = (dateFormat?: string): Array<Column<RowData>> => [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Internal Number",
    field: "internalNumber",
  },
  {
    title: "Warranty Expires",
    field: "warrantyUntil",
    render: ({ warrantyUntil }: RowData) =>
      firestoreTimestampToFormatted(warrantyUntil, dateFormat),
  },
];

const WarrantyEndingItems: React.FC = () => {
  const allItems = useData<Record<string, RowData>>(firestoreQuery.wsItems);
  const dateFormat = useCurrentWsDateFormat();
  const { workspaceId } = useParams<Params>();
  const { push } = useHistory();
  const [daysAfterToday, setDaysAfterToday] = React.useState<number>(30);
  const classes = useStyles();

  const interval = React.useMemo(
    () =>
      Interval.after(
        DateTime.fromObject({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
          zone: "utc",
        }),
        Duration.fromObject({ days: daysAfterToday }),
      ),
    [daysAfterToday],
  );

  const itemsFilterPath = React.useMemo<string>(
    () =>
      `/ws/${workspaceId}/items?warrantyUntil_lte=${interval.start.toISODate()}&warrantyUntil_gte=${interval.end.toISODate()}`,
    [interval, workspaceId],
  );

  const itemsToShow = React.useMemo<RowData[]>(
    () =>
      allItems
        ? Object.entries(allItems).reduce((prev, [id, item]) => {
            if (
              item?.warrantyUntil &&
              interval.contains(
                DateTime.fromMillis(
                  (item.warrantyUntil as firebase.firestore.Timestamp).toMillis(),
                ),
              )
            )
              prev.push({
                id,
                name: item.name,
                warrantyUntil: item.warrantyUntil,
                internalNumber: item.internalNumber,
              });
            return prev;
          }, [] as RowData[])
        : [],
    [allItems, interval],
  );

  return (
    <>
      <Typography
        variant="h5"
        data-cy="home-warranty-ends-title"
        className={classes.interactiveTitle}
      >
        Warranty ends in
        <ButtonGroup>
          {dayOptions.map((days) => (
            <Button
              key={days}
              color={daysAfterToday === days ? "secondary" : "default"}
              onClick={() => setDaysAfterToday(days)}
            >
              {days}d
            </Button>
          ))}
        </ButtonGroup>
      </Typography>
      <Table
        onRowClick={(_, data) => push(`/ws/${workspaceId}/items/${data?.id}`)}
        type="warrantyEnding"
        columns={columns(dateFormat)}
        data={itemsToShow}
        simple
        style={{ boxShadow: "none" }}
      />
      <Link className={classes.itemsLink} to={itemsFilterPath}>
        Show detailed overview
      </Link>
    </>
  );
};

export default WarrantyEndingItems;
