import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Locals
import CreateSubItemActions from "./createSubItemActions";
import Dialog from "../../components/dialog";
import Table from "../../components/table";
import routes, { Params } from "../../routes";
import defaultColumns from "./defaultColumns";
import prepareDataForTable from "./prepareDataForTable";
import { firestoreQuery } from "../../constants";

// Hooks, API
import { useLoading, useData } from "../../firebase";
import Loading from "../../components/loading";
import { useUpdateSubItems } from "./api";

// Types
import { SingleItemState } from "../types";
import { RowData } from "../../components/table/types";

const CreateSubItem: React.FC = () => {
  // URL
  const { pathname } = useLocation();
  const { workspaceId, itemId } = useParams<Params>();
  const { push } = useHistory();

  // Data
  const loading = useLoading(firestoreQuery.wsItems);
  const items = useData<Record<string, SingleItemState>>(firestoreQuery.wsItems);

  // State
  const [state, setState] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);

  // Api
  const updateSubItems = useUpdateSubItems();

  /**
   * STATE MANAGEMENT
   */
  const fillDefaultState = React.useCallback(() => {
    if (!items || !itemId || !items[itemId as string]) return;
    const currentChildren = items[itemId as string]?.children;
    setState(currentChildren || {});
  }, [itemId, items]);

  React.useEffect(() => {
    fillDefaultState();
  }, [fillDefaultState, itemId, items]);

  const handleSetState = (newData: RowData[]) => {
    const newChildren: Record<string, unknown> = {};
    newData.forEach((item: RowData) => {
      if (!item?.id) return;
      newChildren[item.id as string] = true;
    });

    setState(newChildren);
  };

  /**
   * SUBMIT
   */
  const handleOnSaveClick = React.useCallback(async () => {
    if (!workspaceId || !itemId) return;

    setSubmitting(true);
    await updateSubItems(workspaceId, itemId, state);
    setSubmitting(false);

    fillDefaultState();
    push(routes.workspaceItems(workspaceId));
  }, [itemId, fillDefaultState, push, state, updateSubItems, workspaceId]);

  /**
   * CANCEL
   */
  const handleOnCancelClick = React.useCallback(() => {
    setState({});
    push(routes.workspaceItems(workspaceId));
  }, [push, workspaceId]);

  const handleOnKeyDown = React.useCallback(
    (e: { key: string }) => {
      if (e.key === "Escape") {
        handleOnCancelClick();
      }
    },
    [handleOnCancelClick],
  );

  if (loading) return <Loading />;
  return (
    <Dialog
      maxWidth="lg"
      title={`Select sub-items of item ${items ? items[itemId || ""]?.name : ""}`}
      open={pathname === routes.addSubItemToWorkspaceItem(workspaceId, itemId)}
      actions={
        <CreateSubItemActions
          onCancel={handleOnCancelClick}
          onAdd={handleOnSaveClick}
          loading={submitting}
        />
      }
      onClose={handleOnCancelClick}
      onKeyDown={handleOnKeyDown}
    >
      <div style={{ minWidth: "700px" }}>
        <Table
          style={{ boxShadow: "none" }}
          onSelectionChange={handleSetState}
          data={prepareDataForTable(state, items, itemId)}
          columns={defaultColumns}
          type="item"
          additionalOptions={{
            selection: true,
            sorting: false,
            pageSize: 10,
          }}
          toolbarProps={{
            simpleToolbar: true,
          }}
        />
      </div>
    </Dialog>
  );
};

export default CreateSubItem;
