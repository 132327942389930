import React from "react";

// Locals
import DataRow from "./dataRow";
import { firestoreTimestampToFormatted } from "../../helpers/format";
import { useCurrentWsDateFormat, useData } from "../../firebase";
import { firestoreQuery } from "../../constants";

// Types
import { WorkspaceUser } from "../../users/types";

// Styles
import useStyles from "../styles";

interface Props {
  userData?: WorkspaceUser;
}

const LeftInfoPanel: React.FC<Props> = ({ userData }) => {
  const classes = useStyles();
  const dateFormat = useCurrentWsDateFormat();
  // used for converting userId (createdBy) into name
  const wsUsers = useData<WorkspaceUser>(firestoreQuery.wsUsers);
  const addedBy = userData?.createdBy && wsUsers ? wsUsers[userData.createdBy]?.displayName : "";

  // converts seconds into date
  const addedAt = firestoreTimestampToFormatted(userData?.createdAt, dateFormat);

  return (
    <table className={classes.bigMarginRight}>
      <tbody>
        <DataRow dataCy={"userinfo-name"} title="Name" value={userData?.name || ""} />
        <DataRow dataCy={"userinfo-email"} title="Email" value={userData?.email || ""} />
        <DataRow dataCy={"userinfo-added"} title="Added" value={addedAt} />
        <DataRow dataCy={"userinfo-addedby"} title="Added by" value={addedBy} />
      </tbody>
    </table>
  );
};

export default LeftInfoPanel;
