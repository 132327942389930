import React from "react";
import { useParams } from "react-router-dom";

// Locals
import AttachFiles from "./attachFiles";
import Attachment from "./attachment";
import useStyles from "../styles";
import { Params } from "../../routes";
import { useGetFilesFromPath } from "../api";
import { Loading } from "../../components";

// Types
import { StorageItem } from "../types";

interface Props {
  type?: "item" | "userInfoItem";
}

const Attachments: React.FC<Props> = ({ type }) => {
  const getFilesFromPath = useGetFilesFromPath();
  const classes = useStyles();
  const { workspaceId, itemId } = useParams<Params>();
  const [items, setItems] = React.useState<StorageItem[] | undefined>();
  const [
    attachmentsLoadedForItemWithId,
    setAttachmentsLoadedForItemWithId,
  ] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>();
  const filesPath = `workspaces/${workspaceId}/items/${itemId}/files`;

  const getFiles = React.useCallback(async () => {
    setLoading(true);
    const files = await getFilesFromPath(filesPath);
    if (files) {
      setAttachmentsLoadedForItemWithId(itemId);
      setItems(files);
      setLoading(false);
    }
  }, [filesPath, getFilesFromPath, itemId]);

  React.useEffect(() => {
    if (attachmentsLoadedForItemWithId !== itemId && !loading) {
      getFiles();
    }
  }, [attachmentsLoadedForItemWithId, getFiles, itemId, items, loading]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : items?.length ? (
        <div className={classes.relative}>
          <div className={classes.attachmentsLabelWrap}>
            <span className={classes.attachmentsLabel}>Attachments</span>
          </div>
          <div className={classes.attachmentsWrap} data-cy={"attachments"}>
            {items?.map((item) => (
              <Attachment item={item} key={item.dbName} onDeleteCb={getFiles} />
            ))}
          </div>
        </div>
      ) : undefined}
      <AttachFiles type={type} onUploadCb={getFiles} />
    </div>
  );
};

export default Attachments;
