import React from "react";
import useStyles from "../styles";

interface RowProps {
  title: string;
  value?: string | number;
  children?: React.ReactElement;
  valueType?: "number" | "string";
}

const Row: React.FC<RowProps> = ({ title, value, children, valueType = "string" }) => {
  const classes = useStyles();

  return (
    <div className={valueType === "number" ? classes.statisticRow : classes.basicInfoRow}>
      <span className={classes.basicInfoLabel}>{title}</span>
      <span className={`workspace-${title} ${classes.basicInfoValue}`}>{value ?? children}</span>
    </div>
  );
};

export default Row;
