import React from "react";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// MUI
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

// Firebase
import firebase from "firebase/compat/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

// Local
import config from "./config";
import theme from "./theme";
import { AuthRoute } from "./authentication";
import { ConnectedFlagsProvider } from "./flags";
import routes from "./routes";
import DocumentTitleSwitch from "./documentTitleSwitch";

// Subcomponents
import App from "./app";
import { Login } from "./authentication";
import { NotFound } from "./pages";
import { Loading } from "./components";

// Types
import type { AppStore } from "./store";
import type { History } from "history";

interface Props {
  store: AppStore;
  history: History;
}
const Root: React.FC<Props> = ({ store, history }) => (
  <Provider store={store}>
    <ConnectedFlagsProvider>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={config.rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={4}>
              <React.StrictMode>
                <CssBaseline />
                <DocumentTitleSwitch />
                <Switch>
                  <Route path={routes.loading()}>
                    <Loading fullScreen logo size="50vh" />
                  </Route>
                  <Route path={routes.login()}>
                    <Login />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to={routes.workspacesList()} />
                  </Route>
                  <AuthRoute path={routes.workspacesList()}>
                    <App />
                  </AuthRoute>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </React.StrictMode>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </ReactReduxFirebaseProvider>
    </ConnectedFlagsProvider>
  </Provider>
);

export default Root;
