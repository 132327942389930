import theme from "./theme";

// variables
export const drawerWidth = "55vw"; //vw
export const minDrawerWidth = 800; //px
export const tableBorderRadius = 10; //px
export const userTableMaxWidth = 1100; //px (MUI class)
export const userInfoAvatarSize = 120; //px
export const usersTableAvatarSize = 28; //px
export const itemsDetailAvatarSize = 40; //px
export const tableFilteringWidth = 690; //px

// colors
export const formLightBackgroundColor = "#F5F5F5";
export const tableSelectedRowColor = "#E6F7FF";
export const tableSubItemRowColor = "#F7F7F7";
export const textFieldLabelGrayLike = "#757575";

//
export const menuItemIconMargin = { marginRight: 5 };
export const justifySpaceBetween = { justifyContent: "space-between" };

// Mixins
export const smallColumnSize = {
  width: 100,
  maxWidth: 100,
  whiteSpace: "nowrap" as const,
  overflow: "hidden" as const,
  textOverflow: "ellipsis" as const,
};

export const smallColumnStyle = {
  cellStyle: smallColumnSize,
  headerStyle: smallColumnSize,
};

export const cardLookStyle = {
  backgroundColor: theme.palette.primary.dark,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
};
