import makeStyles from "@material-ui/core/styles/makeStyles";
import { textFieldLabelGrayLike } from "../../globalStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 132,
    padding: theme.spacing(2),
    paddingTop: 0,
    minWidth: 300,
  },
  text: {
    color: textFieldLabelGrayLike,
    fontSize: "1rem",
    fontStyle: "italic",
    marginTop: 0,
    whiteSpace: "pre",
  },
  textContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  icon: {
    color: textFieldLabelGrayLike,
  },
}));

export default useStyles;
