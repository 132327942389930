import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Local
import { useData } from "../firebase";
import { useUserId } from "../authentication/hooks";
import useStyles from "./styles";
import routes from "../routes";
import Select from "../components/select";
import { firestoreQuery } from "../constants";

// Material UI
import Typography from "@material-ui/core/Typography";
import AppsRoundedIcon from "@material-ui/icons/AppsRounded";

// Types
import type { User } from "../users/types";
import type { WsParams } from "../routes";

const pathRegex = /^\/ws\/[^/]+\/([^/]+)$/;
const wsDetailRegex = /^\/ws\/[^/]+$/;

const WorkspaceSelect: React.FC = () => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const userId = useUserId();

  // Subscribe to current user workspaces
  useFirestoreConnect([
    { collection: "users", doc: userId as string, storeAs: firestoreQuery.currentUser },
  ]);

  // Redux
  const user = useData<User>(firestoreQuery.currentUser);

  // Callback
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value?: string | number }>) => {
      if (event.target.value) {
        push(routes.workspaceDetail(event.target.value as string));
      } else if (event.target.value === 0) {
        push(routes.workspaceDetail(workspaceId));
      }
    },
    [push, workspaceId],
  );

  // Construct items
  const items = React.useMemo(
    () =>
      user?.workspaces
        ? Object.keys(user.workspaces).map((wsId: string) => ({
            value: wsId,
            title: user.workspaces[wsId].name,
          }))
        : [],
    [user],
  );

  React.useEffect(() => {
    const locationInWorkspace = pathname.match(pathRegex)?.[1];
    if (locationInWorkspace) {
      document.title = `${user?.workspaces[workspaceId]?.name} ${locationInWorkspace} - listiq`;
    } else if (wsDetailRegex.test(pathname)) {
      document.title = `${user?.workspaces[workspaceId]?.name} workspace - listiq`;
    }
  }, [pathname, workspaceId, user]);

  return (
    <Select
      renderValue={(value: unknown) => (
        <div className={classes.wsSelectWrap}>
          <AppsRoundedIcon className={classes.wsSelectIcon} />
          <Typography>{`${user?.workspaces[value as string]?.name || "None"}`}</Typography>
        </div>
      )}
      variant="outlined"
      className={classes.select}
      formStyle={classes.formControl}
      innerWrapperStyle={classes.selectInnerWrapper}
      options={items}
      value={items.length && workspaceId ? workspaceId : ""}
      onClick={handleChange}
    />
  );
};

export default WorkspaceSelect;
