import { loadEmails, loadEmailsError, loadEmailsSuccess } from "./actions";
import { initialState } from "./state";

// Types
import type { Action } from "redux-toolbelt";
import type { EmailsState } from "./state";

const reducer = (state = initialState, action: Action): EmailsState => {
  switch (action.type) {
    case loadEmails.TYPE:
      return { ...state, loading: true };
    case loadEmailsSuccess.TYPE:
      return { ...state, loading: false, data: action.payload };
    case loadEmailsError.TYPE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
