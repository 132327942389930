import { WhereOptions } from "react-redux-firebase";
import firebase from "firebase/compat/app";
import { HistoryItemType } from "../components/history/types";

export type StorageItem = {
  name: string;
  dbName: string;
};

export const itemKeys = [
  "type",
  "status",
  "location",
  "costCenter",
  "bought",
  "warrantyUntil",
] as const;

export type keyType = typeof itemKeys[number];

export interface SingleItemState extends firebase.firestore.DocumentData {
  id?: string;
  name: string;
  parent: string | null;
  children: Record<string, boolean> | null;
  serialNumber: string | null;
  type: string | null;
  status: string | null;
  internalNumber: string | null;
  user: string | null;
  history: HistoryItemType[] | null;
  bought: firebase.firestore.Timestamp | null;
  location: string | null;
  costCenter: string | null;
  invoiceNumber: string | null;
  warrantyUntil: firebase.firestore.Timestamp | null;
  extendedWarranty: boolean | null;
  notes: string | null;
  inventoryCheck: Record<
    string,
    { verified: boolean; verifiedAt?: firebase.firestore.Timestamp; verifiedBy: string }
  > | null;
  tags: [];

  [key: string]:
    | boolean
    | string
    | undefined
    | null
    | HistoryItemType[]
    | firebase.firestore.FieldValue
    | firebase.firestore.Timestamp
    | Record<string, unknown>;
}

export interface SingleItemErrorState {
  [key: string]: boolean;
}

// Used for allowing conditional where clause in items.tsx
export interface SubCollectionQuery {
  collection: string;
  where?: WhereOptions;
}
