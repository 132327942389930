import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    boxShadow: theme.shadows[2],
    minWidth: 300,
  },
  rowLabel: {
    color: "#8f8f8f",
  },
  copyright: {
    fontSize: ".8em",
    color: "grey",
    textAlign: "center",
  },
  zeroPadding: {
    padding: 0,
  },
  labelList: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& p": {
      margin: ".5em 0",
    },
  },
  clearLocalDataBtn: {
    display: "flex",
    margin: "auto",
  },
  getSupportLink: {
    textDecoration: "none",
  },
  getSupportBtn: {
    display: "flex",
    margin: "auto",
    marginTop: "1em",
  },
}));

export default useStyles;
