import makeStyles from "@material-ui/core/styles/makeStyles";

import { formLightBackgroundColor } from "../../globalStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    marginTop: theme.spacing(6),
    padding: theme.spacing(3, 3, 1, 4),
    // Center card on page
    marginLeft: "auto",
    marginRight: "auto",
  },

  divider: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  cardHeader: {
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(1),
  },

  list: {
    // padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    backgroundColor: formLightBackgroundColor,
    borderRadius: theme.shape.borderRadius,
  },

  or: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },

  cardContent: {
    padding: 0,
  },

  createButton: {
    // Keep button in the middle
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default useStyles;
