import { FeatureFlag } from "./featureFlags";
import { localStorageKey } from "../constants";

// Types
import type { AppStore } from "../store";
import { createFlags } from "flag";
import { createReduxBindings } from "flag/redux";

// Generate flags helpers
const { FlagsProvider } = createFlags<Record<FeatureFlag, boolean>>();
// Generate flags Redux helpers
const { setFlagsAction, createFlagsReducer, ConnectedFlagsProvider } = createReduxBindings(
  FlagsProvider,
);

const getDefault = (flag: FeatureFlag): boolean => {
  return window.localStorage.getItem(localStorageKey.featureFlag(flag)) === "true";
};

/**
 * Helper function for easy terminal flags switch
 *
 * @param store Redux store
 * @param flag flag name
 */
const setFlag = (store: AppStore, flag: FeatureFlag) => (): boolean => {
  const newValue = !store.getState().flags[flag];
  store.dispatch(setFlagsAction({ [flag]: newValue }));
  console.log(`[Feature Flags] ${flag} toggle ${newValue ? "on" : "off"}`);
  window.localStorage.setItem(localStorageKey.featureFlag(flag), newValue.toString());
  return newValue;
};

/**
 * Generate object with all Feature Flags toggles
 *
 * @param store Redux store
 */
const setFlags = (store: AppStore): Record<FeatureFlag, () => boolean> => {
  const result: Partial<Record<FeatureFlag, () => boolean>> = {};

  Object.values(FeatureFlag).forEach((flag) => {
    result[flag] = setFlag(store, flag);
  });

  return result as Record<FeatureFlag, () => boolean>;
};

export { createFlagsReducer, ConnectedFlagsProvider };

const flags = {
  getDefault,
  setFlags,
};

export default flags;
