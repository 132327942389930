import { DateTime } from "luxon";

export enum FilterType {
  ContainsStr = "containsStr",
  Date = "date",
  MultiSelect = "multiSelect",
  Bool = "bool",
}

export type FilterSpecification = {
  filterType?: FilterType;
  containsStr?: string;
  gte?: DateTime;
  lte?: DateTime;
  bool?: string;
  includedIn?: string[];
};

export interface IFilters {
  [key: string]: FilterSpecification;
}
