export type Role = "user" | "admin";
export const userRoles: Role[] = ["user", "admin"];

interface RoleOption {
  title: Role;
  value: Role;
}

export const userRolesOptions: RoleOption[] = [
  {
    value: "user",
    title: "user",
  },
  {
    value: "admin",
    title: "admin",
  },
];
