import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import LuxonUtils from "@date-io/luxon";

// Local
import Root from "./root";
import analytics from "./analytics";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./store";
import { firebase } from "./firebase";

// Initialize Firebase
firebase.init();

// Browser history and connect pageView analytics
const history = createBrowserHistory();
history.listen(analytics.pageView);

// Redux store with persistor
export const appStore = store.configure(history);

// Helper render function to enable hot-mode reload
const renderApp = (): void =>
  ReactDOM.render(
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <Root store={appStore} history={history} />
    </MuiPickersUtilsProvider>,
    document.getElementById("root") as HTMLDivElement,
  );

// Hot reload
if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./root", renderApp);
}

// Render our app
renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
