import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";

// Local
import Detail from "./detail/detail";
import Users from "../users";
import Items from "../items";
import routes from "../routes";
import UserInfo from "../userInfo";
import Emails from "../emails";
import InventoryCheck from "../inventoryCheck";
import { firestoreQuery } from "../constants";
import { useLoadEmails } from "../emails/actions";
import { NotFound } from "../pages";
import { useCurrentWsDetail, useLoading } from "../firebase";
import { Loading } from "../components";
import { useIsAdmin, useUserId } from "../authentication/hooks";

// Types
import type { WsParams } from "../routes";

const WorkspaceSwitch: React.FC = () => {
  const { workspaceId } = useParams<WsParams>();
  const userId = useUserId();
  const { isAdmin } = useIsAdmin();
  const loadEmails = useLoadEmails();
  const loadingForWorkspaceId = React.useRef<string>();
  const currentWsDetail = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);

  React.useEffect(() => {
    if (isAdmin && workspaceId !== loadingForWorkspaceId.current) {
      loadingForWorkspaceId.current = workspaceId;
      loadEmails(workspaceId);
    }
  }, [isAdmin, workspaceId, loadEmails]);

  const isWorkspaceIdTemplate = workspaceId === ":workspaceId";

  // updates state so we can be checking if workspaceUser has admin role
  useFirestoreConnect(
    isWorkspaceIdTemplate
      ? []
      : [
          {
            collection: "workspaces",
            doc: workspaceId,
            subcollections: [
              {
                collection: "users",
                doc: userId ?? "",
              },
            ],
            storeAs: firestoreQuery.currentWsUser,
          },
          {
            collection: "workspaces",
            doc: workspaceId,
            subcollections: [{ collection: "users" }],
            storeAs: firestoreQuery.wsUsers,
          },
          {
            collection: "workspaces",
            doc: workspaceId,
            storeAs: firestoreQuery.currentWsDetail,
          },
          {
            collection: "workspaces",
            doc: workspaceId,
            subcollections: [
              {
                collection: "items",
                where: isAdmin ? undefined : ["user", "==", userId],
              },
            ],
            storeAs: firestoreQuery.wsItems,
          },
        ],
  );

  if (loading) return <Loading />;

  if (
    !loading &&
    loadingForWorkspaceId &&
    workspaceId in currentWsDetail &&
    currentWsDetail[workspaceId] == null
  ) {
    return (
      <NotFound
        title="Access denied"
        message={() => <>Sorry, you don't have permission to access this workspace.</>}
      />
    );
  }

  return (
    <Switch>
      <Route path={routes.workspaceDetail()} exact>
        <Detail />
      </Route>
      <Route path={routes.workspaceUserInfo()}>
        <UserInfo />
      </Route>
      <Route path={routes.workspaceUsers()}>
        <Users />
      </Route>
      <Route path={routes.workspaceItems()}>
        <Items />
      </Route>
      <Route path={routes.workspaceEmails()}>
        <Emails />
      </Route>
      <Route path={routes.workspaceInventoryCheck()}>
        <InventoryCheck />
      </Route>
      <Route>
        <NotFound
          message={(pathname) => (
            <>
              We are sorry but the URL <code>{pathname}</code> does not exist in this workspace.
            </>
          )}
        />
      </Route>
    </Switch>
  );
};

export default WorkspaceSwitch;
