import React from "react";
import { useSelector } from "react-redux";

// Material UI
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Local
import useStyles from "./styles";

// Types
import type { AppState } from "../store";

const Profile: React.FC = () => {
  const classes = useStyles();

  // Redux
  const profileImage = useSelector<AppState, string | null>(
    (state) => state.firebase.profile.photoURL,
  );
  const userName = useSelector<AppState, string>((state) => state.firebase.profile.name);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Avatar
          className={classes.avatar}
          src={profileImage === null ? undefined : profileImage}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{userName}</Typography>
      </Grid>
    </Grid>
  );
};
export default Profile;
