import React from "react";
import { Link, useParams } from "react-router-dom";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Locals
import DefaultTemplate from "./template";
import useStyles from "./styles";
import { firestoreTimestampToFormatted } from "../../helpers/format";
import { useCurrentWsDateFormat, useUser } from "../../firebase";
import routes from "../../routes";

// Types
import type { WsItemParams } from "../../routes";
import type { HistoryItemType } from "./types";

interface Props {
  data: HistoryItemType;
}

const Item: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsItemParams>();
  const dateFormat = useCurrentWsDateFormat();
  const { displayName } = useUser(data.actorId);

  const formatedDate = firestoreTimestampToFormatted(data.time, dateFormat);
  const tooltipTitle =
    data.field === "notes" &&
    `Changed from: "${data.before || "no value"}" to: "${data.after || "no value"}".`;

  const textEl = (
    <span className={classes.historyMessage}>
      {formatedDate} -{" "}
      <Link
        className={classes.spanText}
        to={routes.workspaceUserInfo(workspaceId, data.actorId as string)}
      >
        <span className={classes.spanText}>{`${displayName || data.actorId}`}</span>
      </Link>{" "}
      <DefaultTemplate data={data} workspaceId={workspaceId} dateFormat={dateFormat} />
    </span>
  );

  if (tooltipTitle)
    return (
      <Tooltip title={tooltipTitle} placement="bottom-start">
        {textEl}
      </Tooltip>
    );
  return textEl;
};

export default Item;
