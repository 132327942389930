import { json2csv } from "json-2-csv";
import React, { ReactNode, ReactElement } from "react";

import { firestoreTimestampToFormatted } from "../../../helpers/format";

import firebase from "firebase/compat/app";
import { Column } from "material-table";
import { RowData } from "../types";

// Taken from default behavior of material table export
// https://github.com/mbrn/material-table/blob/master/src/utils/data-manager.js#L505-L515
// https://github.com/mbrn/material-table/blob/master/src/components/m-table-toolbar.js#L58-L75

interface ExtendedColumn extends Column<RowData> {
  tableData: { columnOrder: number };
  field: string;
  filterType?: string;
}

interface ElementProps {
  children?: ReactNode;
  checked?: string[];
}

const filterColumns = (
  columns: Array<Column<RowData> & { tableData: { columnOrder: number }; field: string }>,
) =>
  columns
    .filter(
      (columnDef) =>
        (!columnDef.hidden || columnDef.export === true) &&
        columnDef.export !== false &&
        columnDef.field,
    )
    .sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1));

const getContentFromElement = (element: ReactElement<ElementProps>): string[] | null => {
  if (React.isValidElement(element)) {
    if (React.Children.count(element.props.children) === 0) {
      return element.props.checked || null;
    } else {
      return React.Children.map(element.props.children, (child: ReactNode) => {
        if (typeof child === "string" || typeof child === "number") {
          return String(child);
        } else if (React.isValidElement(child)) {
          // If it's another React element, recursively access its content
          return getContentFromElement(child as ReactElement<ElementProps>);
        }
        return null;
      }) as string[];
    }
  }
  return null;
};

const filterData = (data: Array<RowData>, columns: Array<ExtendedColumn>) =>
  data.map((rowData) =>
    columns.map((columnDef) => {
      let value = rowData[columnDef.field];

      // reformat Firestore timestamps
      if (columnDef.filterType === "date") {
        value = firestoreTimestampToFormatted(value as firebase.firestore.Timestamp);
      }

      if (typeof value !== "string") {
        value = getContentFromElement(value as ReactElement);
      }

      return String(value);
    }),
  );

const createJsonFromData = (
  columns: string[],
  data: (string | number)[][],
): Record<string, unknown>[] => {
  return data.map((row) => {
    const obj: Record<string, unknown> = {};
    columns.forEach((column, index) => {
      obj[column] = row[index];
    });
    return obj;
  });
};

export const exportCsv = async (
  columns: Array<Column<RowData> & { tableData: { columnOrder: number }; field: string }>,
  data: Array<RowData>,
  type: string,
): Promise<void> => {
  try {
    const newColumns = filterColumns(columns);
    const newData = filterData(data, newColumns);

    const jsonData = createJsonFromData(
      newColumns.map((column) => column.field),
      newData,
    );

    const csv = await json2csv(jsonData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `listiq-${type}-export.csv`;
    link.click();
  } catch (err) {
    console.error(err);
    throw err;
  }
};
