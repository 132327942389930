import makeStyles from "@material-ui/core/styles/makeStyles";
import { cardLookStyle } from "../globalStyles";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      display: "flex",
    },
    "& .ReactVirtualized__Table__row": {
      display: "flex",
      "&:focus": {
        outline: "none",
      },
    },
  },
  flexContainer: {
    display: "flex",
    height: theme.spacing(6),
  },
  addButton: {
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing(2),
  },
  gridCards: {
    minHeight: `calc(100vh - ${theme.spacing(8) + 64}px - 4.9rem)`,
    columnGap: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  cardTitle: { lineHeight: 1.2, fontSize: 20, marginBottom: 8 },
  wrapper: {
    ...cardLookStyle,
    alignSelf: "start",
    padding: theme.spacing(2),
    backgroundColor: "white",
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
