import { SingleItemState } from "../types";
import { User } from "../../users/types";
import { RowData } from "../../components/table/types";

export const getUserEmail = (users?: Record<string, User>, userId?: string | null): string => {
  if (!users || !userId) return "";
  return users[userId]?.email || "";
};

const archivedFilter = (archivedStatusList: string[], showArchived: boolean) => ([_, value]: [
  unknown,
  SingleItemState,
]) =>
  !!value &&
  (showArchived
    ? archivedStatusList.includes(value.status ?? "")
    : !archivedStatusList.includes(value.status ?? ""));

export const prepareDataForTable = (
  items: Record<string, SingleItemState>,
  users: Record<string, User>,
  archivedStatusList?: string[],
  showArchived?: boolean,
): RowData[] =>
  items
    ? (archivedStatusList
        ? Object.entries(items).filter(archivedFilter(archivedStatusList, !!showArchived))
        : Object.entries(items)
      ).map(([id, value]) => ({
        id,
        parent: value?.parent || "",
        name: value?.name,
        internalNumber: value?.internalNumber || "",
        type: value?.type || "",
        costCenter: value?.costCenter || "",
        status: value?.status || "",
        location: value?.location || "",
        warrantyUntil: value?.warrantyUntil,
        // warrantyUntil: secondsToFormatted(value?.warrantyUntil?.seconds),
        bought: value?.bought,
        // bought: secondsToFormatted(value?.bought?.seconds),
        user: getUserEmail(users, value?.user),
        notes: value?.notes || "",
        serialNumber: value?.serialNumber || "",
        extendedWarranty: `${value?.extendedWarranty ?? ""}`,
        tags: value?.tags || [],
      }))
    : [];
