import React from "react";

// Material UI
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Locals
import { ConfirmButton } from "../../components/buttons";
import useStyles from "../styles";

// Types
import type { ButtonProps } from "@material-ui/core/Button";

interface Props {
  onCancel: ButtonProps["onClick"];
  onAdd: ButtonProps["onClick"];
  loading?: boolean;
}

const AddUserActions: React.FC<Props> = ({ loading, onAdd, onCancel }) => {
  const classes = useStyles();
  return (
    <DialogActions className={classes.removeActionsMarginTop}>
      <Button disabled={loading} data-cy={"btn-cancel"} onClick={onCancel}>
        Cancel
      </Button>
      <ConfirmButton disabled={loading} loading={loading} onClick={onAdd}>
        Add
      </ConfirmButton>
    </DialogActions>
  );
};

export default AddUserActions;
