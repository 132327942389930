import React from "react";

// Material UI
import Avatar from "@material-ui/core/Avatar";

// Locals
import LeftInfoPanel from "./leftInfoPanel";
import CenterInfoPanel from "./centerInfoPanel";

// Types
import type { WorkspaceUser } from "../../users/types";

// Styles
import useStyles from "../styles";

interface Props {
  user: WorkspaceUser;
}

const UserSection: React.FC<Props> = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={`myitems-userinfo ${classes.userSectionWrap}`}>
      <Avatar
        data-cy={"user-avatar"}
        className={classes.avatar}
        src={user?.photoURL || undefined}
      />
      <div className={classes.userInfoSection}>
        <LeftInfoPanel userData={user} />
        <CenterInfoPanel user={user} />
      </div>
    </div>
  );
};

export default UserSection;
