import React from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";

// Locals
import { useData } from "../../firebase";
import UserSection from "./userSection";
import UserItemsSection from "../userItems";
import SingleItem from "../../items/singleItemView/singleItem";

// Types
import type { Params } from "../../routes";
import type { WorkspaceUser } from "../../users/types";

// Queries
import { firestoreQuery } from "../../constants";

// Routes
import routes from "../../routes";
import { useUserId } from "../../authentication/hooks";
import Grid from "@material-ui/core/Grid";
import RightInfoPanel from "./rightInfoPanel";

const UserInfo: React.FC = () => {
  const { workspaceId, userId } = useParams<Params>();

  useFirestoreConnect([
    {
      collection: "workspaces",
      doc: workspaceId,
      subcollections: [
        {
          collection: "items",
          where: ["user", "==", userId],
        },
      ],
      storeAs: firestoreQuery.currentWsUserItems,
    },
  ]);

  const loggedUserId = useUserId();

  // Getting user info
  const wsUsers = useData<WorkspaceUser>(firestoreQuery.wsUsers);
  const currentWSUser = useData<WorkspaceUser>(firestoreQuery.currentWsUser);

  const selectedWsUser = React.useMemo(
    () => (loggedUserId === userId ? currentWSUser : userId && wsUsers ? wsUsers[userId] : {}),
    [currentWSUser, loggedUserId, userId, wsUsers],
  );

  const extensibleUser = { ...selectedWsUser };
  extensibleUser.id = userId;

  React.useEffect(() => {
    if (selectedWsUser?.displayName) {
      document.title = `${selectedWsUser.displayName} - listiq`;
    }
  }, [selectedWsUser]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <UserSection user={extensibleUser} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RightInfoPanel user={extensibleUser} />
        </Grid>
        <Grid item xs={12}>
          <UserItemsSection userName={selectedWsUser?.name} />
        </Grid>
      </Grid>
      <Switch>
        <Route path={routes.workspaceUserInfoItemDetail()}>
          <div style={{ width: 300 }}>
            <SingleItem type="userInfoItem" />
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default UserInfo;
