import React from "react";
import { Redirect, Route } from "react-router-dom";

// Local
import Loading from "../components/loading";
import routes from "../routes";
import { useAuthLoaded, useAuthEmpty } from "../firebase";

// Types
import type { RouteProps } from "react-router-dom";

const AuthRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const loaded = useAuthLoaded();
  const empty = useAuthEmpty();

  if (!loaded) return <Loading fullScreen logo />;

  if (empty) return <Redirect to={routes.login()} />;

  return <Route {...props}>{children}</Route>;
};

export default AuthRoute;
