import React from "react";

// Material UI
import Chip from "@material-ui/core/Chip";
// Types
import { FilterType, IFilters } from "./types";
// Helpers
import { prepareLabel } from "../helpers/filter";
import { camelCaseToText } from "../../../helpers/format";
// Styles
import useStyles from "./styles";

interface Props {
  filters: IFilters;
  onDeleteClick: (filters: IFilters) => void;
}

const Tags: React.FC<Props> = ({ filters, onDeleteClick }) => {
  const classes = useStyles();

  const handleOnDeleteClick = (fieldToRemoveKey: string, entry?: string) => () => {
    const newFilters = { ...filters };
    const processedFilter = newFilters[fieldToRemoveKey].includedIn;
    if (entry && processedFilter && processedFilter.length) {
      newFilters[fieldToRemoveKey].includedIn = processedFilter.filter(
        (value) => value !== entry,
      );
    } else {
      delete newFilters[fieldToRemoveKey];
    }
    onDeleteClick(newFilters);
  };

  return (
    <ul className={classes.listOfTags}>
      {Object.entries(filters).map(([key, value]) => {
        if (value.filterType === FilterType.MultiSelect)
          return value.includedIn?.map((entry) => (
            <li key={`${key}: ${entry}`}>
              <Chip
                style={{ marginLeft: 2, marginTop: 2 }}
                label={`${camelCaseToText(key)}: ${entry}`}
                size="small"
                onDelete={handleOnDeleteClick(key, entry)}
              />
            </li>
          ));
        else {
          return (
            <li key={key}>
              <Chip
                style={{ marginLeft: 2, marginTop: 2 }}
                label={prepareLabel(value, camelCaseToText(key))}
                size="small"
                onDelete={handleOnDeleteClick(key)}
              />
            </li>
          );
        }
      })}
    </ul>
  );
};

export default Tags;
