import React from "react";

// Material UI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// Locals
import { ConfirmButton, HelpIcon } from "../../buttons";
import FilterRowSwitch from "./filterRowSwitch";
import useStyles from "./styles";

// Types
import type { MyColumn } from "../types";
import type { IFilters, FilterSpecification } from "./types";

interface Props {
  columns: MyColumn[];
  onApplyClick: (filters: IFilters) => void;
  filters: IFilters;
}

const Filter: React.FC<Props> = ({ columns, filters, onApplyClick }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filtersState, setFiltersState] = React.useState<IFilters>(filters);

  const handleOnChange = React.useCallback(
    (value: FilterSpecification | null, field: string) => {
      // When user removes the filter value by backspace key - its set to null
      // Here we want to clean the filter object from null values for eaasier manipulation fo other components
      // (tags) e.g
      const newFiltersState = { ...filtersState };
      if (!value) {
        delete newFiltersState[field];
      } else {
        newFiltersState[field] = value;
      }

      setFiltersState(newFiltersState);
    },
    [filtersState, setFiltersState],
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.filterOpener}>
        <Button
          className={classes.filterInputWrap}
          onClick={() => setOpen(!open)}
          variant="outlined"
        >
          filters
        </Button>
        {open && (
          <Paper elevation={3} className={classes.filters} onKeyDown={handleOnKeyPress}>
            <div className={classes.filtersBody}>
              {columns
                .filter((column: MyColumn) => !column.hidden)
                .map((column: MyColumn, index: number) => (
                  <React.Fragment key={column.field}>
                    <FilterRowSwitch
                      focus={index === 0}
                      field={column.field}
                      onChange={handleOnChange}
                      label={column.title}
                      value={filtersState[column.field]}
                      type={column.filterType}
                    />
                  </React.Fragment>
                ))}
            </div>
            <div className={classes.filtersActions}>
              <HelpIcon title="Filtering options are shown based on the displayed columns. Enable columns in your table for more filtering options." />
              <Button onClick={() => setFiltersState({})} className={classes.marginRight}>
                Clear
              </Button>
              <ConfirmButton onClick={() => onApplyClick(filtersState)}>Apply</ConfirmButton>
            </div>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Filter;
