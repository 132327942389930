import React from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import { AutoSizer, Column, Table } from "react-virtualized";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

import { firebase } from "../firebase";
import { MultiAutoCompleteSelect } from "../components/autocompleteSelect";
import { firestoreQuery } from "../constants";
import { useCurrentWsDetail, useOrdered } from "../firebase";
import { SingleItemState } from "../items/types";
import routes, { WsParams } from "../routes";
import useStyles from "./styles";
import { Loading } from "../components";

const InventoryCheckCreate: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { workspaceId } = useParams<WsParams>();
  const { archivedStatusList = [], fields = {} } = useCurrentWsDetail();
  const [onlyNonArchived, setOnlyNonArchived] = React.useState<boolean>(true);
  const [filters, setFilters] = React.useState<Record<string, string[]>>({});
  const items = useOrdered<SingleItemState>(firestoreQuery.wsItems);
  const [notIncluded, setNotIncluded] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const filteredItems = React.useMemo<SingleItemState[]>(
    () =>
      items?.filter((item) => {
        if (onlyNonArchived && item.status && archivedStatusList.includes(item.status)) {
          return false;
        }
        if (Object.keys(filters).length) {
          return Object.entries(filters).every(
            ([key, values]) => !values.length || values.includes(item[key] as string),
          );
        }
        return true;
      }) ?? [],
    [archivedStatusList, items, onlyNonArchived, filters],
  );

  const onSubmit = React.useCallback(() => {
    setLoading(true);
    firebase
      .functions()
      .httpsCallable("createInventoryCheck")({
        workspaceId,
        itemsIds: filteredItems
          .filter((item) => item.id && !notIncluded.includes(item.id))
          .map((item) => item.id),
      })
      .then(() => {
        push(routes.workspaceInventoryCheck(workspaceId));
      });
  }, [notIncluded, filteredItems, workspaceId, push]);

  const includedItemsLength = React.useMemo<number>(
    () =>
      filteredItems.reduce<number>(
        (prev, curr) => (prev += notIncluded.includes(curr.id ?? "") ? 0 : 1),
        0,
      ),
    [filteredItems, notIncluded],
  );

  return (
    <>
      <div className={classes.title}>
        <Typography variant="h5">Create new stock-taking</Typography>
        <Typography variant="subtitle2">Items to be verified: {includedItemsLength}</Typography>
      </div>
      <Button
        disabled={loading}
        className={classes.addButton}
        color="secondary"
        variant="contained"
        size="medium"
        component={Link}
        to={routes.workspaceInventoryCheck(workspaceId)}
      >
        Go back
      </Button>
      <Button
        disabled={loading}
        className={classes.addButton}
        color="secondary"
        variant="contained"
        size="medium"
        onClick={onSubmit}
      >
        Create {loading ? <Loading size={20} /> : undefined}
      </Button>
      <div className={classes.gridCards}>
        <div className={classes.wrapper}>
          <ButtonGroup color="secondary">
            <Button
              disabled={loading}
              onClick={() => setOnlyNonArchived(false)}
              variant={onlyNonArchived ? "outlined" : "contained"}
            >
              All Items
            </Button>
            <Button
              disabled={loading}
              onClick={() => setOnlyNonArchived(true)}
              variant={onlyNonArchived ? "contained" : "outlined"}
            >
              Non-Archived only
            </Button>
          </ButtonGroup>
          <br />
          <br />
          {Object.entries(fields).map(([key, field]) => (
            <div key={key}>
              <MultiAutoCompleteSelect
                label={field.name}
                name={key}
                variant="outlined"
                placeholder={`Start typing here to filter by ${field.name}`}
                setValue={(values: string[], key: string) =>
                  setFilters({ ...filters, [key]: values })
                }
                value={filters[key]}
              />
              <br />
            </div>
          ))}
        </div>
        <div className={classes.wrapper} style={{ alignSelf: "stretch" }}>
          <AutoSizer>
            {({ height, width }) => (
              <Table
                height={height}
                width={width}
                rowHeight={48}
                headerHeight={0}
                rowCount={filteredItems.length}
                onRowClick={({ rowData: { id } }) => {
                  const newNotIncluded = [...notIncluded];
                  const currentIndex = notIncluded.indexOf(id);
                  if (currentIndex !== -1) newNotIncluded.splice(currentIndex, 1);
                  else newNotIncluded.push(id);
                  setNotIncluded(newNotIncluded);
                }}
                rowGetter={({ index }) => filteredItems[index]}
                className={classes.table}
              >
                <Column
                  dataKey="name"
                  width={width}
                  cellRenderer={({ cellData, rowData: { id } }) => (
                    <TableCell className={classes.flexContainer}>
                      <Checkbox checked={!notIncluded.includes(id)} />
                      {cellData}
                    </TableCell>
                  )}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </>
  );
};

export default InventoryCheckCreate;
