import React from "react";
import { useHistory, useRouteMatch } from "react-router";

// Material-UI
import Button from "@material-ui/core/Button";

// Locals
import { ConfirmButton } from "../buttons";
import Dialog from "../dialog";
import Dropzone from "./dropzone";

// Types
import type { DropzoneAreaProps } from "material-ui-dropzone";

/**
 * @param redirectTo Used for redirecting to given url which can come handy when using the dialog in ClickAwayDrawer
 * component and being able to avoid clickAway action by not closing if we are on specific url (redirectTo url for
 * example).
 * @param redirectBack Used to redirect back to given url after submitting the form for example.
 */
interface Props extends DropzoneAreaProps {
  loading?: boolean;
  onConfirm: () => void;
  redirectTo: string;
  redirectBack: string;
}

const UploadDialog: React.FC<Props> = ({
  loading,
  onConfirm,
  redirectBack,
  redirectTo,
  ...props
}) => {
  const { push } = useHistory();
  const shouldOpen = !!useRouteMatch({ path: redirectTo }) && !!redirectTo;

  const onCancel = () => push(redirectBack);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      onCancel();
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        color="secondary"
        onClick={() => push(redirectTo)}
        variant="outlined"
        style={{ maxHeight: 40 }}
        fullWidth
      >
        Attach files
      </Button>
      <Dialog
        open={shouldOpen}
        title="Attach Files"
        actions={
          <>
            <Button disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <ConfirmButton disabled={loading} onClick={onConfirm} loading={loading}>
              Upload
            </ConfirmButton>
          </>
        }
        onKeyDown={handleOnKeyDown}
        onClose={onCancel}
        helpText="Please be aware that files with the same name will over-write each other. Make sure you do not upload the file with name which already exists on this item. File size limit is 5 MB."
      >
        <Dropzone {...props} />
      </Dialog>
    </>
  );
};

export default UploadDialog;
