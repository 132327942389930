import { NewUserFormState } from "../../users/types";
import { isValidEmail, isValidRole } from "./common";

type BeforeSubmitValidation = {
  isValid: boolean;
  newUsersToBeAdded: Record<string, NewUserFormState>;
};

export const beforeSubmitUserValidation = (
  newUsersToBeAdded: Record<string, NewUserFormState>,
): BeforeSubmitValidation => {
  let isValid = true;

  // input validation
  Object.keys(newUsersToBeAdded).forEach((key) => {
    // Is there email verification
    if (!isValidEmail(newUsersToBeAdded[key as string].email)) {
      isValid = false;
      newUsersToBeAdded[key as string] = {
        ...newUsersToBeAdded[key as string],
        emailError: true,
      };
    } else {
      newUsersToBeAdded[key as string] = {
        ...newUsersToBeAdded[key as string],
        emailError: false,
      };
    }

    // Is there valid role
    if (!isValidRole(newUsersToBeAdded[key as string].role)) {
      isValid = false;
      newUsersToBeAdded[key as string] = {
        ...newUsersToBeAdded[key as string],
        roleError: true,
      };
    } else {
      newUsersToBeAdded[key as string] = {
        ...newUsersToBeAdded[key as string],
        roleError: false,
      };
    }
  });

  return {
    isValid: isValid,
    newUsersToBeAdded: newUsersToBeAdded,
  };
};
