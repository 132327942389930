import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(1),
  },
  dialogTitleLeft: {
    display: "flex",
    alignItems: "center",
  },
  dialogActions: {
    marginTop: theme.spacing(4),
  },
}));

export default useStyles;
