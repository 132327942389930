import React from "react";

// Material UI
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Locals
import { ButtonTooltip, ConfirmButton } from "../../components/buttons";

// Styles
import useStyles from "../styles";

// Types
import type { ButtonProps } from "@material-ui/core/Button";

interface Props {
  onCancel: ButtonProps["onClick"];
  onAdd?: ButtonProps["onClick"];
  loading?: boolean;
}

const AddItemActions: React.FC<Props> = ({ onCancel, onAdd, loading }) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.removeActionsMarginTop}>
      <ButtonTooltip title="(Esc)">
        <Button disabled={loading} data-cy="btn-add-item-cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonTooltip>
      <ConfirmButton
        disabled={loading}
        data-cy="btn-add-item-confirm-and-close"
        color="default"
        variant="text"
        onClick={async (e) => {
          if (await onAdd?.(e)) {
            await onCancel?.(e);
          }
        }}
      >
        Add and close
      </ConfirmButton>
      <ButtonTooltip title="(Ctrl + Enter)" placement="top-end">
        <ConfirmButton
          disabled={loading}
          loading={loading}
          onClick={onAdd}
          data-cy="btn-add-item-confirm"
        >
          Add
        </ConfirmButton>
      </ButtonTooltip>
    </DialogActions>
  );
};

export default AddItemActions;
