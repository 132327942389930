import { useSelector } from "react-redux";

// Types
import { AppState } from "../store";
import { Email } from "./types";

export interface EmailsState {
  data: Email[] | null;
  error: string | null;
  loading: boolean;
}

export const initialState: EmailsState = {
  data: null,
  error: null,
  loading: false,
};

export const useEmails = (): Email[] | null => {
  return useSelector<AppState, Email[] | null>((state) => state.emails.data);
};

export const useEmailsLoading = (): boolean => {
  return useSelector<AppState, boolean>((state) => state.emails.loading);
};

export const useEmailsError = (): string | null => {
  return useSelector<AppState, string | null>((state) => state.emails.error);
};
