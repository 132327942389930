import React from "react";

// MUI
import { DropzoneArea } from "material-ui-dropzone";

// Locals
import useStyles from "./styles";

// Types
import type { DropzoneAreaProps } from "material-ui-dropzone";

const Dropzone: React.FC<DropzoneAreaProps> = (props) => {
  const classes = useStyles();
  const filesLimit = 10;
  const maxFileSize = 5242880;

  return (
    <DropzoneArea
      {...props}
      classes={classes}
      showAlerts={["error"]}
      showFileNames
      clearOnUnmount
      useChipsForPreview
      maxFileSize={maxFileSize}
      filesLimit={filesLimit}
      dropzoneText={`Click or drag and drop up to ${filesLimit} files here.\nMax size ${
        maxFileSize / 1024 / 1024
      } MB`}
    />
  );
};

export default Dropzone;
