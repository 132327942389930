import React from "react";

import HelpOutline from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

import type { TooltipProps } from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  helper: {
    color: "rgba(0,0,0,0.54)",
    cursor: "help",
    marginLeft: 5,
  },
});

const HelpIcon: React.FC<Omit<TooltipProps, "children">> = (props) => {
  const classes = useStyles();

  return (
    <Tooltip placement="right" {...props}>
      <HelpOutline className={classes.helper} />
    </Tooltip>
  );
};

export default HelpIcon;
