import React from "react";
import { useParams } from "react-router-dom";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

// Locals
import { useCurrentWsDetail, useLoading } from "../../firebase";
import { useAddWorkspaceFieldValue } from "../../workspace/api";
import Loading from "../loading";
import { isNotAlreadyAnOption } from "./helpers";
import useCustomSnackbar from "../snackbar";
import { firestoreQuery } from "../../constants";

// Types
import type { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import type { TextFieldProps } from "@material-ui/core/TextField";
import type { WsParams } from "../../routes";
import { Option } from "./types";

// Styles
import useStyles from "./styles";

interface Props
  extends Pick<
    TextFieldProps,
    "value" | "variant" | "label" | "name" | "InputProps" | "error" | "required" | "placeholder"
  > {
  options?: Option[];
  value?: string | null;
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setValue: (value: string, field: string) => void;
  className?: TextFieldProps["className"];
  creatable?: boolean;
  size?: "small" | "medium";
}

const filter = createFilterOptions<Option>();

const ConnectedAutoCompleteSelect: React.FC<Props> = ({
  options, // these options will be rendered
  creatable, // add "item name" option will be added.
  name = "", // Name should match with the field in the state - DB - id of the specific field if in currentWsDetails.fields
  setValue,
  className,
  value = "",
  size = "medium",
  placeholder = "Type and select one.",
  ...other
}) => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();
  const { enqueueSnackbar } = useCustomSnackbar();
  const updateWsFieldValue = useAddWorkspaceFieldValue(true);
  const { fields } = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);

  const autoCompleteOnChange = React.useCallback(
    async (event, newValue) => {
      const valueToAdd = typeof newValue === "string" ? newValue : newValue?.inputValue;
      if (creatable && valueToAdd) {
        // add new field value to DB
        await updateWsFieldValue(valueToAdd, name, workspaceId);
        enqueueSnackbar("New option created and selected.", "success");
        // select it
        setValue(valueToAdd, name);
      } else if (typeof newValue !== "string") {
        setValue(newValue?.value, name);
      }
    },
    [creatable, enqueueSnackbar, name, setValue, updateWsFieldValue, workspaceId],
  );

  const filteredOptions = React.useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      // Suggest the creation of a new value
      if (creatable && params.inputValue && isNotAlreadyAnOption(options, params.inputValue)) {
        filtered.push({
          inputValue: params.inputValue,
          title: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    [creatable],
  );

  const finalOptions = React.useMemo<Option[]>(
    () =>
      options || fields?.[name]?.values.map((value: string) => ({ title: value, value })) || [],
    [fields, name, options],
  );

  const valueToDisplay = React.useMemo<Option>(
    () => ({
      title: finalOptions?.find((option) => option.value === value)?.title || value || "",
    }),
    [finalOptions, value],
  );

  if (loading) return <Loading />;

  return (
    <Autocomplete
      value={valueToDisplay}
      onChange={autoCompleteOnChange}
      filterOptions={filteredOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={finalOptions}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => option.title}
      freeSolo
      className={className}
      classes={{ input: classes.placeholder }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField placeholder={placeholder} {...other} {...params} />
      )}
    />
  );
};

export default ConnectedAutoCompleteSelect;
