import React from "react";

// Mui
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MUIDialog from "@material-ui/core/Dialog";

// Local
import { getCurrentYear } from "../../helpers/format";
import useStyles from "./styles";
import { ConfirmButton } from "../buttons";
import { clearLocalData } from "../../helpers/clearLocalData";
import config from "../../config";

interface IProps {
  close: () => void;
  open: boolean;
}

const About: React.FC<IProps> = ({ close, open }) => {
  const classes = useStyles();
  const oldTitle = React.useRef(document.title);

  const handleOnKeyDown = (e: { key: string }) => {
    if (e.key === "Escape") {
      close();
    }
  };

  React.useEffect(() => {
    if (open) {
      oldTitle.current = document.title;
      document.title = "About - listiq";
    } else {
      document.title = oldTitle.current;
    }
  }, [open]);

  return (
    <MUIDialog open={open} onKeyDown={handleOnKeyDown} onClose={close}>
      <DialogTitle className={classes.title}>
        <Grid container justify="space-between" alignItems="center">
          <div>
            <span>About the app</span>
          </div>
          <IconButton onClick={close} classes={{ root: classes.zeroPadding }}>
            <CloseOutlined />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <br />
        <div className={classes.labelList}>
          <p>
            <span className={classes.rowLabel}>Version </span>
            <span>{process.env.REACT_APP_COMMIT_TAG}</span>
          </p>
          <p>
            <span className={classes.rowLabel}>Hash </span>
            <span>{process.env.REACT_APP_COMMIT_SHA}</span>
          </p>
          <p>
            <span className={classes.rowLabel}>Environment </span>
            <span>{process.env.REACT_APP_ENV}</span>
          </p>
          <p>
            <span className={classes.rowLabel}>Contact </span>
            <span>
              <a href={`mailto:${config.email.info}`}>{config.email.info}</a>
            </span>
          </p>
        </div>
        <br />
        <ConfirmButton
          onClick={clearLocalData}
          className={classes.clearLocalDataBtn}
          color="primary"
        >
          Clear Local Data
        </ConfirmButton>
        <a href={`mailto:${config.email.support}`} className={classes.getSupportLink}>
          <ConfirmButton className={classes.getSupportBtn}>Get support</ConfirmButton>
        </a>
        <br />
        <p className={classes.copyright}>© {getCurrentYear()} Listiq, All Rights Reserved.</p>
      </DialogContent>
    </MUIDialog>
  );
};

export default About;
