import React from "react";

import Typography from "@material-ui/core/Typography";

import useStyles from "../styles";

interface Props {
  title: string;
  value?: string | React.ReactElement;
  dataCy?: string;
}

const UserInfoDataRow: React.FC<Props> = ({ title, value, dataCy }) => {
  const classes = useStyles();
  return (
    <tr>
      <th style={{ textAlign: "right" }}>
        <Typography variant="body2" className={classes.smallMarginRight}>
          {title}:
        </Typography>
      </th>
      <th data-cy={dataCy} style={{ textAlign: "left" }}>
        <Typography variant="subtitle2">{value}</Typography>
      </th>
    </tr>
  );
};

export default UserInfoDataRow;
