import React from "react";

import firebase from "firebase/compat/app";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { useCurrentWsDetail } from "../../firebase";
import { MultipleAutoCompleteSelect } from "../../components/autocompleteSelect";
import { useUpdateWorkspace } from "../api";
import { useParams } from "react-router";
import { Params } from "../../routes";
import { HelpIcon } from "../../components/buttons";
import useCustomSnackbar from "../../components/snackbar";

const getAlteredStatus = (
  newList: string[],
  oldList?: string[],
): { add: boolean; value?: string } => {
  if (!oldList) return { add: true, value: newList[0] };
  const add = newList.length > oldList.length;
  const value = (add ? newList : oldList).find(
    (entry) => !(add ? oldList : newList).includes(entry),
  );
  return { add, value };
};

const ArchivedStatusManagement: React.FC = () => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { fields, archivedStatusList = [] } = useCurrentWsDetail() ?? {};
  const updateWs = useUpdateWorkspace();
  const { workspaceId } = useParams<Params>();

  const fieldStatusList = React.useMemo(
    () =>
      fields?.status.values
        .filter((status) => !archivedStatusList?.includes(status))
        .map((status) => ({
          value: status,
          title: status,
        })),
    [archivedStatusList, fields],
  );

  const handleChange = React.useCallback(
    async (newArchivedStatusList: string[]) => {
      const { add, value } = getAlteredStatus(newArchivedStatusList, archivedStatusList);
      try {
        await updateWs(
          {
            archivedStatusList: add
              ? firebase.firestore.FieldValue.arrayUnion(value)
              : firebase.firestore.FieldValue.arrayRemove(value),
          },
          String(workspaceId),
        );
        enqueueSnackbar(
          `The ${value} status has been successfully ${
            add ? "added to" : "removed from"
          } archived statuses list`,
          "success",
        );
      } catch (err) {
        enqueueSnackbar(
          `Failed to ${
            add ? `add ${value} to` : `remove ${value} from`
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          } the archived statuses list: ${err.message}`,
          "error",
        );
        console.error(err);
      }
    },
    [archivedStatusList, enqueueSnackbar, updateWs, workspaceId],
  );

  return (
    <>
      <Grid container alignItems="center">
        <Typography variant="h5">Archived statuses</Typography>
        <HelpIcon title="These are statuses of items which won't be displayed in the classic Items table, but can be shown after checking 'Show Archived items'" />
      </Grid>
      <br />
      <MultipleAutoCompleteSelect
        disableClearable
        variant="outlined"
        name="archivedStatusList"
        value={archivedStatusList}
        setValue={handleChange}
        options={fieldStatusList}
      />
    </>
  );
};

export default ArchivedStatusManagement;
