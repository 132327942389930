import configure from "./configure";

const store = {
  configure,
};

export default store;

export type { AppState } from "./reducer";
export type { AppStore } from "./configure";
