import React from "react";

// Material UI
import Tooltip from "@material-ui/core/Tooltip";
import { Column } from "material-table";

import { smallColumnStyle } from "../../globalStyles";
import { RowData } from "../../components/table/types";
import { firestoreTimestampToFormatted } from "../../helpers/format";
import { FilterType } from "../../components/table/filter/types";
import { Chip } from "@material-ui/core";

/**
 * How to use so it works with filtering!
 *
 *
 * Use filterType "date" ony when field is containing (firebase.timestamp or null) which we then convert to seconds.
 *
 * Keep columns with filterType "date" together for ui reasons in filters!
 *
 * When no filterType given and table has enableFiltering = true, containStr filterType will be used.
 *
 */

const defaultColumns = (
  dateFormat?: string,
): Array<
  Column<RowData> & {
    filterType?: FilterType;
  }
> => [
  {
    title: "ID",
    field: "id",
    hidden: true,
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Internal Number",
    field: "internalNumber",
  },
  {
    title: "Type",
    field: "type",
    filterType: FilterType.MultiSelect,
    ...smallColumnStyle,
  },
  {
    title: "Status",
    field: "status",
    filterType: FilterType.MultiSelect,
    ...smallColumnStyle,
  },
  {
    title: "Tags",
    field: "tags",
    filterType: FilterType.MultiSelect,
    render: (data: RowData) => {
      return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {(data.tags || []).map((tag) => (
            <Chip label={tag} size="small" />
          ))}
        </div>
      );
    },
  },
  {
    title: "Location",
    field: "location",
    hidden: true,
    filterType: FilterType.MultiSelect,
    ...smallColumnStyle,
  },
  {
    title: "User",
    field: "user",
    filterType: FilterType.MultiSelect,
  },
  {
    title: "Cost Center",
    field: "costCenter",
    hidden: true,
  },
  {
    title: "Warranty Expires",
    field: "warrantyUntil",
    filterType: FilterType.Date,
    render(data: RowData) {
      return firestoreTimestampToFormatted(data.warrantyUntil, dateFormat);
    },
    ...smallColumnStyle,
  },
  {
    title: "Bought",
    field: "bought",
    filterType: FilterType.Date,
    hidden: true,
    render(data: RowData) {
      return firestoreTimestampToFormatted(data.bought, dateFormat);
    },
    ...smallColumnStyle,
  },
  {
    title: "Extended Warranty",
    field: "extendedWarranty",
    filterType: FilterType.Bool,
    hidden: true,
    ...smallColumnStyle,
  },
  {
    title: "Serial Number",
    field: "serialNumber",
    hidden: true,
    ...smallColumnStyle,
  },
  {
    title: "Notes",
    field: "notes",
    hidden: true,
    render(data: RowData) {
      if (!data.notes) return "";
      // We want the notes tooltip to have new lines
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const lines = data.notes.split("\n");
      let formattedText;
      if (lines.length === 1) formattedText = data.notes;
      else {
        formattedText = (
          <p>
            {lines.map((line: string, i: number) => (
              <>
                {i ? <br /> : undefined}
                <span>{line}</span>
              </>
            ))}
          </p>
        );
      }
      return (
        <Tooltip title={formattedText} placement="bottom-start">
          <span>
            {/* Div that prevents safari from rendering second tooltip after long hover ... unfortunately no easier way to to override this behavior */}
            <div />
            {data.notes}
          </span>
        </Tooltip>
      );
    },
    ...smallColumnStyle,
  },
];

export default defaultColumns;
