import React from "react";
import { useParams } from "react-router-dom";

// Locals
import Loading from "../../components/loading";
import { firestoreTimestampToFormatted } from "../../helpers/format";
import Row from "./row";
import { firestoreQuery } from "../../constants";

// Material-ui
import Typography from "@material-ui/core/Typography";

// Redux / Firebase / Routes / Types
import {
  useCurrentWsDateFormat,
  useCurrentWsDetail,
  useCurrentWsTier,
  useLoading,
} from "../../firebase";
import type { WsParams } from "../../routes";
import DateFormatOptionSelect from "./dateFormatOptionSelect";

const BasicInfo: React.FC = () => {
  // Workspace ID from URL
  const { workspaceId } = useParams<WsParams>();
  const dateFormat = useCurrentWsDateFormat();
  // Workspace data
  const { name, createdAt } = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);
  const tier = useCurrentWsTier();

  if (loading) return <Loading />;

  return (
    <>
      <Typography variant="h5">Your workspace information</Typography>
      <br />
      <Row title="Id" value={workspaceId} />
      <br />
      <Row title="Name" value={name} />
      <br />
      <Row title="Created" value={firestoreTimestampToFormatted(createdAt, dateFormat)} />
      <br />
      <Row title="Tier" value={tier} />
      <br />
      <DateFormatOptionSelect />
    </>
  );
};

export default BasicInfo;
