import React from "react";

// Material UI
import MUIDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

// Locals
import { HelpIcon } from "../buttons";
import useStyles from "./styles";

// Types
import type { DialogProps } from "@material-ui/core/Dialog";

interface Props extends DialogProps {
  actions: React.ReactNode;
  helpText?: string;
}

const Dialog: React.FC<Props> = ({ actions, children, onClose, helpText, title, ...other }) => {
  const classes = useStyles();

  return (
    <MUIDialog {...other} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="center">
          <div className={classes.dialogTitleLeft}>
            {title}
            {helpText && <HelpIcon title={helpText} />}
          </div>
          <IconButton onClick={(e) => onClose?.(e, "escapeKeyDown")}>
            <CloseOutlined />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
