import React from "react";

import { useParams } from "react-router";

import { useCurrentWsDetail } from "../../firebase";
import Row from "./row";
import { useUpdateWorkspace } from "../api";
import { Params } from "../../routes";
import { Loading, Select } from "../../components";
import { DateFormatOption } from "../types";

const dateFormatOptions: DateFormatOption[] = ["dd.MM.yyyy", "MM/dd/yyyy"];
export const defaultDateFormatOption = dateFormatOptions[0];

type Option = {
  value: DateFormatOption;
  title: DateFormatOption;
};

const DateFormatOptionSelect: React.FC = () => {
  const wsDetail = useCurrentWsDetail();
  const { workspaceId } = useParams<Params>();
  const updateWorkspace = useUpdateWorkspace();
  const [format, setFormat] = React.useState<DateFormatOption>(
    wsDetail?.dateFormat ?? defaultDateFormatOption,
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const onHandleChange = React.useCallback(
    async (e) => {
      if (workspaceId && wsDetail) {
        const { value } = (e as React.ChangeEvent<Option>).target;
        setLoading(true);
        await updateWorkspace({ dateFormat: value }, workspaceId);
        setFormat(value);
        setLoading(false);
      }
    },
    [updateWorkspace, workspaceId, wsDetail],
  );

  return (
    <Row title="Date format">
      {loading ? (
        <Loading />
      ) : (
        <Select
          options={dateFormatOptions.map((dateFormat) => ({
            value: dateFormat,
            title: dateFormat,
          }))}
          value={format}
          onChange={onHandleChange}
        />
      )}
    </Row>
  );
};

export default DateFormatOptionSelect;
