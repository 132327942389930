import React from "react";

// Material UI
import MUISelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

// Types
import type { FormControlProps } from "@material-ui/core/FormControl";
import type { SelectProps } from "@material-ui/core/Select";

interface Option {
  value: string | number;
  title: string;
}

interface Props extends SelectProps {
  options: Option[];
  formStyle?: FormControlProps["className"];
  innerWrapperStyle?: string;
  inputLabel?: string;
}

const Select: React.FC<Props> = ({
  options,
  inputLabel,
  formStyle,
  innerWrapperStyle,
  ...props
}) => {
  const labelId = inputLabel ? `select-label-${Math.random()}` : undefined;

  return (
    <FormControl className={formStyle}>
      {inputLabel && <InputLabel id={labelId}>{inputLabel}</InputLabel>}
      <MUISelect
        labelId={labelId}
        classes={{ root: innerWrapperStyle }}
        MenuProps={{ disablePortal: true }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ minHeight: "calc(1.5rem + 12px)" }}
          >
            {option.title}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
