import makeStyles from "@material-ui/core/styles/makeStyles";
import { cardLookStyle } from "../../globalStyles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".firebaseui-form-actions .mdl-button--primary.firebaseui-button": {
      color: "#000",
    },
    ".firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button": {
      background: theme.palette.secondary.main,
    },
    "#firebaseui_container .mdl-card.firebaseui-id-page-sign-in": {
      boxShadow: "none",
    },
    ".firebaseui-container.firebaseui-id-page-password-sign-up": {
      boxShadow: "none",
    },
  },
  loginWrap: {
    height: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FFF",
  },
  upperSection: {
    background: "linear-gradient(353deg, rgba(113,172,255,1) 12%, rgba(107,153,218,1) 100%)",
    backgroundColor: "#71ACFF",
    paddingTop: "30vh",
  },
  logo: {
    maxWidth: 250,
    display: "block",
    margin: theme.spacing(2, "auto", 4, "auto"),
  },
  line: {
    width: "60%",
    border: "none",
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(6),
  },
  title: {
    textAlign: "center",
  },
  firebaseWrap: {
    ...cardLookStyle,
    padding: theme.spacing(2, 0),
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FFF",
    marginTop: `max(-20vh, ${theme.spacing(-18)}px)`,
  },
  lowerSpacing: {
    marginTop: theme.spacing(-4),
    padding: theme.spacing(2),
  },
  paragraph: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    textAlign: "center",
    fontSize: 10,
    color: theme.palette.text.disabled,
  },
}));

export default useStyles;
