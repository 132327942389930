import React from "react";
import { useSnackbar } from "notistack";

// Material UI
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

// Types
import type { ProviderContext, SharedProps, SnackbarAction } from "notistack";

export interface EnqueueSnackbar {
  (
    message: React.ReactNode,
    variant?: SharedProps["variant"],
    persist?: boolean,
  ): React.ReactText;
}

interface SnackbarHook extends Pick<ProviderContext, "closeSnackbar"> {
  enqueueSnackbar: EnqueueSnackbar;
}

const useCustomSnackbar = (): SnackbarHook => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const action: SnackbarAction = (key) => (
    <IconButton onClick={() => closeSnackbar(key)}>
      <CloseIcon />
    </IconButton>
  );

  const editedEnqueue: EnqueueSnackbar = (message, variant) =>
    enqueueSnackbar(message, {
      action,
      variant,
      persist: variant === "error",
    });

  return { closeSnackbar, enqueueSnackbar: editedEnqueue };
};

export default useCustomSnackbar;
