import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";

// Material UI
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";

// Local
import Item from "./item";
import Loading from "../../components/loading";
import routes from "../../routes";
import { useData, useLoading } from "../../firebase/hooks";
import { useUserId } from "../../authentication/hooks";
import useStyles from "./styles";
import { firestoreQuery, localStorageKey } from "../../constants";

// Types
import type { User } from "../../users/types";

const Overview: React.FC = () => {
  const classes = useStyles();
  const { replace } = useHistory();
  const userId = useUserId();
  useFirestoreConnect([
    { collection: "users", doc: userId, storeAs: firestoreQuery.currentUser },
  ]);
  const user = useData<User>(firestoreQuery.currentUser);
  const loading = useLoading(firestoreQuery.currentUser);
  const userWorkspaces = user?.workspaces;
  const userWorkspacesKeys = React.useMemo<string[]>(
    () => (userWorkspaces ? Object.keys(userWorkspaces) : []),
    [userWorkspaces],
  );

  React.useEffect(() => {
    const lastUsedWorkspace = localStorage.getItem(localStorageKey.lastUsedWorkspace(userId));
    if (lastUsedWorkspace) {
      replace(routes.workspaceDetail(lastUsedWorkspace));
    }
  }, [replace, userWorkspacesKeys, userId]);

  if (loading) return <Loading fullScreen />;

  const listItems = userWorkspacesKeys.map((wsId: string, index: number) => (
    <React.Fragment key={wsId}>
      <Item key={wsId} wsId={wsId} name={userWorkspaces[wsId].name} />
      {index !== Object.keys(userWorkspaces).length - 1 && (
        <Divider className={classes.divider} />
      )}
    </React.Fragment>
  ));

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h5" align="center">
          Select Workspace
        </Typography>
        <CardContent className={classes.cardContent}>
          {userWorkspacesKeys.length ? (
            <List className={classes.list}>{listItems}</List>
          ) : undefined}
        </CardContent>
        <span className={classes.or}>OR</span>
        <CardActions>
          <Button
            disabled={loading}
            data-cy="btn-workspaces-create-new"
            className={classes.createButton}
            variant="contained"
            color="secondary"
            component={Link}
            to={routes.workspaceCreate}
          >
            Create New Workspace
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default Overview;
