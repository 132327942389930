import { makeActionCreator } from "redux-toolbelt";
import { useDispatch } from "react-redux";

const navActionCreator = makeActionCreator.withDefaults({ prefix: "@navigation/" });

export const selectWorkspace = navActionCreator("SELECT_WORKSPACE");

export const useSelectWorkspace = (): ((workspaceId: string) => void) => {
  const dispatch = useDispatch();
  return (workspaceId: string) => {
    dispatch(selectWorkspace(workspaceId));
  };
};
