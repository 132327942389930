import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 500,
    marginTop: theme.spacing(15),
    marginRight: "auto",
    marginLeft: "auto",
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
