import React from "react";

import { MTableToolbar } from "material-table";
import IconButton from "@material-ui/core/IconButton";
import { RotateLeft, TextFields } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

import Filter from "./filter";
import Tags from "./filter/tags";
import { IFilters } from "./filter/types";
import ShowSwitch from "../toggle/showArchivedSwitch";
import useStyles from "./styles";
import { MyColumn } from "./types";

type ISwitchProps = {
  toggleName: string;
  toggleTitle: string;
  helperTitle: string;
  handleOnChange: (show: boolean, name: string) => void;
  value: boolean;
};

export interface ToolbarProps {
  searchComponent: React.ReactNode;
  addDataComponent: React.ReactNode;
  filtering: boolean;
  filters: IFilters;
  columns: MyColumn[];
  setFilters: (filters: IFilters) => void;
  switchProps?: ISwitchProps;
  enableSwitch?: boolean;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  handleResetColumns: () => void;
  isRegex: boolean;
  simpleToolbar?: boolean;
}

const Toolbar: React.FC<ToolbarProps> = ({
  searchComponent,
  addDataComponent,
  filters,
  columns,
  setFilters,
  filtering,
  searchValue,
  setSearchValue,
  switchProps,
  enableSwitch,
  handleResetColumns,
  isRegex,
  simpleToolbar,
  ...other
}) => {
  const classes = useStyles({ simpleToolbar });

  return (
    <div className={classes.toolBarWrap}>
      <div className={classes.toolbarLeftSideWrap}>
        {addDataComponent}
        {filtering && (
          <>
            <Filter columns={columns} onApplyClick={setFilters} filters={filters} />
            <Tags filters={filters} onDeleteClick={setFilters} />
          </>
        )}
      </div>
      <div className={classes.toolbarRightSideWrap}>
        {enableSwitch && switchProps && <ShowSwitch {...switchProps} />}
        <div className={classes.trailing}>
          <div className={classes.regexIndicator}>
            <Tooltip
              title={
                <p>
                  <span>Using {isRegex ? "regex" : "normal"} search</span>
                  {isRegex ? null : (
                    <>
                      <br />
                      <span>To use regex, Your input must start and end with a slash ( / )</span>
                      <br />
                      <span>E.g. /^[a-zA-Z0-9\.]+@gmail\.com$/</span>
                    </>
                  )}
                </p>
              }
            >
              {isRegex ? <b>.*</b> : <TextFields />}
            </Tooltip>
          </div>
        </div>
        <MTableToolbar
          {...other}
          columns={columns}
          classes={{
            actions: classes.toolbarActions,
            spacer: classes.toolbarSpacer,
            root: classes.toolbarRoot,
            searchField: classes.searchField,
          }}
          showTitle={false}
        />
        <Tooltip title="Reset columns">
          <IconButton
            data-cy={"reset-columns-btn"}
            className={classes.resetButton}
            onClick={handleResetColumns}
          >
            <RotateLeft />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default Toolbar;
