import React from "react";

import { useLocation } from "react-router";

const titles = {
  "^/login$": "Login - listiq",
  "^/ws$": "Workspaces - listiq",
  "^/ws/create$": "Create new workspace - listiq",
  "^/ws/[^/]+/users/add$": "Add user - listiq",
};

const DocumentTitleSwitch: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const title = Object.entries(titles).find(([path]) => RegExp(path).test(pathname))?.[1];
    if (title) {
      document.title = title;
    }
  }, [pathname]);

  return null;
};

export default DocumentTitleSwitch;
