import React from "react";
import { Route, Switch } from "react-router-dom";

// Locals
import AddItem from "../create/addItem";
import CreateSubItem from "../createSubItem";
import SingleItem from "../singleItemView/singleItem";
import routes from "../../routes";
import ItemTable from "./itemTable";

const Items: React.FC = () => {
  return (
    <>
      <Route>
        {({ match, history }) => (
          <ItemTable workspaceId={match?.params.workspaceId} push={history.push} />
        )}
      </Route>
      <Switch>
        <Route path={routes.addSubItemToWorkspaceItem()}>
          <CreateSubItem />
        </Route>
        <Route path={routes.workspaceItemsDetail()}>
          <div style={{ width: 300 }}>
            <SingleItem type="item" />
          </div>
        </Route>
      </Switch>
      <AddItem />
    </>
  );
};

export default Items;
