import React from "react";

import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import InventoryCheckCreate from "./inventoryCheckCreate";
import InventoryCheckList from "./inventoryCheckList";

const InventoryCheck: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.workspaceInventoryCheck()} exact>
        <InventoryCheckList />
      </Route>
      <Route path={routes.addInventoryCheck()}>
        <InventoryCheckCreate />
      </Route>
    </Switch>
  );
};

export default InventoryCheck;
