import { put, spawn, takeLatest } from "redux-saga/effects";

// Local
import { authenticationSaga } from "../authentication";
import { firebase } from "../firebase";
import { loadEmails } from "../emails";

// Types
import type { SagaIterator } from "redux-saga";
import { loadEmailsError, loadEmailsSuccess } from "../emails/actions";

function* emailsSaga({ payload }: ReturnType<typeof loadEmails>) {
  try {
    const { data } = yield firebase.functions().httpsCallable("listWsEmails")({
      workspaceId: payload,
    });
    yield put({ type: loadEmailsSuccess.TYPE, payload: data });
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put({ type: loadEmailsError.TYPE, payload: e.message });
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(authenticationSaga);
  yield takeLatest(loadEmails.TYPE, emailsSaga);
}
