import React from "react";
import * as uuid from "uuid";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Locals
import AddUserActions from "./addUserActions";
import AddUserBody from "./addUserBody";
import routes, { Params } from "../../routes";
import Dialog from "../../components/dialog";
import { isValidEmail, beforeSubmitUserValidation } from "../../helpers/validations";
import { useAddMultipleUsers } from "../api";
import { firestoreQuery } from "../../constants";
import Loading from "../../components/loading";
import { useCurrentWsDetail, useLoading } from "../../firebase";

// Types
import type { NewUserFormState, UsersToBeAddedState } from "../types";
import type { Role } from "../userRoles";

const defaultNewUser: NewUserFormState = {
  email: "",
  notify: true,
  emailError: false,
  role: "user" as Role,
  roleError: false,
};

const defaultState = () => ({
  [uuid.v4() as string]: defaultNewUser,
});

const AddUser: React.FC = () => {
  const addMultipleUsersToWs = useAddMultipleUsers();
  const { push } = useHistory();
  const { workspaceId } = useParams<Params>();
  const { pathname } = useLocation();
  const [usersToBeAdded, setUsersToBeAdded] = React.useState<UsersToBeAddedState>(defaultState);
  const [submitting, setSubmitting] = React.useState(false);
  const { name } = useCurrentWsDetail();

  const handleUsersToBeAddedChange = React.useCallback(
    (newUser: NewUserFormState, key: string) => {
      const newState = { ...usersToBeAdded };
      const newElement = { ...newUser };

      if (newElement.emailError && isValidEmail(newElement.email)) {
        newElement.emailError = false;
      }

      newState[key] = newElement;
      setUsersToBeAdded(newState);
    },
    [usersToBeAdded],
  );

  const addMoreInputs = React.useCallback(() => {
    setUsersToBeAdded({ ...usersToBeAdded, [uuid.v4() as string]: defaultNewUser });
  }, [usersToBeAdded]);

  const onRemoveInput = React.useCallback(
    (key: string) => {
      if (Object.keys(usersToBeAdded).length > 1) {
        const { [key]: _, ...newUsersToBeAdded } = usersToBeAdded;
        setUsersToBeAdded(newUsersToBeAdded);
      }
    },
    [usersToBeAdded],
  );

  const handleCancelClick = React.useCallback(() => {
    setUsersToBeAdded(defaultState);
    push(routes.workspaceUsers(workspaceId));
  }, [push, workspaceId]);

  const handleSubmit = React.useCallback(async () => {
    const copyOfNewUsersToBeAdded = { ...usersToBeAdded };
    const { isValid, newUsersToBeAdded } = beforeSubmitUserValidation(copyOfNewUsersToBeAdded);

    // If error then update state with new error information
    if (!isValid) {
      setUsersToBeAdded(newUsersToBeAdded);
    } else {
      // Send the request for API
      setSubmitting(true);
      await addMultipleUsersToWs(workspaceId || "", name, copyOfNewUsersToBeAdded);

      setUsersToBeAdded(defaultState());
      setSubmitting(false);
      push(routes.workspaceUsers(workspaceId));
    }
  }, [push, name, workspaceId, addMultipleUsersToWs, usersToBeAdded]);

  const actions = (
    <AddUserActions onCancel={handleCancelClick} onAdd={handleSubmit} loading={submitting} />
  );

  // Return loading in case we have not yet loaded current WS data
  const loading = useLoading(firestoreQuery.currentWsDetail);
  if (loading) return <Loading />;

  return (
    <Dialog
      data-cy={"add-users-to-workspace-dialog"}
      open={pathname === routes.addUserToWorkspace(workspaceId)}
      onClose={handleCancelClick}
      maxWidth="md"
      title="Add users to workspace"
      actions={actions}
    >
      <AddUserBody
        usersToBeAdded={usersToBeAdded}
        handleUsersToBeAddedChange={handleUsersToBeAddedChange}
        onKeyEnter={handleSubmit}
        onAddMore={addMoreInputs}
        onRemove={onRemoveInput}
      />
    </Dialog>
  );
};

export default AddUser;
