import { useSelector } from "react-redux";

import { firestoreQuery } from "../constants";
import { defaultDateFormatOption } from "../workspace/detail/dateFormatOptionSelect";

// Types
import type { AppState } from "../store";
import type { WorkspaceUser } from "../users/types";
import type { WsDetail } from "../workspace/types";

/**
 * Is query loading right now?
 */
export const useLoading = (query: string | string[]): boolean =>
  useSelector<AppState, boolean>((state) => {
    if (query instanceof Array) {
      return query.some(
        (entry) =>
          !state.firestore.status.requested[entry] && state.firestore.status.requesting[entry],
      );
    }
    return !state.firestore.status.requested[query] && state.firestore.status.requesting[query];
  });

/**
 * Return ordered set
 */
export const useOrdered = <Result = Record<string, unknown>>(query: string): Result[] =>
  useSelector<AppState, Result[]>((state) => state.firestore.ordered[query]);

export const useData = <Result = Record<string, unknown>>(
  query: string,
  subSelector?: string | string[],
): Result =>
  useSelector<AppState, Result>((state) => {
    if (subSelector instanceof Array) {
      // Used for getting multiple subItems including their ids for example
      return subSelector.map((id) => id && { ...state.firestore.data[query]?.[id], id });
    }
    return subSelector ? state.firestore.data[query]?.[subSelector] : state.firestore.data[query];
  });

export const useAuthLoaded = (): boolean =>
  useSelector<AppState, boolean>((state) => state.firebase.auth.isLoaded);

export const useAuthEmpty = (): boolean =>
  useSelector<AppState, boolean>((state) => state.firebase.auth.isEmpty);

export const useCurrentWsDetail = (): WsDetail =>
  useData<WsDetail>(firestoreQuery.currentWsDetail) ?? {};

export const useCurrentWsDateFormat = (): string =>
  useData<string | null>(firestoreQuery.currentWsDetail, "dateFormat") ?? defaultDateFormatOption;

export const useCurrentWsTier = (): string =>
  useData<string>(firestoreQuery.currentWsDetail, "tier") ?? "free";

export const useUser = (userId: string): WorkspaceUser | Record<string, unknown> =>
  useData<WorkspaceUser | undefined>(firestoreQuery.wsUsers, userId) ?? {};
