// Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/performance";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// Local
import config from "../config";

const useEmulators = process.env.REACT_APP_EMULATOR && process.env.REACT_APP_EMULATOR !== "false";

/**
 * Initialize Firebase
 */
const init = (): void => {
  firebase.initializeApp(config.firebase());
  firebase.analytics();
  firebase.performance();
  const db = firebase.firestore();
  const fn = functions();

  if (useEmulators) {
    db.settings({ host: "localhost:8080", ssl: false });
    fn.useFunctionsEmulator("http://localhost:5001");
  }
};

/**
 * Instance of the FB functions
 */
export const functions = (): firebase.functions.Functions =>
  firebase.app().functions(useEmulators ? undefined : config.firebaseFunctions.location);

/**
 * Log analytics event
 *
 * @param name name of the event
 * @param params parameters of the event
 */
const logEvent = (name: string, params?: Record<string, string | boolean>): void => {
  firebase.analytics().logEvent(name, params);
};

/**
 * Setup user ID for analytics
 *
 * @param id User ID to add to analytics
 */
const setUserId = (id: string): void => firebase.analytics().setUserId(id);

/**
 * Reset user ID
 */
const resetUserId = (): void => setUserId("");

const ourFirebase = {
  init,
  functions,
  logEvent,
  setUserId,
  resetUserId,
};

export default ourFirebase;
