import React from "react";
import { Redirect } from "react-router-dom";

// Firebase
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";

// Local
import config from "./config";
import { useIsLogged } from "../hooks";
import useStyles from "./styles";

const Login: React.FC = () => {
  const classes = useStyles();
  // Redux
  const loggedIn = useIsLogged();

  if (loggedIn) return <Redirect to="/" />;
  return (
    <div className={classes.loginWrap}>
      <div className={classes.upperSection} />
      <div className={classes.lowerSpacing}>
        <div className={classes.firebaseWrap}>
          <img src="/logo.svg" alt="Listiq" className={classes.logo} />
          <hr className={classes.line} />
          <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebase.auth()} />
          <p className={classes.paragraph}>
            To register, simply proceed with Sign In button of your choice.
          </p>
          <p className={classes.paragraph}>
            By proceeding, you agree to our{" "}
            <a href="./legal/terms-and-conditions.html">Terms of Use</a> and confirm that you have
            read our <a href="./legal/privacy-policy.html">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
