import React from "react";

import firebase from "firebase/compat/app";
import { useParams } from "react-router";
import { AutoSizer, Column, Table } from "react-virtualized";

import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

import { useCurrentWsDetail, useOrdered } from "../firebase";
import useStyles from "./styles";

import { firestoreQuery } from "../constants";
import { useEditItem } from "../items/api";
import { useUserId } from "../authentication/hooks";

import type { SingleItemState } from "../items/types";
import type { WsParams } from "../routes";

type InventoryCheckItem = Partial<SingleItemState> & { verified: boolean };

const CurrentInventoryCheck: React.FC = () => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();
  const { activeInventoryCheckId } = useCurrentWsDetail();
  const items = useOrdered<SingleItemState>(firestoreQuery.wsItems);
  const editItem = useEditItem(true);
  const userId = useUserId();

  const switchItem = React.useCallback(
    (itemId: string, verified: boolean) => {
      editItem(workspaceId, itemId, {
        [`inventoryCheck.${activeInventoryCheckId}`]: {
          verified,
          verifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
          verifiedBy: userId,
        },
      });
    },
    [activeInventoryCheckId, userId, workspaceId, editItem],
  );

  const filteredItems = React.useMemo<InventoryCheckItem[] | false>(
    () =>
      activeInventoryCheckId !== undefined &&
      items?.reduce<InventoryCheckItem[]>((prev, curr) => {
        if (curr.inventoryCheck?.[activeInventoryCheckId]) {
          prev.push({
            id: curr.id,
            name: curr.name,
            verified: curr.inventoryCheck?.[activeInventoryCheckId].verified,
          });
        }
        return prev;
      }, []),
    [items, activeInventoryCheckId],
  );

  return (
    <div
      className={classes.wrapper}
      style={{ alignSelf: activeInventoryCheckId ? "stretch" : "undefined" }}
    >
      <Typography variant="subtitle1" className={classes.cardTitle}>
        {activeInventoryCheckId && filteredItems
          ? `Items verified ${filteredItems.filter((item) => item.verified).length}/${
              filteredItems.length
            }`
          : "No Stock-Taking in progress"}
      </Typography>
      {activeInventoryCheckId && filteredItems && (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              height={height - 32}
              width={width}
              rowHeight={48}
              headerHeight={0}
              rowCount={filteredItems.length}
              onRowClick={({ rowData: { id, verified } }) => switchItem(id, !verified)}
              rowGetter={({ index }) => filteredItems[index]}
              className={classes.table}
            >
              <Column
                dataKey="name"
                width={width}
                cellRenderer={({ cellData, rowData: { verified } }) => (
                  <TableCell className={classes.flexContainer}>
                    <Checkbox checked={verified} />
                    {cellData}
                  </TableCell>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default CurrentInventoryCheck;
