import React from "react";
import { Link } from "react-router-dom";

// Material UI
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Local
import routes from "../../routes";

interface Props {
  wsId: string;
  name: string;
}
const Item: React.FC<Props> = ({ wsId, name }) => {
  return (
    <ListItem button component={Link} to={routes.workspaceDetail(wsId)}>
      <ListItemText primary={name} style={{ marginLeft: "15px" }} />
    </ListItem>
  );
};

export default Item;
