import firebase from "firebase/compat/app";
import { getFirestore, collection, getCountFromServer } from "@firebase/firestore";
import { default as configuredFirebase } from "../../firebase/firebase";
import { useFirestore } from "react-redux-firebase";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

// Locals
import { useUserId } from "../../authentication/hooks";
import useCustomSnackbar from "../../components/snackbar";
import {
  firebaseTimestampOrNull,
  nonEmptyStringOrNull,
  enforceObject,
} from "../../helpers/format";
import { useGetWsTierDetail } from "../../workspace/api";

// Types
import type { SingleItemState, StorageItem } from "../types";

interface EditItem {
  (
    wsId: string,
    itemId: string,
    updatedItem: Partial<SingleItemState> & { [key: string]: unknown },
    deactivatedUser?: boolean,
  ): Promise<void>;
}

interface UseEditItem {
  (simple?: boolean): EditItem;
}

export const useEditItem: UseEditItem = (simple?: boolean) => {
  const firestore = useFirestore();
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (
    wsId: string,
    itemId: string,
    updatedItem: Partial<SingleItemState> & { [key: string]: unknown },
    deactivatedUser?: boolean,
  ) => {
    const cleanUpdatedItem: Partial<SingleItemState> = simple
      ? {
          ...updatedItem,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: loggedInUserId,
        }
      : {
          ...updatedItem,
          children: enforceObject(updatedItem.children),
          parent: nonEmptyStringOrNull(updatedItem.parent),
          user: nonEmptyStringOrNull(updatedItem.user),
          bought: firebaseTimestampOrNull(
            updatedItem.bought as firebase.firestore.Timestamp | DateTime | null,
          ),
          warrantyUntil: firebaseTimestampOrNull(
            updatedItem.warrantyUntil as firebase.firestore.Timestamp | DateTime | null,
          ),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: loggedInUserId,
        };

    const ws = firestore.collection("workspaces").doc(wsId);
    const oldItem = ws.collection("items").doc(itemId);

    try {
      await oldItem.update(cleanUpdatedItem);
      enqueueSnackbar("Item info was updated.", "success");
    } catch (err: unknown) {
      if (deactivatedUser)
        enqueueSnackbar("The user of this item is deactivated, please change him.", "error");
      else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enqueueSnackbar(`Failed to update item info. ${err?.message}`, "error");
      }
      console.error(err);
    }
  };
};

interface AddItem {
  (
    wsId: string,
    newItem: SingleItemState,
    files?: File[],
  ): Promise<firebase.firestore.DocumentReference | null>;
}

interface UseAddItem {
  (): AddItem;
}

export const useAddItem: UseAddItem = () => {
  const getWsTierDetail = useGetWsTierDetail();
  const firestore = useFirestore();
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();
  const attachFiles = useAttachFilesToItem();

  return async (wsId: string, item: SingleItemState, files?: File[]) => {
    const { itemLimit } = await getWsTierDetail(wsId);
    if (itemLimit > -1) {
      const db = getFirestore();
      const inventoryRef = collection(db, `workspaces/${wsId}/items`);
      const snapshot = await getCountFromServer(inventoryRef);
      const itemCount = snapshot.data().count;

      if (itemCount >= itemLimit) {
        enqueueSnackbar(`You exceeded the workspace tier limit of ${itemLimit} items.`);
        return null;
      }
    }

    const newItem: SingleItemState = {
      ...item,
      children: enforceObject(item.children),
      parent: nonEmptyStringOrNull(item.parent),
      user: nonEmptyStringOrNull(item.user),
      history: [],
      bought: firebaseTimestampOrNull(
        item.bought as firebase.firestore.Timestamp | DateTime | null,
      ),
      warrantyUntil: firebaseTimestampOrNull(
        item.warrantyUntil as firebase.firestore.Timestamp | DateTime | null,
      ),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: loggedInUserId,
    };

    // selected workspace ref
    const ws = firestore.collection("workspaces").doc(wsId);
    // saving new item to workspace.items collection
    const wsItems = ws.collection("items");
    try {
      const response = await wsItems.add(newItem);
      if (files && files.length) {
        attachFiles(files, wsId, response?.id);
      }
      enqueueSnackbar("Item was saved.", "success");
      return response;
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to save new item. ${err?.message}`, "error");
      console.error(err);
      return null;
    }
  };
};

interface RemoveItem {
  (wsId: string, itemId: string): Promise<void>;
}

interface UseRemoveItem {
  (): RemoveItem;
}

export const useRemoveItem: UseRemoveItem = () => {
  const firestore = useFirestore();
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (wsId: string, itemId: string) => {
    if (!loggedInUserId) {
      enqueueSnackbar(`Failed to find your id.`, "error");
      console.error(`Failed to find your id.`);
      return;
    }

    try {
      await firestore.doc(`workspaces/${wsId}/items/${itemId}`).delete();
      enqueueSnackbar("Item was deleted.", "success");
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to delete item. ${err?.message}`, "error");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.error(err.message);
    }
  };
};

interface AttachFiles {
  (files: File[], wsId: string, itemId: string): Promise<void>;
}

interface UseAttachFiles {
  (): AttachFiles;
}

export const useAttachFilesToItem: UseAttachFiles = () => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const actorId = firebase.auth().currentUser?.uid ?? "";

  return async (files: File[], wsId: string, itemId: string) => {
    const wsItemStorageRef = firebase.storage().ref(`workspaces/${wsId}/items/${itemId}/files`);

    await Promise.all(
      files.map(async (file) => {
        try {
          const uniqueName = uuidv4();
          const exactRef = wsItemStorageRef.child(`/${uniqueName}`);
          await exactRef.put(file, {
            customMetadata: {
              name: file.name,
              createdAt: DateTime.utc().toString(),
              createdBy: actorId,
            },
          });
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          enqueueSnackbar(`Failed to attach file ${file.name}. ${err?.message}`, "error");
          console.error(err);
        }
      }),
    );
  };
};

interface GetFiles {
  (path: string): Promise<StorageItem[] | undefined>;
}

interface UseGetFilesFromPath {
  (): GetFiles;
}

export const useGetFilesFromPath: UseGetFilesFromPath = () => {
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (path: string) => {
    try {
      const storageRef = firebase.storage().ref(path);
      const response = await storageRef.listAll();

      return await Promise.all(
        response.items.map(async (itemRef) => {
          const meta = await itemRef.getMetadata();

          return {
            // For displaying correct name
            name: meta.customMetadata?.name || meta.name || "Unknown name",
            // For generating links
            dbName: itemRef.name,
          };
        }),
      );
    } catch (err) {
      enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `Something went wrong with accessing attachments. ${err?.message}`,
        "error",
      );
      console.error(err);
      return;
    }
  };
};

interface GetFileUrl {
  (path: string): Promise<string>;
}

interface UseGetFileUrl {
  (): GetFileUrl;
}

export const useGetFileUrl: UseGetFileUrl = () => {
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (path: string) => {
    try {
      const response = await configuredFirebase.functions().httpsCallable("getSignedUrlOfFile")({
        path,
      });

      return response?.data?.signedUrl;
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to open file. ${err?.message}`, "error");
      console.error(err);
    }
  };
};

interface DeleteFile {
  (workspaceId: string, itemId: string, fileName: string): Promise<void>;
}

interface UseDeleteFile {
  (): DeleteFile;
}

export const useDeleteFile: UseDeleteFile = () => {
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (workspaceId: string, itemId: string, fileName: string) => {
    try {
      await configuredFirebase.functions().httpsCallable("archiveFile")({
        workspaceId,
        itemId,
        fileName,
      });
      enqueueSnackbar("File successfuly deleted.", "success");
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to delete file. ${err?.message}`, "error");
      console.error(err);
    }
  };
};
