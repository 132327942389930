import React from "react";

// Material UI
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { ConfirmButton } from "../buttons";

// Styles
import useStyles from "./styles";

// Types
import type { ButtonProps } from "@material-ui/core/Button";

interface Props extends Pick<ButtonProps, "size" | "disabled"> {
  changesMade?: boolean;
  onDiscardClick: () => void;
  onSaveClick: () => void;
  loading?: boolean;
}

const SaveOrDiscardPanel: React.FC<Props> = ({
  changesMade,
  onDiscardClick,
  onSaveClick,
  size = "small",
  loading,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        style={{ visibility: changesMade ? "visible" : "hidden" }}
        className={classes.marginBottom}
      >
        You have made changes
      </Typography>
      <div>
        <Button
          disabled={!changesMade || loading}
          onClick={onDiscardClick}
          size={size}
          className={classes.marginRight}
        >
          Discard
        </Button>
        <ConfirmButton
          disabled={disabled || !changesMade || loading}
          onClick={onSaveClick}
          size={size}
          loading={loading}
        >
          Save
        </ConfirmButton>
      </div>
    </div>
  );
};

export default SaveOrDiscardPanel;
