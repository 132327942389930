import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  placeholder: {
    fontSize: 14,
    margin: 1,
  },
});

export default useStyles;
