import React from "react";

import { MultiAutoCompleteSelect } from "../../../components/autocompleteSelect";
import useStyles from "./styles";
import { useOrdered } from "../../../firebase";
import { firestoreQuery } from "../../../constants";

// Types
import type { WorkspaceUser } from "../../../users/types";
import type { Option } from "../../autocompleteSelect/types";
import { FilterSpecification, FilterType } from "./types";

interface Props {
  field: string;
  label: string;
  onChange: (newValue: FilterSpecification, field: string) => void;
  value?: FilterSpecification | null;
  focus?: boolean;
}

const FilterMultiSelect: React.FC<Props> = ({ field, focus, label, onChange, value }) => {
  const classes = useStyles();
  const users = useOrdered<WorkspaceUser>(firestoreQuery.wsUsers);

  const userNameList = React.useMemo<Option[]>(
    () =>
      users
        .filter((user) => !user.deactivated && !value?.includedIn?.includes(user.email))
        .map((user) => ({
          title: user.name,
          value: user.email,
        })),
    [users, value],
  );

  const handleOnChange = React.useCallback(
    (newValue) => {
      onChange({ includedIn: newValue, filterType: FilterType.MultiSelect }, field);
    },
    [field, onChange],
  );

  return (
    <>
      <span className={classes.filterLabel}>{label}</span>
      <MultiAutoCompleteSelect
        limitTags={1}
        autoFocus={focus}
        name={field}
        options={field === "user" ? userNameList : undefined}
        value={value?.includedIn || []}
        setValue={handleOnChange}
      />
    </>
  );
};

export default FilterMultiSelect;
