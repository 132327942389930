import React from "react";

// Material UI
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Locals
import { ConfirmButton, HelpIcon } from "../../components/buttons";

// Styles
import useStyles from "../styles";

// Types
import type { ButtonProps } from "@material-ui/core/Button";

interface Props {
  onCancel: ButtonProps["onClick"];
  onAdd?: ButtonProps["onClick"];
  loading?: boolean;
}

const CreateSubItemActions: React.FC<Props> = ({ onCancel, onAdd, loading }) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.removeActionsMarginTop}>
      <HelpIcon title="After assigning, all sub-items will inherit status, location and user of that item" />
      <Button disabled={loading} onClick={onCancel}>
        Cancel
      </Button>
      <ConfirmButton loading={loading} onClick={onAdd}>
        Save
      </ConfirmButton>
    </DialogActions>
  );
};

export default CreateSubItemActions;
