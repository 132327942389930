import { useSelector } from "react-redux";

// Types
import type { AppState } from "../store";
import { firestoreQuery } from "../constants";

export const useIsLogged = (): boolean =>
  useSelector<AppState, boolean>(
    (state) => state.firebase.auth.isLoaded && !state.firebase.auth.isEmpty,
  );

export const useUserId = (): string =>
  useSelector<AppState, string>((state) =>
    state.firebase.auth.isLoaded && !state.firebase.auth.isEmpty ? state.firebase.auth.uid : "",
  );

export const useIsAdmin = (): { loading: boolean; isAdmin: boolean } => {
  // This is updated on WorkspaceSwitch render
  const currentWsUserRole = useSelector<AppState, string | boolean>(
    (state) =>
      state.firestore.status.requested[firestoreQuery.currentWsUser] &&
      !state.firestore.status.requesting[firestoreQuery.currentWsUser] &&
      state.firestore.data[firestoreQuery.currentWsUser]?.role,
  );

  return { loading: currentWsUserRole === false, isAdmin: currentWsUserRole === "admin" };
};
