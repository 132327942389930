import React from "react";
import { Link, useParams } from "react-router-dom";

import type { SingleItemState } from "../types";
import useStyles from "../styles";

import routes, { Params } from "../../routes";

interface Props {
  type: "item" | "userInfoItem";
  parent: { data?: SingleItemState; id?: string };
  subItems: SingleItemState[];
}

const SubItems: React.FC<Props> = ({ parent, subItems, type }) => {
  const classes = useStyles();
  const { workspaceId, userId } = useParams<Params>();

  if (parent.data) {
    const redirectLink =
      type === "userInfoItem"
        ? routes.workspaceUserInfoItemDetail(workspaceId, userId, parent.id)
        : routes.workspaceItemsDetail(workspaceId, parent.id);
    return (
      <>
        <div className={classes.relative}>
          <div className={classes.attachmentsLabelWrap}>
            <span className={classes.attachmentsLabel}>Parent</span>
          </div>
          <div className={classes.attachmentsWrap}>
            <Link to={redirectLink} className={classes.attachment}>
              {parent.data?.name}
            </Link>
          </div>
        </div>
      </>
    );
  }

  if (subItems.length) {
    return (
      <>
        <div className={classes.relative}>
          <div className={classes.attachmentsLabelWrap}>
            <span className={classes.attachmentsLabel}>Sub-items</span>
          </div>
          <div className={classes.attachmentsWrap}>
            {subItems.map((subItem) => {
              const redirectLink =
                type === "userInfoItem"
                  ? routes.workspaceUserInfoItemDetail(workspaceId, userId, subItem.id)
                  : routes.workspaceItemsDetail(workspaceId, subItem.id);
              return (
                <Link to={redirectLink} className={classes.attachment} key={subItem.id}>
                  {subItem.name}
                </Link>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default SubItems;
