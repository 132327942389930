import React from "react";

import Select from "../../../components/select";
import useStyles from "./styles";

// Types
import { FilterSpecification, FilterType } from "./types";

interface Props {
  field: string;
  label: string;
  onChange: (newValue: FilterSpecification, field: string) => void;
  value?: FilterSpecification | null;
}

const options = [
  { title: "", value: "" },
  { title: "true", value: "true" },
  { title: "false", value: "false" },
];

const FilterBool: React.FC<Props> = ({ field, label, onChange, value }) => {
  const classes = useStyles();

  const handleOnChange = React.useCallback(
    (e) => {
      onChange({ bool: e.target.value, filterType: FilterType.Bool }, field);
    },
    [field, onChange],
  );

  return (
    <>
      <span className={classes.filterLabel}>{label}</span>
      <Select
        value={value?.bool ?? ""}
        options={options}
        name={field}
        onChange={handleOnChange}
      />
    </>
  );
};

export default FilterBool;
