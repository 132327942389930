import React, { ChangeEvent } from "react";
import { RowData } from "../components/table/types";
import { WorkspaceUser } from "./types";
import Switch from "@material-ui/core/Switch";
import { MaterialTableProps } from "material-table";

export const generateTableActions = (
  openDialog: (data: RowData) => void,
  workspaceId?: string,
) => (secondary?: boolean): MaterialTableProps<RowData>["actions"] => [
  {
    icon: secondary ? "person_add" : "person_remove",
    tooltip: secondary ? "Activate this user." : "De-activate this user.",
    onClick: (_: unknown, row: RowData | RowData[]) => {
      if (!(row as RowData).id || !workspaceId) return null;

      // We save current deactivated status of user and his id to the state.
      openDialog(row as RowData);
    },
  },
];

export const prepareUsersData = (
  users: Record<string, WorkspaceUser>,
  prepareNameField: (userData: WorkspaceUser) => JSX.Element,
  handleOnNotificationsChange: (e: ChangeEvent) => void,
): { active: RowData[]; deactivated: RowData[] } => {
  const active: RowData[] = [];
  const deactivated: RowData[] = [];

  users &&
    Object.entries(users).forEach(([id, user]) => {
      if (!user) return;
      const userData = {
        id,
        name: prepareNameField(user),
        email: user?.email,
        createdAt: user?.createdAt,
        role: user?.role,
        deactivated: !!user?.deactivated,
        notifications: (
          <Switch
            name={id}
            checked={user?.notificationSettings.all}
            onChange={handleOnNotificationsChange}
          />
        ),
      };
      // Sorting in two categories so I can switch between those in table
      if (user.deactivated) {
        deactivated.push(userData);
      } else {
        active.push(userData);
      }
    });

  return { active, deactivated };
};
