import React from "react";
import { useParams, Link } from "react-router-dom";

// MaterialUI
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

// Locals
import DatePicker from "../../components/datePicker";
import useStyles from "../styles";
import { useLoading, useOrdered } from "../../firebase";
import {
  ConnectedAutoCompleteSelect,
  MultiAutoCompleteSelect,
} from "../../components/autocompleteSelect";
import { itemNameMaxLength } from "../commonVariables";
import { firestoreQuery } from "../../constants";
import routes from "../../routes";

// Types
import type { SingleItemState, SingleItemErrorState } from "../types";
import type { WorkspaceUser } from "../../users/types";
import type { WsParams } from "../../routes";
import { Dropzone } from "../../components/fileUpload";
import { itemsDetailAvatarSize } from "../../globalStyles";

const helperText = `Name must contain between 3 and ${itemNameMaxLength} characters.`;

type OnChangeType = (value: string | string[] | boolean, field: string) => void;

/**
 * @dropzoneKey Number used for removing files from dropzone after submitting from by increasing it on submit.
 * https://github.com/Yuvaleros/material-ui-dropzone/issues/9
 * @errorState
 * @handleOnChange
 * @handleOnAttachFiles
 * @handleOnFileDelete
 * @state
 */
interface Props {
  dropzoneKey: number;
  errorState: SingleItemErrorState;
  handleOnChange: OnChangeType;
  handleOnAttachFiles: (files: File[]) => void;
  handleOnFileDelete: (file: File) => void;
  state: SingleItemState;
}

const AddItemBody: React.FC<Props> = ({
  dropzoneKey,
  errorState,
  handleOnChange,
  handleOnAttachFiles,
  handleOnFileDelete,
  state,
}) => {
  const { workspaceId } = useParams<WsParams>();
  const classes = useStyles();
  const users = useOrdered<WorkspaceUser>(firestoreQuery.wsUsers);
  const loading = useLoading(firestoreQuery.wsUsers);

  const selectUserOptions = React.useMemo(
    () =>
      loading
        ? []
        : users
            .filter((user) => !user.deactivated)
            .map((user) => ({
              title: user.email,
              value: user.id,
            })),
    [users, loading],
  );

  const eventOnChangeHandler = React.useCallback(
    (event, name?: string) => {
      if (event?.target?.name) {
        const { name: field, value: newValue } = event.target;
        handleOnChange(newValue, field);
      } else if (name) {
        handleOnChange(event, name);
      }
    },
    [handleOnChange],
  );

  const avatarStyle = {
    display: "grid",
    placeItems: "center",
    height: itemsDetailAvatarSize,
    width: itemsDetailAvatarSize,
  };

  const getUser = users?.find((item) => item.id === state.user);

  return (
    <div className={classes.addItemBody}>
      <TextField
        data-cy="add-item-name"
        name="name"
        label="Name"
        variant="outlined"
        required
        error={errorState.name}
        helperText={errorState.name && helperText}
        value={state.name || ""}
        onChange={eventOnChangeHandler}
      />
      <TextField
        data-cy="add-item-internal-number"
        label="Internal Number"
        name="internalNumber"
        // If !value then empty string otherwise last entered value will be kept displayed.
        value={state.internalNumber || ""}
        onChange={eventOnChangeHandler}
      />
      <ConnectedAutoCompleteSelect
        data-cy="add-item-type"
        label="Type"
        name="type"
        variant="outlined"
        value={state.type}
        setValue={handleOnChange}
        creatable
      />
      <TextField
        data-cy="add-item-serial-number"
        label="Service Tag / Serial Number"
        name="serialNumber"
        value={state.serialNumber || ""}
        onChange={eventOnChangeHandler}
      />
      <div className={classes.subGridTwoCols}>
        <DatePicker
          data-cy="add-item-bought"
          label="Bought"
          value={state.bought}
          onChange={(date) => eventOnChangeHandler(date, "bought")}
        />
        <TextField
          data-cy="add-item-invoice-number"
          label="Invoice Number"
          name="invoiceNumber"
          value={state.invoiceNumber || ""}
          onChange={eventOnChangeHandler}
        />
      </div>
      <div className={classes.subGridTwoCols}>
        <ConnectedAutoCompleteSelect
          data-cy="add-item-location"
          label="Location"
          name="location"
          value={state.location}
          setValue={handleOnChange}
          creatable
        />
        <ConnectedAutoCompleteSelect
          data-cy="add-item-cost-center"
          label="Cost Center"
          name="costCenter"
          value={state.costCenter}
          setValue={handleOnChange}
          creatable
        />
      </div>
      <TextField
        data-cy="add-item-notes"
        name="notes"
        value={state.notes || ""}
        label="Notes"
        rows={5}
        multiline
        variant="outlined"
        onChange={eventOnChangeHandler}
      />
      <div className={classes.subGridThreeCols}>
        <MultiAutoCompleteSelect
          data-cy="add-item-tags"
          label="Tags"
          name="tags"
          value={state.tags}
          setValue={handleOnChange}
        />
        <div className={state.user ? classes.subGridIcon : ""}>
          {state.user ? (
            <Button
              data-cy="menu-users-inactive"
              size="small"
              component={Link}
              to={routes.workspaceUserInfo(workspaceId, `${state.user}`)}
            >
              <Avatar data-cy={"user-avatar"} style={avatarStyle} src={getUser?.photoURL} />
            </Button>
          ) : null}
          <ConnectedAutoCompleteSelect
            data-cy="add-item-user"
            label="User"
            name="user"
            value={state.user}
            setValue={handleOnChange}
            options={selectUserOptions}
          />
        </div>
        <div className={classes.subGridTwoCols}>
          <DatePicker
            data-cy="add-item-warranty-due"
            label="Warranty Due"
            value={state.warrantyUntil}
            onChange={(date) => eventOnChangeHandler(date, "warrantyUntil")}
          />
          <ConnectedAutoCompleteSelect
            creatable
            data-cy="add-item-status"
            label="Status"
            name="status"
            value={state.status}
            setValue={handleOnChange}
          />
        </div>
      </div>
      <Dropzone key={dropzoneKey} onChange={handleOnAttachFiles} onDelete={handleOnFileDelete} />
      <FormControlLabel
        className={classes.checkboxItem}
        classes={{ root: classes.checkboxClasses }}
        labelPlacement="start"
        control={
          <Checkbox
            data-cy="add-item-extended-warranty"
            name="extendedWarranty"
            onChange={(e) => handleOnChange(e.target.checked, "extendedWarranty")}
            checked={!!state.extendedWarranty}
          />
        }
        label="Extended Warranty"
      />
    </div>
  );
};

export default AddItemBody;
