import { Column } from "material-table";

import { smallColumnSize } from "../globalStyles";
import { RowData } from "../components/table/types";
import { firestoreTimestampToFormatted } from "../helpers/format";

/**
 * How to use so it works with filtering!
 *
 *
 * Use filterType "date" ony when field is containing (firebase.timestamp or null) which we then convert to seconds.
 *
 * Keep columns with filterType "date" together for ui reasons in filters!
 *
 * When no filterType given and table has enableFiltering = true, containStr filterType will be used.
 *
 */

const defaultColumns = (dateFormat?: string): Column<RowData>[] => [
  {
    title: "ID",
    field: "id",
    hidden: true,
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Added",
    field: "createdAt",
    render: (data: RowData) => firestoreTimestampToFormatted(data.createdAt, dateFormat),
    cellStyle: smallColumnSize,
    headerStyle: smallColumnSize,
  },
  {
    title: "Role",
    field: "role",
    cellStyle: smallColumnSize,
    headerStyle: smallColumnSize,
  },
  {
    title: "Notifications",
    field: "notifications",
    cellStyle: smallColumnSize,
    headerStyle: smallColumnSize,
  },
];

export default defaultColumns;
