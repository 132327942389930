import React from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase/compat/app";

// Locals
import RaisableCard from "../../components/cards";
import BasicInfo from "./basicInfo";
import FieldsOptions from "./fieldsOptions";
import ArchivedStatusManagement from "./archivedStatusManagement";
import Statistics from "./statistics";
import RecentlyViewedItems from "./recentlyViewedItems";
import WarrantyEndingItems from "./warrantyEndingItems";
import useStyles from "../styles";
import { firestoreQuery, localStorageKey } from "../../constants";
import routes from "../../routes";
import ChartStatistics from "./chartStatistics";
import { useCurrentWsDetail, useOrdered } from "../../firebase";
import { prepareStatistics } from "./helpers";
import { useUserId } from "../../authentication/hooks";

// Types
import type { SingleItemState } from "../../items/types";
import type { WsParams } from "../../routes";

const Detail: React.FC = () => {
  const classes = useStyles();
  const { replace } = useHistory();
  const { workspaceId } = useParams<WsParams>();
  const userId = useUserId();
  const items = useOrdered<SingleItemState>(firestoreQuery.wsItems);
  const { statisticsToShow } = useCurrentWsDetail();

  React.useEffect(() => {
    if (workspaceId && userId) {
      if (!localStorage.getItem(localStorageKey.lastUsedWorkspace(userId))) {
        localStorage.setItem(localStorageKey.lastUsedWorkspace(userId), workspaceId);
      }
      firebase
        .auth()
        .currentUser?.getIdTokenResult()
        .then((userTokenData) => {
          if (!userTokenData.claims.adminForWS?.[workspaceId]) {
            console.log(userTokenData.claims);
            const userId = firebase.auth().currentUser?.uid;

            replace(routes.workspaceUserInfo(workspaceId, userId));
          }
        });
    }
  }, [replace, workspaceId, userId]);

  const [countData, chartData] = React.useMemo(() => {
    if (!(items && statisticsToShow)) return [{}, {}];
    return prepareStatistics(items, statisticsToShow);
  }, [items, statisticsToShow]);

  return (
    <div className={classes.body}>
      <RaisableCard>
        <div className={classes.cardPadding}>
          <BasicInfo />
        </div>
      </RaisableCard>
      <RaisableCard>
        <div className={classes.cardPadding}>
          <ArchivedStatusManagement />
        </div>
      </RaisableCard>
      <RaisableCard>
        <div className={classes.cardPadding}>
          <Statistics data={countData} allCount={items?.length} />
        </div>
      </RaisableCard>
      <RaisableCard className={classes.middleColumn}>
        <div className={classes.cardPadding}>
          <RecentlyViewedItems />
        </div>
      </RaisableCard>
      <RaisableCard className={classes.rowEnd}>
        <div className={classes.cardPadding}>
          <WarrantyEndingItems />
        </div>
      </RaisableCard>
      <RaisableCard className={classes.doubleRowSpan}>
        <div className={classes.cardPadding}>
          <FieldsOptions />
        </div>
      </RaisableCard>
      <RaisableCard className={classes.fullWidthRow}>
        <div className={classes.cardPadding}>
          <ChartStatistics data={chartData} />
        </div>
      </RaisableCard>
    </div>
  );
};

export default Detail;
