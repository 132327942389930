import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Locals
import AddItemActions from "./addItemActions";
import Dialog from "../../components/dialog";
import AddItemBody from "./addItemBody";
import routes, { Params } from "../../routes";
import { useAddItem } from "../api";
import { defaultNewItem, defaultNewItemErrorState } from "./defaultState";

// Validations
import { beforeSubmitValidation, onChangeValidation } from "../validation";

const AddItem: React.FC = () => {
  const { push } = useHistory();
  const addItem = useAddItem();

  // URL
  const { workspaceId } = useParams<Params>();
  const { pathname } = useLocation();

  // Local state
  const [dropzoneKey, setDropzoneKey] = React.useState(0);
  const [state, setState] = React.useState(defaultNewItem());
  const [files, setFiles] = React.useState([]);
  const [errorState, setErrorState] = React.useState(defaultNewItemErrorState);
  const [submitting, setSubmitting] = React.useState(false);

  const handleFilesAttach = React.useCallback((files) => {
    setFiles(files);
  }, []);

  /**
   * Used for removing files from dropzone after submitting from
   * https://github.com/Yuvaleros/material-ui-dropzone/issues/9
   */
  const incrementDropzoneKey = React.useCallback(() => {
    setDropzoneKey(dropzoneKey + 1);
  }, [dropzoneKey]);

  const handleOnAttachmentDelete = React.useCallback(
    (deletedFile: File) => {
      const newFiles = files.filter((file) => file !== deletedFile);
      setFiles(newFiles);
    },
    [files],
  );

  const handleItemInfoChange = React.useCallback(
    (newValue, field) => {
      if (!field) return;
      const newItem = { ...state, [field]: newValue ?? null };

      // errors checking
      const { changed, newErrorState } = onChangeValidation(field, newValue, errorState);
      changed && setErrorState(newErrorState);
      // -- end of errors checking

      setState(newItem);
    },
    [state, errorState],
  );

  const handleCancelClick = React.useCallback(() => {
    setState(defaultNewItem());
    push(routes.workspaceItems(workspaceId));
  }, [push, workspaceId]);

  const handleOnClose = React.useCallback(() => {
    push(routes.workspaceItems(workspaceId));
  }, [push, workspaceId]);

  const handleOnAdd = React.useCallback(async (): Promise<boolean | undefined | null> => {
    if (!workspaceId) return;

    setSubmitting(true);

    // Validation
    const { isValid, newErrorState } = beforeSubmitValidation(state, errorState);
    if (!isValid) {
      setErrorState(newErrorState);
      setSubmitting(false);
      return null;
    }

    const result = await addItem(workspaceId, state, files);
    setSubmitting(false);
    if (result) {
      /**
       * Used for removing files from dropzone after submitting from
       * https://github.com/Yuvaleros/material-ui-dropzone/issues/9
       */
      incrementDropzoneKey();
      setState(defaultNewItem());
      return true;
    }
  }, [addItem, errorState, files, incrementDropzoneKey, state, workspaceId]);

  const actions = (
    <AddItemActions onCancel={handleCancelClick} onAdd={handleOnAdd} loading={submitting} />
  );

  const handleOnKeyPress = React.useCallback(
    (e: { key: string; ctrlKey: boolean }) => {
      if (e.ctrlKey && e.key === "Enter") {
        handleOnAdd();
      } else if (e.key === "Escape") {
        handleCancelClick();
      }
    },
    [handleOnAdd, handleCancelClick],
  );

  return (
    <Dialog
      open={pathname === routes.addItemToWorkspace(workspaceId)}
      maxWidth="md"
      onClose={handleOnClose}
      title="Add item to workspace"
      actions={actions}
      onKeyDown={handleOnKeyPress}
    >
      <AddItemBody
        errorState={errorState}
        handleOnAttachFiles={handleFilesAttach}
        handleOnFileDelete={handleOnAttachmentDelete}
        dropzoneKey={dropzoneKey}
        handleOnChange={handleItemInfoChange}
        state={state}
      />
    </Dialog>
  );
};

export default AddItem;
