import { SingleItemState } from "../../items/types";
import { RowData } from "../../components/table/types";

export const prepareDataForTable = (
  items: Record<string, SingleItemState>,
  userName: string,
  userId?: string,
): RowData[] =>
  items
    ? Object.entries(items)
        .filter(([_, value]) => !!value && value.user === userId)
        .map(([id, value]) => ({
          id,
          parent: value?.parent || "",
          name: value?.name,
          internalNumber: value?.internalNumber || "",
          type: value?.type || "",
          costCenter: value?.costCenter || "",
          status: value?.status || "",
          location: value?.location || "",
          warrantyUntil: value?.warrantyUntil,
          user: userName || "",
          bought: value?.bought,
          notes: value?.notes || "",
          serialNumber: value?.serialNumber || "",
        }))
    : [];
