export interface Params {
  workspaceId?: string;
  itemId?: string;
  userId?: string;
}

export interface WsParams extends Params {
  workspaceId: string;
}

export interface WsItemParams extends WsParams {
  itemId: string;
}

export interface WsUserParams extends WsParams {
  userId: string;
}

const login = () => "/login" as const;

const loading = () => "/loading" as const;

// Workspace
const workspacesList = () => "/ws" as const;
const workspaceCreate = (): string => `${workspacesList()}/create`;
const workspaceDetail = (workspaceId?: string): string =>
  `${workspacesList()}/${workspaceId ?? ":workspaceId"}`;

// Workspace User default landing page (logged in user and his items info)
const workspaceUserInfo = (workspaceId?: string, userId?: string): string => {
  const baseUrl = `${workspaceDetail(workspaceId)}/user`;
  if (!userId) return `${baseUrl}/:userId`;
  return `${baseUrl}/${userId}`;
};
const workspaceUserInfoItemDetail = (
  workspaceId?: string,
  userId?: string,
  itemId?: string,
): string => {
  const baseUrl = `${workspaceUserInfo(workspaceId, userId)}/item`;
  if (!itemId) return `${baseUrl}/:itemId`;
  return `${baseUrl}/${itemId}`;
};
const workspaceUserInfoItemDetailAttachFiles = (
  workspaceId?: string,
  userId?: string,
  itemId?: string,
): string => {
  const baseUrl = workspaceUserInfoItemDetail(workspaceId, userId, itemId);
  return `${baseUrl}/attachFiles`;
};

// Workspace Users
const workspaceUsers = (workspaceId?: string): string => `${workspaceDetail(workspaceId)}/users`;
const addUserToWorkspace = (workspaceId?: string): string => `${workspaceUsers(workspaceId)}/add`;
const workspaceUsersDetail = (workspaceId?: string, userId?: string | null): string => {
  const baseUrl = workspaceUsers(workspaceId);
  if (!userId) return `${baseUrl}/:userId`;
  return `${baseUrl}/${userId}`;
};

// Workspace Items
const workspaceItems = (workspaceId?: string): string => `${workspaceDetail(workspaceId)}/items`;
const addItemToWorkspace = (workspaceId?: string): string => `${workspaceItems(workspaceId)}/add`;
const workspaceItemsDetail = (workspaceId?: string, itemId?: string | null): string => {
  const baseUrl = workspaceItems(workspaceId);
  if (!itemId) return `${baseUrl}/:itemId`;
  return `${baseUrl}/${itemId}`;
};
const workspaceItemsDetailAttachFiles = (workspaceId?: string, itemId?: string): string => {
  const baseUrl = workspaceItemsDetail(workspaceId, itemId);
  return `${baseUrl}/attachFiles`;
};
const addSubItemToWorkspaceItem = (workspaceId?: string, itemId?: string): string => {
  const baseUrl = workspaceItemsDetail(workspaceId, itemId);
  return `${baseUrl}/add`;
};

// Workspace Emails
const workspaceEmails = (workspaceId?: string): string =>
  `${workspaceDetail(workspaceId)}/emails`;

// Workspace Inventory Check
const workspaceInventoryCheck = (workspaceId?: string): string =>
  `${workspaceDetail(workspaceId)}/check`;
const addInventoryCheck = (workspaceId?: string): string =>
  `${workspaceInventoryCheck(workspaceId)}/add`;
const inventoryCheckDetail = (workspaceId?: string, inventoryCheck?: string): string =>
  `${workspaceInventoryCheck(workspaceId)}/check/${inventoryCheck ?? ":inventoryCheckId"}`;

const routes = {
  loading,
  login,
  workspacesList,
  workspaceCreate,
  workspaceDetail,
  workspaceUsers,
  addUserToWorkspace,
  workspaceUsersDetail,
  workspaceItems,
  addItemToWorkspace,
  addSubItemToWorkspaceItem,
  workspaceItemsDetail,
  workspaceUserInfo,
  workspaceUserInfoItemDetail,
  workspaceUserInfoItemDetailAttachFiles,
  workspaceItemsDetailAttachFiles,
  workspaceEmails,
  workspaceInventoryCheck,
  addInventoryCheck,
  inventoryCheckDetail,
};

export default routes;
