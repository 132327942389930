import firebase from "firebase/compat/app";
import { getFirestore, collection, getCountFromServer, query, where } from "@firebase/firestore";

// Locals
import { useUserId } from "../authentication/hooks";
import useCustomSnackbar from "../components/snackbar";
import { useGetWsTierDetail } from "../workspace/api";

// Types
import { WorkspaceUser } from "../users/types";

interface UpdateUser {
  (wsId: string, userId: string, updatedUser: Partial<WorkspaceUser>): Promise<void>;
}

interface UseUpdateUser {
  (): UpdateUser;
}

export const useUpdateUser: UseUpdateUser = () => {
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();
  const getWsTierDetail = useGetWsTierDetail();

  return async (wsId: string, userId: string, updatedUser: Partial<WorkspaceUser>) => {
    const updatedUserData: Record<string, unknown> = { ...updatedUser };
    const ws = firebase.firestore().collection("workspaces").doc(wsId);
    const oldUser = ws.collection("users").doc(userId);

    if (!updatedUserData.deactivated && (await oldUser.get()).data()?.deactivated) {
      // Check tier limits when user is activated
      const { userLimit } = await getWsTierDetail(wsId);
      if (userLimit >= -1) {
        const db = getFirestore();
        const usersRef = collection(db, `workspaces/${wsId}/users`);
        const activeUsersQuery = query(usersRef, where("deactivated", "==", false));
        const snapshot = await getCountFromServer(activeUsersQuery);
        const userCount = snapshot.data().count;

        if (userCount >= userLimit) {
          enqueueSnackbar(
            `You exceeded the workspace tier limit of ${userLimit} users.`,
            "error",
          );
          return;
        }
      }
    }

    // info about last update
    updatedUserData.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    // info about who updated
    updatedUserData.updatedBy = loggedInUserId;
    try {
      await oldUser.update(updatedUserData);
      enqueueSnackbar("User info updated.", "success");
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to update users info. ${err?.message}`, "error");
      console.error(err);
    }
  };
};

interface UnasssignItemFromUser {
  (wsId: string, itemId: string): Promise<void>;
}

interface UseUnasssignItemFromUser {
  (): UnasssignItemFromUser;
}

export const useUnassignItemFromUser: UseUnasssignItemFromUser = () => {
  const loggedInUserId = useUserId();
  const { enqueueSnackbar } = useCustomSnackbar();

  return async (wsId: string, itemId: string) => {
    const ws = firebase.firestore().collection("workspaces").doc(wsId);
    const itemToBeUnassigned = ws.collection("items").doc(itemId);
    try {
      await itemToBeUnassigned.update({
        user: null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: loggedInUserId,
      });
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enqueueSnackbar(`Failed to unassign item. ${err?.message}`, "error");
      console.error(err);
    }
  };
};
