import React from "react";
import { useParams, Link } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";

// Locals
import routes, { Params } from "../../routes";
import useStyles from "../styles";

const TableActionAddItem: React.FC = () => {
  const { workspaceId } = useParams<Params>();
  const classes = useStyles();
  return (
    <Button
      data-cy="btn-add-item"
      className={classes.addButton}
      color="secondary"
      variant="contained"
      size="medium"
      component={Link}
      to={routes.addItemToWorkspace(workspaceId)}
    >
      Add item
    </Button>
  );
};

export default TableActionAddItem;
