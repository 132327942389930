import makeStyles from "@material-ui/core/styles/makeStyles";

import { userInfoAvatarSize, cardLookStyle } from "../globalStyles";

const useStyles = makeStyles((theme) => {
  const padding = {
    padding: theme.spacing(2, 4),
  };

  const marginRight = {
    marginRight: theme.spacing(4),
  };

  const bigMarginRight = {
    marginRight: theme.spacing(8),
  };

  const smallMarginRight = {
    marginRight: theme.spacing(1),
  };

  return {
    userSectionWrap: {
      ...cardLookStyle,
      ...padding,
      minHeight: `calc(${theme.spacing(28)}px + 2.2em)`,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    historyWrap: {
      ...cardLookStyle,
      ...padding,
      backgroundColor: "white",
    },
    userInfoSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
      ...padding,
    },
    userDataInfoRow: {
      display: "flex",
      alignItems: "center",
    },
    itemsSectionWrap: {
      ...cardLookStyle,
    },
    avatar: {
      display: "grid",
      placeItems: "center",
      width: userInfoAvatarSize,
      height: userInfoAvatarSize,
    },
    leftElement: {
      ...marginRight,
    },
    smallMarginRight: {
      ...smallMarginRight,
    },
    bigMarginRight: {
      ...bigMarginRight,
    },
    editableInput: {
      width: 180,
    },
    actionsPanelWrap: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      minWidth: 320,
    },
    actionsPanel: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 5,
      alignItems: "center",
    },
    resize: {
      fontSize: 14,
    },
  };
});

export default useStyles;
