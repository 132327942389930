import React from "react";
import { Link, useParams } from "react-router-dom";
import { useRouteMatch } from "react-router";

// Material UI
import Button from "@material-ui/core/Button";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AppsIcon from "@material-ui/icons/Apps";
import MailIcon from "@material-ui/icons/MailOutlined";
import CheckIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

// Local
import WorkspaceSelect from "./workspaceSelect";
import routes from "../routes";
import { useIsAdmin, useUserId } from "../authentication/hooks";
import useStyles from "./styles";
import { isProductionUrl } from "../helpers/isProductionUrl";

// Types
import type { WsParams } from "../routes";

const Expanded: React.FC = () => {
  const classes = useStyles();
  const { workspaceId } = useParams<WsParams>();
  const loggedInUserId = useUserId();
  const { isAdmin } = useIsAdmin();

  // Workspace user info url matches
  const loggedInUserInfoMatch = useRouteMatch({
    path: routes.workspaceUserInfo(workspaceId, loggedInUserId),
  });
  const loggedInUserItemDetailMatch = useRouteMatch({
    path: routes.workspaceUserInfoItemDetail(workspaceId, loggedInUserId),
  });

  // Workspace detail match
  const workspaceDetailMatch = useRouteMatch({
    path: routes.workspaceDetail(),
    exact: true,
  });

  // All workspace items url match
  const itemsMatch = useRouteMatch({
    path: routes.workspaceItems(),
  });
  const itemDetailMatch = useRouteMatch({
    path: routes.workspaceItemsDetail(),
  });

  // All workspace users match
  const usersMatch = useRouteMatch({
    path: routes.workspaceUsers(),
  });
  const userDetailMatch = useRouteMatch({
    path: routes.workspaceUserInfo(),
  });

  // Workspace emails match
  const emailsMatch = useRouteMatch({
    path: routes.workspaceEmails(),
  });

  // Workspace inventory check match
  const checkMatch = useRouteMatch({
    path: routes.workspaceInventoryCheck(),
  });

  const isInUsers = (usersMatch || userDetailMatch) && !loggedInUserInfoMatch;
  const isInItems = itemsMatch || itemDetailMatch;
  const isInMyItems = loggedInUserInfoMatch || loggedInUserItemDetailMatch;
  const isInWorkspace = workspaceDetailMatch;
  const isInEmails = emailsMatch;
  const isInCheck = checkMatch;

  return (
    <div className={classes.buttonsWrapper}>
      <WorkspaceSelect />
      {isAdmin && (
        <Button
          data-cy={`menu-home-${isInWorkspace ? "active" : "inactive"}`}
          color={isInWorkspace ? "secondary" : "default"}
          className={classes.navButton}
          component={Link}
          to={routes.workspaceDetail(workspaceId)}
          variant={isInWorkspace ? "contained" : "outlined"}
          startIcon={<AppsIcon />}
        >
          My Workspace
        </Button>
      )}
      {isAdmin && (
        <Button
          data-cy={`menu-users-${isInUsers ? "active" : "inactive"}`}
          color={isInUsers ? "secondary" : "default"}
          className={classes.navButton}
          component={Link}
          to={routes.workspaceUsers(workspaceId)}
          variant={isInUsers ? "contained" : "outlined"}
          startIcon={<PeopleIcon />}
        >
          Users
        </Button>
      )}
      {isAdmin && (
        <Button
          data-cy={`menu-items-${isInItems ? "active" : "inactive"}`}
          color={isInItems ? "secondary" : "default"}
          className={classes.navButton}
          component={Link}
          to={routes.workspaceItems(workspaceId)}
          variant={isInItems ? "contained" : "outlined"}
          startIcon={<ListAltIcon />}
        >
          Items
        </Button>
      )}
      <Button
        data-cy={`menu-myitems-${isInMyItems ? "active" : "inactive"}`}
        color={isInMyItems ? "secondary" : "default"}
        className={classes.navButton}
        component={Link}
        to={routes.workspaceUserInfo(workspaceId, loggedInUserId)}
        variant={isInMyItems ? "contained" : "outlined"}
        startIcon={<ListAltIcon />}
      >
        My Items
      </Button>
      {isAdmin && (
        <Button
          data-cy={`menu-emails-${isInEmails ? "active" : "inactive"}`}
          color={isInEmails ? "secondary" : "default"}
          className={classes.navButton}
          component={Link}
          to={routes.workspaceEmails(workspaceId)}
          variant={isInEmails ? "contained" : "outlined"}
          startIcon={<MailIcon />}
        >
          Emails
        </Button>
      )}
      {isAdmin && !isProductionUrl() && (
        <Button
          data-cy={`menu-stock-taking-${isInCheck ? "active" : "inactive"}`}
          color={isInCheck ? "secondary" : "default"}
          className={classes.navButton}
          component={Link}
          to={routes.workspaceInventoryCheck(workspaceId)}
          variant={isInCheck ? "contained" : "outlined"}
          startIcon={<CheckIcon />}
        >
          Stock-taking
        </Button>
      )}
    </div>
  );
};

export default Expanded;
