import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

// Local
import useStyles from "./styles";
import loadingLogo from "./logo.svg";

type Props = {
  size?: number | string;
  fullScreen?: boolean;
  logo?: boolean;
};

const Loading: React.FC<Props> = ({ size, fullScreen, logo }) => {
  const classes = useStyles();

  const indicator = logo ? (
    <img
      src={loadingLogo}
      style={{ height: size ? size : fullScreen ? "10vh" : undefined }}
      className={classes.progress}
      alt="loading"
    />
  ) : (
    <CircularProgress size={size} className={classes.progress} />
  );
  if (fullScreen)
    return (
      <div className={classes.containerWrapper}>
        <div className={classes.fullScreenContainer}>{indicator}</div>
      </div>
    );
  return indicator;
};

export default Loading;
