import { FeatureFlag } from "./featureFlags";
import utils from "./utils";

// Types
import type { Computable } from "flag";

const defaultFlags = (): Computable<Record<FeatureFlag, boolean>> => {
  const flags: Record<string, boolean> = {};
  Object.values(FeatureFlag).forEach((flag) => {
    flags[flag] = utils.getDefault(flag);
  });
  return flags as Record<FeatureFlag, boolean>;
};

export default defaultFlags;
