import makeStyles from "@material-ui/core/styles/makeStyles";

const appBarColor = "white";

const useStyles = makeStyles((theme) => ({
  profileButton: {
    borderRadius: 10,
  },
  logo: {
    height: 40,
  },
  profile: {
    textAlign: "center",
  },
  avatar: {
    display: "block",
    width: 28,
    height: 28,
  },
  select: {
    color: theme.palette.secondary.main,
    "&:before": {
      borderColor: appBarColor,
    },
    "&:after": {
      borderColor: theme.palette.secondary.main,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.secondary.main,
    },
  },
  selectInnerWrapper: {
    padding: 6,
    paddingRight: 12,
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 120,
  },
  navButton: {
    margin: theme.spacing(0.5),
  },
  appBar: {
    height: 50,
    backgroundColor: appBarColor,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarRight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 2,
    marginLeft: "1em",
  },
  wsSelectWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  wsSelectIcon: {
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
