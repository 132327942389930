import { auth } from "firebaseui";
import firebase from "firebase/compat/app";

import routes from "../../routes";

const config: auth.Config = {
  credentialHelper: auth.CredentialHelper.NONE,
  signInFlow: "popup",
  signInSuccessUrl: routes.workspacesList(),
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: true },
  ],
};

export default config;
