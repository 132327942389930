import React from "react";
import { useHistory } from "react-router";

// Material UI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import TextFiled from "@material-ui/core/TextField";

// Local
import useStyles from "./styles";
import { useCreateWorkspace } from "../api";
import routes from "../../routes";
import { isNameLengthOk, isNameValid } from "../../helpers/validations";
import { Loading } from "../../components";

const helperText = "Name must contain between 3 and 30 characters.";

const CreateWorkspace: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const createWorkspace = useCreateWorkspace();

  // Local form state
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
      if (isNameLengthOk(event.target.value)) setError(false);
    },
    [setName],
  );

  const handleCreate = React.useCallback(async () => {
    if (!isNameValid(name)) {
      setError(true);
    } else {
      setLoading(true);
      await createWorkspace({ name });
      push(routes.workspacesList());
    }
  }, [createWorkspace, name, push]);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="Create Workspace" />
        <CardContent>
          <TextFiled
            error={error}
            value={name}
            onChange={handleChange}
            helperText={error && helperText}
            onKeyPress={(e) => e.key === "Enter" && handleCreate()}
            label="Workspace Name"
          />
        </CardContent>
        <CardActions>
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={handleCreate}
            className={classes.createButton}
          >
            {loading ? <Loading size="15pt" /> : "Create"}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CreateWorkspace;
