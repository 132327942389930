import firebase from "firebase/compat/app";

/**
 * Used for unassigning sub items from its parents by setting all those items parent field to null and status to not in use.
 * @param transaction
 * @param workspaceItems
 * @param loggedInUserId Id of logged in user so we can assign him to uptadeBy field and track changes in DB.
 * @param childrenIds Ids of items whose parent will be set to null.
 */

export const unassignFromParent = async (
  transaction: firebase.firestore.Transaction,
  workspaceItems: firebase.firestore.CollectionReference,
  loggedInUserId?: string,
  childrenIds: string[] = [],
): Promise<void> => {
  for (const childrenId of childrenIds) {
    const exSubItem = workspaceItems.doc(childrenId);
    await transaction.update(exSubItem, {
      parent: null,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: loggedInUserId,
    });
  }
};

/**
 * Updates multiples items with the same field values.
 * Used for syncing new children with its parent fields for example ["location", "status", "user"]
 * @param transaction
 * @param workspaceItems
 * @param itemsToBeUpdatedIds Ids of items whose data will be updated.
 * @param updatedFields Values that will be updated for all given items the same way.
 */
export const updateItems = async (
  transaction: firebase.firestore.Transaction,
  workspaceItems: firebase.firestore.CollectionReference,
  itemsToBeUpdatedIds: string[],
  updatedFields: Record<string, unknown>,
): Promise<void> => {
  for (const itemToBeUpdated of itemsToBeUpdatedIds) {
    const itemToUpdate = workspaceItems.doc(itemToBeUpdated);
    await transaction.update(itemToUpdate, updatedFields);
  }
};

/**
 * Return array of keys that are included in the oldMap and NOT in the newMap.
 * @param oldMap
 * @param newMap
 */
export const findRemovedKeys = (
  oldMap: Record<string, unknown>,
  newMap: Record<string, unknown>,
): string[] => {
  const oldKeys = Object.keys(oldMap);
  const newKeys = Object.keys(newMap);
  return oldKeys.filter((key) => !newKeys.includes(key));
};
