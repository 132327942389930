import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  progress: {
    display: "block",
    margin: theme.spacing(0.3),
    marginLeft: "auto",
    marginRight: "auto",
    height: 60,
  },
  containerWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
  },
  fullScreenContainer: {
    display: "grid",
    placeItems: "center",
    height: "100vh",
    width: "100vw",
    backdropFilter: "brightness(0.9)",
    WebkitBackdropFilter: "brightness(0.9)",
  },
}));

export default useStyles;
