import React from "react";
import { useParams, Link } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Locals
import Attachments from "./attachments";
import {
  ConnectedAutoCompleteSelect,
  MultiAutoCompleteSelect,
} from "../../components/autocompleteSelect";
import SubItems from "./subItems";
import routes from "../../routes";
import { useData, useOrdered } from "../../firebase";
import { useIsAdmin } from "../../authentication/hooks";
import { itemNameMaxLength } from "../commonVariables";
import { firestoreQuery } from "../../constants";
import DatePicker from "../../components/datePicker";

// Styles
import useStyles from "../styles";
import { itemsDetailAvatarSize } from "../../globalStyles";

// Types
import type { SingleItemState, SingleItemErrorState } from "../types";
import type { WorkspaceUser } from "../../users/types";
import type { WsParams } from "../../routes";

const helperText = `Name must contain between 3 and ${itemNameMaxLength} characters.`;

type OnChangeType = (value: string | string[] | boolean, field: string) => void;

interface Props {
  errorState: SingleItemErrorState;
  handleOnChange: OnChangeType;
  state: SingleItemState;
  type: "item" | "userInfoItem";
  deactivatedUser?: boolean;
}

const SingleItemBody: React.FC<Props> = ({
  errorState,
  handleOnChange,
  type,
  state,
  deactivatedUser,
}) => {
  const { workspaceId } = useParams<WsParams>();
  const classes = useStyles();
  const users = useOrdered<WorkspaceUser>(firestoreQuery.wsUsers);
  const { isAdmin } = useIsAdmin();

  // Sub items and parents
  const childrenSelector = Object.keys(state?.children || {});
  const subItems = useData<SingleItemState[]>(
    firestoreQuery.wsItems,
    childrenSelector.length ? childrenSelector : [],
  );
  const parent = useData<SingleItemState>(firestoreQuery.wsItems, state?.parent || []);

  const selectUserOptions = React.useMemo(
    () =>
      users
        ?.filter((user) =>
          deactivatedUser ? user.id === state.user || !user.deactivated : !user.deactivated,
        )
        .map((user) => ({
          title: user.email,
          value: user.id,
        })),
    [users, deactivatedUser, state],
  );

  const eventOnChangeHandler = React.useCallback(
    (event, field?: string) => {
      if (event?.target?.name) {
        const { name, value } = event.target;
        handleOnChange(value, name);
      } else if (field) {
        handleOnChange(event, field);
      }
    },
    [handleOnChange],
  );

  const avatarStyle = {
    display: "grid",
    placeItems: "center",
    height: itemsDetailAvatarSize,
    width: itemsDetailAvatarSize,
  };

  const getUser = users?.find((item) => item.id === state.user);

  return (
    <div className={classes.singleItemBody}>
      <TextField
        data-cy="single-item-name"
        name="name"
        label="Name"
        variant="outlined"
        error={errorState.name}
        helperText={errorState.name && helperText}
        required
        value={state.name || ""}
        onChange={eventOnChangeHandler}
      />
      <TextField
        data-cy="single-item-internal-number"
        label="Internal Number"
        name="internalNumber"
        value={state.internalNumber || ""}
        onChange={eventOnChangeHandler}
      />
      <ConnectedAutoCompleteSelect
        data-cy="single-item-type"
        label="Type"
        name="type"
        variant="outlined"
        value={state.type}
        setValue={handleOnChange}
        creatable
      />
      <TextField
        data-cy="single-item-serial-number"
        label="Service Tag / Serial Number"
        name="serialNumber"
        value={state.serialNumber || ""}
        onChange={eventOnChangeHandler}
      />
      <div className={classes.subGridTwoCols}>
        <DatePicker
          data-cy="single-item-bought"
          label="Bought"
          value={state.bought}
          onChange={(date) => eventOnChangeHandler(date, "bought")}
        />
        <TextField
          data-cy="single-item-invoice-number"
          label="Invoice Number"
          name="invoiceNumber"
          value={state.invoiceNumber || ""}
          onChange={eventOnChangeHandler}
        />
      </div>

      <div className={classes.subGridThreeCols}>
        <div className={classes.subGridTwoCols}>
          <ConnectedAutoCompleteSelect
            data-cy="single-item-location"
            label="Location"
            name="location"
            value={state.location}
            setValue={handleOnChange}
            creatable
          />
          <ConnectedAutoCompleteSelect
            data-cy="single-item-cost-center"
            label="Cost Center"
            name="costCenter"
            value={state.costCenter}
            setValue={handleOnChange}
            creatable
          />
        </div>
      </div>
      <TextField
        data-cy="single-item-notes"
        name="notes"
        value={state.notes || ""}
        label="Notes"
        rows={5}
        multiline
        variant="outlined"
        onChange={eventOnChangeHandler}
      />
      <div className={classes.subGridThreeCols}>
        <MultiAutoCompleteSelect
          data-cy="single-item-tags"
          label="Tags"
          name="tags"
          value={state.tags}
          setValue={handleOnChange}
        />
        <div className={state.user ? classes.subGridIcon : ""}>
          {state.user ? (
            <Button
              data-cy="menu-users-inactive"
              size="small"
              component={Link}
              to={routes.workspaceUserInfo(workspaceId, `${state.user}`)}
            >
              <Avatar data-cy={"user-avatar"} style={avatarStyle} src={getUser?.photoURL} />
            </Button>
          ) : null}
          <ConnectedAutoCompleteSelect
            data-cy="single-item-user"
            label="User"
            name="user"
            value={state.user}
            setValue={handleOnChange}
            options={selectUserOptions}
          />
        </div>
        <div className={classes.subGridTwoCols}>
          <DatePicker
            data-cy="single-item-warranty-due"
            label="Warranty Due"
            value={state.warrantyUntil}
            onChange={(date) => eventOnChangeHandler(date, "warrantyUntil")}
          />
          <ConnectedAutoCompleteSelect
            creatable
            data-cy="single-item-status"
            label="Status"
            name="status"
            value={state.status}
            setValue={handleOnChange}
          />
        </div>
      </div>
      {isAdmin && <Attachments type={type} />}
      <SubItems
        parent={{ data: state.parent ? parent : undefined, id: state.parent ?? undefined }}
        subItems={subItems}
        type={type}
      />
      <FormControlLabel
        className={classes.checkboxItem}
        classes={{ root: classes.checkboxClasses }}
        labelPlacement="start"
        control={
          <Checkbox
            data-cy="single-item-extended-warranty"
            name="extendedWarranty"
            onChange={(e) => handleOnChange(e.target.checked, "extendedWarranty")}
            checked={!!state.extendedWarranty}
          />
        }
        label="Extended Warranty"
      />
    </div>
  );
};

export default SingleItemBody;
