import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

// Locals
import useCustomSnackbar from "../snackbar";
import { useCurrentWsDetail, useLoading } from "../../firebase";
import Loading from "../loading";
import { hideDuplicates, isNotAlreadyAnOption } from "./helpers";
import { firestoreQuery } from "../../constants";

// Types
import type { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import type { TextFieldProps } from "@material-ui/core/TextField";
import type { Option } from "./types";

// Styles
import useStyles from "./styles";

interface Props extends Pick<TextFieldProps, "variant" | "label" | "name" | "placeholder"> {
  options?: Option[];
  value: string[];
  disableClearable?: boolean;
  setValue: (values: string[], fieldId: string) => void;
  creatable?: boolean;
}

const filter = createFilterOptions<Option>();

const MultipleAutoCompleteSelect: React.FC<Props> = ({
  options, // If true -> these options will be rendered
  creatable, // If true -> add "item name" option will be added.
  name = "", // Name should match with the field in the state - DB - id of the specific field if in currentWsDetails.fields
  setValue,
  value = [],
  disableClearable,
  placeholder = "Type and select one.",
  ...other
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { fields } = useCurrentWsDetail();
  const loading = useLoading(firestoreQuery.currentWsDetail);

  const autoCompleteOnChange = React.useCallback(
    (_, newValuesArray: Array<string | Option>) => {
      // Iterate through to avoid duplicates
      const cleanNewValues = hideDuplicates(newValuesArray, enqueueSnackbar);

      setValue(cleanNewValues, name);
    },
    [enqueueSnackbar, name, setValue],
  );

  const filteredOptions = React.useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      // Suggest the creation of a new value
      if (creatable && params.inputValue && isNotAlreadyAnOption(options, params.inputValue)) {
        filtered.push({
          inputValue: params.inputValue,
          title: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    [creatable],
  );

  const finalOptions: Option[] =
    options || fields?.[name]?.values.map((value: string) => ({ title: value, value })) || [];

  const valueToDisplay: Option[] = value.map((item) => ({ title: item }));

  if (loading) return <Loading />;

  return (
    <Autocomplete
      multiple
      value={valueToDisplay}
      onChange={autoCompleteOnChange}
      filterOptions={filteredOptions}
      autoHighlight={true}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disableClearable={disableClearable}
      options={finalOptions}
      getOptionLabel={(option: { title: string }) => option.title}
      renderOption={(option: { title: string }) => option.title}
      freeSolo
      classes={{ input: classes.placeholder }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} {...other} placeholder={placeholder} />
      )}
    />
  );
};

export default MultipleAutoCompleteSelect;
