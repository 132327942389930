import React from "react";

// Material UI
import MaterialTable, { Column, MaterialTableProps } from "material-table";

// Local
import {
  customFilterAndSearch,
  filterData,
  getCurrentShownColumns,
  handleColumnDragged,
  handleParentChildData,
  parseFiltersToQS,
  parseQSToFilters,
  prepareColumnsFromLocalStorage,
} from "./helpers/filter";
import { useUserId } from "../../authentication/hooks";
import { tableSelectedRowColor, tableSubItemRowColor } from "../../globalStyles";
import { localStorageKey } from "../../constants";
import Toolbar from "./toolbar";
import { Loading } from "../index";

// Types
import type { IFilters } from "./filter/types";
import type { MyColumn, RowData } from "./types";
import type { ToolbarProps } from "./toolbar";
import { exportCsv } from "./helpers/export";

type MuiTableProps = MaterialTableProps<RowData>;

interface Props
  extends Pick<
    MuiTableProps,
    | "columns"
    | "data"
    | "style"
    | "isLoading"
    | "actions"
    | "onRowClick"
    | "onSelectionChange"
    | "title"
  > {
  generateTableActions?: (secondary?: boolean) => MuiTableProps["actions"];
  type: "user" | "item" | "userInfoItem" | "warrantyEnding";
  toolbarProps?: Partial<ToolbarProps>;
  secondaryData?: MuiTableProps["data"];
  additionalOptions?: MuiTableProps["options"];
  enableSwitch?: boolean;
  simple?: boolean;
  search?: string;
  replace?: (path: string) => void;
}

const defaultTableOptions = {
  exportAllData: true,
  searchFieldVariant: "outlined" as const,
  searchFieldStyle: {
    height: 36,
    marginRight: 11,
  },
  searchAutofocus: true,
  pageSizeOptions: [10, 50, 100, 1000],
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
};

const Table: React.FC<Props> = ({
  additionalOptions,
  toolbarProps,
  columns,
  data,
  onRowClick,
  type,
  secondaryData,
  enableSwitch,
  generateTableActions,
  actions,
  simple,
  search,
  replace,
  ...props
}) => {
  const [selectedRow, setSelectedRow] = React.useState<string | null>(null);
  const [columnsState, setColumnsState] = React.useState<Column<RowData>[]>(columns);
  const [showSecondary, setShowSecondary] = React.useState<boolean>(false);
  const [isRegex, setIsRegex] = React.useState<boolean>(false);
  const preparedColumnsFromLocalStorage = React.useRef<boolean>();
  const loggedInUserId = useUserId();

  /**
   * Used for displaying columns based on setting stored in local storage if any when table mounts for the first time.
   */
  React.useEffect(() => {
    if (simple) {
      setColumnsState(columns);
    } else if (!preparedColumnsFromLocalStorage.current) {
      const customFilter = customFilterAndSearch(setIsRegex);

      const columnsSettings = localStorage.getItem(
        localStorageKey.shownColumns(type, loggedInUserId),
      );

      if (!columnsSettings) {
        const visibleColumnIndex = columns.findIndex((c) => !c.hidden);
        columns[visibleColumnIndex].customFilterAndSearch = customFilter;
        setColumnsState(columns);
        return;
      }

      const parsedShownColumns: string[] = JSON.parse(columnsSettings);

      const newColumnsState = prepareColumnsFromLocalStorage(parsedShownColumns, columns);

      const visibleColumnIndex = newColumnsState.findIndex((c) => !c.hidden);
      newColumnsState[visibleColumnIndex].customFilterAndSearch = customFilter;

      preparedColumnsFromLocalStorage.current = true;
      setColumnsState(newColumnsState);
    }
  }, [columns, simple, loggedInUserId, type]);

  /**
   * Used when hiding/showing columns
   */
  const handleOnChangeColumnHidden = React.useCallback(() => {
    const currentShownColumns = getCurrentShownColumns(columnsState);

    localStorage.setItem(
      localStorageKey.shownColumns(type, loggedInUserId),
      JSON.stringify(currentShownColumns),
    );
  }, [columnsState, loggedInUserId, type]);

  const handleOnRowClick: MuiTableProps["onRowClick"] = React.useCallback(
    (event, selectedRow?: RowData) => {
      setSelectedRow(selectedRow?.id as string);
      onRowClick && onRowClick(event, selectedRow);
    },
    [onRowClick],
  );

  const pageSize = React.useMemo<number>(
    () =>
      parseInt(localStorage.getItem(localStorageKey.itemsPerPage(type, loggedInUserId)) ?? "10"),
    [loggedInUserId, type],
  );

  const options: MuiTableProps["options"] = React.useMemo(
    () => ({
      pageSize,
      columnsButton: !simple,
      exportButton: !simple,
      exportCsv: (columns, data) => exportCsv(columns, data, type),
      paging: !simple,
      rowStyle: (rowData: RowData) => ({
        backgroundColor:
          selectedRow === rowData.id
            ? tableSelectedRowColor
            : rowData.parent
            ? tableSubItemRowColor
            : undefined,
      }),
      ...defaultTableOptions,
      ...additionalOptions,
    }),
    [additionalOptions, simple, pageSize, selectedRow, type],
  );

  const onChangeRowsPerPage = React.useCallback(
    (rows) => {
      localStorage.setItem(localStorageKey.itemsPerPage(type, loggedInUserId), rows);
    },
    [loggedInUserId, type],
  );

  const filters = React.useMemo<IFilters>(() => {
    if (search) {
      localStorage.setItem(localStorageKey.filters(type, loggedInUserId), search);
      return parseQSToFilters(search, columns as MyColumn[]);
    }
    const localData = localStorage.getItem(localStorageKey.filters(type, loggedInUserId));
    if (localData) {
      replace?.(`${document.location.pathname}${localData}`);
      return parseQSToFilters(localData, columns as MyColumn[]);
    }
    return {};
  }, [columns, loggedInUserId, search, type, replace]);

  const handleSetFilters = React.useCallback(
    (filters: IFilters) => {
      const search = parseFiltersToQS(filters);
      if (search) {
        localStorage.setItem(localStorageKey.filters(type, loggedInUserId), search);
        replace?.(`${document.location.pathname}?${search}`);
      } else {
        localStorage.removeItem(localStorageKey.filters(type, loggedInUserId));
        replace?.(document.location.pathname);
      }
    },
    [loggedInUserId, replace, type],
  );

  const switchProps =
    type === "user"
      ? {
          value: showSecondary,
          handleOnChange: setShowSecondary,
          toggleName: "showDeactivatedUsers",
          toggleTitle: "Show deactivated users",
          helperTitle:
            "Show only users that have been deactivated. You can change users deactivated status by click on" +
            " action button in the table row.",
        }
      : {
          value: showSecondary,
          handleOnChange: setShowSecondary,
          toggleName: "showArchivedItems",
          toggleTitle: "Show Archived items",
          helperTitle:
            "Show only items that have archived status. Change which statuses are marked as archived in" +
            " workspace overview. By default it is 'removed'",
        };

  const handleResetColumns = React.useCallback(() => {
    localStorage.removeItem(localStorageKey.shownColumns(type, loggedInUserId));
    setColumnsState(columns);
  }, [columns, loggedInUserId, type]);

  return (
    <MaterialTable
      onChangeRowsPerPage={onChangeRowsPerPage}
      onRowClick={handleOnRowClick}
      options={options}
      parentChildData={handleParentChildData}
      onChangeColumnHidden={handleOnChangeColumnHidden}
      data={filterData(filters, (showSecondary ? secondaryData : data) as RowData[])}
      columns={columnsState}
      onColumnDragged={handleColumnDragged(
        localStorageKey.shownColumns(type, loggedInUserId),
        columnsState,
      )}
      components={{
        OverlayLoading: (props) => <Loading logo fullScreen {...props} />,
        Toolbar: !simple
          ? (props) => (
              <Toolbar
                filters={filters}
                setFilters={handleSetFilters}
                enableSwitch={enableSwitch}
                isRegex={isRegex}
                switchProps={switchProps}
                columns={columnsState}
                handleResetColumns={handleResetColumns}
                {...props}
                {...toolbarProps}
              />
            )
          : () => null,
      }}
      actions={generateTableActions?.(showSecondary) ?? actions}
      {...props}
    />
  );
};

export default Table;
