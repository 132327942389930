import React from "react";

import FilterContainsString from "./filterContainsString";
import FilterMultiSelect from "./filterMultiSelect";
import FilterDate from "./filterDate";
import FilterBool from "./filterBool";
import { FilterType, FilterSpecification } from "./types";

interface Props {
  field: string;
  label: string;
  onChange: (newValue: FilterSpecification | null, field: string) => void;
  value?: FilterSpecification;
  type?: FilterType;
  focus?: boolean;
}

const FilterRowSwitch: React.FC<Props> = ({ type = FilterType.ContainsStr, ...props }) => {
  switch (type) {
    case FilterType.ContainsStr:
      return <FilterContainsString {...props} />;
    case FilterType.Date:
      return <FilterDate {...props} />;
    case FilterType.MultiSelect:
      return <FilterMultiSelect {...props} />;
    case FilterType.Bool:
      return <FilterBool {...props} />;
  }
};

export default FilterRowSwitch;
