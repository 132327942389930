import React from "react";

// Material-ui
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// Material-ui icons
import CloseIcon from "@material-ui/icons/Close";
import OpenInNew from "@material-ui/icons/OpenInNew";

// Style
import useStyles from "../styles";

// Api
import { useGetFileUrl } from "../api";

// Locals
import Loading from "../../components/loading";

type Props = {
  text: string;
  filePath: string;
};

const AttachmentModal: React.FC<Props> = ({ text, filePath }) => {
  const classes = useStyles();
  const getFileUrl = useGetFileUrl();

  const [isOpen, setOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState<string | undefined>(undefined);

  const setModalOpen = () => setOpen(true);
  const setModalClosed = () => setOpen(false);
  const openInNewTab = () => window.open(fileUrl, "_blank");

  React.useEffect(() => {
    if (fileUrl || !isOpen) return;

    (async () => {
      setFileUrl(await getFileUrl(filePath));
    })();
  }, [isOpen, filePath, fileUrl, getFileUrl]);

  return (
    <>
      <div onClick={setModalOpen} className={classes.attachment}>
        {text}
      </div>
      <Modal open={isOpen} onClose={setModalClosed}>
        <Box className={classes.attachmentModal}>
          <Box className={classes.attachmentModalHeader}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {text}
            </Typography>
            <Box>
              <IconButton disabled={!fileUrl} onClick={openInNewTab}>
                <OpenInNew />
              </IconButton>
              <IconButton onClick={setModalClosed}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.attachmentModalContent}>
            {!fileUrl ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  height: "100%",
                }}
              >
                <Loading size={120} />
              </div>
            ) : (
              <iframe title={`file:${text}`} src={fileUrl} width="100%"></iframe>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AttachmentModal;
