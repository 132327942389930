import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

// Local
import ConfirmationDialog from "../components/confirmationDialog";
import AddUser from "./create/addUser";
import Loading from "../components/loading";
import Table from "../components/table";
import routes from "../routes";
import defaultColumns from "./columns";
import { generateTableActions, prepareUsersData } from "./helpers";
import { firestoreQuery } from "../constants";

// Api / Hooks
import { useLoading, useData, useCurrentWsDetail } from "../firebase";
import { useUpdateUser } from "../userInfo/api";
import { useIsAdmin } from "../authentication/hooks";

// Styles
import { usersTableAvatarSize } from "../globalStyles";
import useStyles from "./styles";

// Types
import type { Params } from "../routes";
import type { WorkspaceUser } from "./types";
import { RowData } from "../components/table/types";
import { defaultDateFormatOption } from "../workspace/detail/dateFormatOptionSelect";

const Users: React.FC = () => {
  const classes = useStyles();
  // const removeUserFromWs = useRemoveUser();
  const { isAdmin } = useIsAdmin();
  const { name, dateFormat } = useCurrentWsDetail() ?? {};
  const updateUser = useUpdateUser();
  const { workspaceId } = useParams<Params>();
  const { push } = useHistory();

  // If we click [action button for deactivating user] in table, clicked users id is set here
  // and confirmation dialog is opened.
  const [userToBeDeactivated, setUserToBeDeactivated] = React.useState<RowData | undefined>();

  const prepareNameField = React.useCallback(
    (userData: WorkspaceUser) => {
      const avatarStyle = {
        display: "grid",
        placeItems: "center",
        height: usersTableAvatarSize,
        width: usersTableAvatarSize,
      };
      return (
        <div className={classes.nameField}>
          <div className={classes.nameGrid}>
            <Avatar data-cy={"user-avatar"} style={avatarStyle} src={userData?.photoURL} />
            <div className={classes.nameText}>{userData?.displayName}</div>
          </div>
        </div>
      );
    },
    [classes],
  );

  const handleUserDeactivate = React.useCallback(async () => {
    setUserToBeDeactivated(undefined);
    if (!userToBeDeactivated?.id) return;

    // Here we want to set attribute deactivated and save to DB
    const data = { deactivated: !userToBeDeactivated.deactivated };
    updateUser(workspaceId as string, userToBeDeactivated.id as string, data);
  }, [userToBeDeactivated, updateUser, workspaceId]);

  const loading = useLoading(firestoreQuery.wsUsers);
  const users = useData<WorkspaceUser>(firestoreQuery.wsUsers);

  const onRowClick = React.useCallback(
    (e, selectedRow) => {
      if (e.target.type !== "checkbox") {
        push(routes.workspaceUserInfo(workspaceId, selectedRow?.id as string));
      }
    },
    [push, workspaceId],
  );

  const handleOnNotificationsChange = React.useCallback(
    async (e) => {
      e.stopPropagation();
      const { checked, name: userId } = e.target;
      await updateUser(workspaceId as string, userId, {
        ...users[userId],
        notificationSettings: { all: checked },
      });
    },
    [updateUser, users, workspaceId],
  );

  const { active, deactivated } = React.useMemo(
    () => prepareUsersData(users, prepareNameField, handleOnNotificationsChange),
    [users, prepareNameField, handleOnNotificationsChange],
  );

  if (loading) return <Loading />;

  return (
    <>
      <div className={classes.tableWrap}>
        <Table
          title={`${name} - Users`}
          data={active}
          secondaryData={deactivated}
          columns={defaultColumns(dateFormat ?? defaultDateFormatOption)}
          type="user"
          toolbarProps={{
            addDataComponent: (
              <Button
                data-cy="btn-add-user"
                className={classes.addButton}
                color="secondary"
                variant="contained"
                size="medium"
                component={Link}
                to={routes.addUserToWorkspace(workspaceId)}
              >
                Add new user
              </Button>
            ),
          }}
          enableSwitch
          // Not implemented yet
          // onDeleteRowClick={onTableUserDelete}
          onRowClick={onRowClick}
          generateTableActions={
            isAdmin ? generateTableActions(setUserToBeDeactivated, workspaceId) : undefined
          }
        />
      </div>
      <AddUser />
      {
        // there was problem with dialog closing one frame later after the message was changed, bad UX
        userToBeDeactivated && (
          <ConfirmationDialog
            open={Boolean(userToBeDeactivated)}
            title={`Do you really want to ${
              userToBeDeactivated?.deactivated ? "" : "de"
            }activate this user?`}
            message={
              userToBeDeactivated?.deactivated
                ? `Clicking YES will re-activate this user (${userToBeDeactivated?.email}).`
                : "Clicking" +
                  ` YES will deactivate this user (${userToBeDeactivated?.email}). You can find deactivated users by toggling switch in the header of` +
                  " users table."
            }
            onCancel={() => setUserToBeDeactivated(undefined)}
            onConfirm={handleUserDeactivate}
          />
        )
      }
    </>
  );
};

export default Users;
