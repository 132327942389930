// Material UI
import makeStyles from "@material-ui/core/styles/makeStyles";

import { tableFilteringWidth } from "../../../globalStyles";

const useStyles = makeStyles((theme) => ({
  filterOpener: {
    marginLeft: theme.spacing(1),
  },
  filters: {
    position: "absolute",
    top: 50,
    left: 0,
    zIndex: 100,
    padding: theme.spacing(4, 4, 2, 4),
  },
  filtersBody: {
    display: "grid",
    grid: "auto-flow / 1fr 2fr 1fr 2fr",
    gridGap: theme.spacing(3, 2),
    width: tableFilteringWidth,
    paddingBottom: theme.spacing(2),
  },
  filterInputWrap: {
    position: "relative",
  },
  filterTextField: {
    height: 34,
    padding: 1,
    marginLeft: theme.spacing(1),
  },
  filtersActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  filterContainStringInput: {
    fontSize: 14,
    padding: "7px 0",
  },
  filterDateRow: {
    gridColumnStart: 1,
    gridColumnEnd: 5,
    display: "grid",
    alignItems: "center",
    grid: "auto-flow / 1fr 5fr",
  },
  filterDateInputsSection: {
    marginLeft: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  filterDateCell: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterLabel: {
    lineHeight: "28px", // height of the row div
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  filterDateSubLabel: {
    whiteSpace: "nowrap",
    lineHeight: "28px", // height of the row div
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  listOfTags: {
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: 1,
    padding: 0,
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
