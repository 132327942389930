import { isNameValid, isValidDate, isNameLengthOk } from "../../helpers/validations";
import { itemNameMaxLength } from "../commonVariables";
import type { SingleItemState, SingleItemErrorState, keyType } from "../types";
import { DateTime } from "luxon";

type ValidationResponse = {
  isValid?: boolean;
  changed?: boolean;
  newErrorState: Record<string, boolean>;
};

const beforeSubmitValidation = (
  item: SingleItemState,
  errors: SingleItemErrorState,
): ValidationResponse => {
  let isValid = true;
  const newErrorState = {
    ...errors,
  };

  if (!isNameValid(item.name, itemNameMaxLength)) {
    isValid = false;
    newErrorState.name = true;
  }

  if (item.bought && !isValidDate(item.bought)) {
    isValid = false;
    newErrorState.bought = true;
  }

  if (item.warrantyUntil && !isValidDate(item.warrantyUntil)) {
    isValid = false;
    newErrorState.warrantyDue = true;
  }

  return {
    isValid,
    newErrorState,
  };
};

const onChangeValidation = (
  field: keyType,
  newValue: string | string[] | DateTime | boolean,
  errors: SingleItemErrorState,
): ValidationResponse => {
  let changed = false;
  const newErrorState = { ...errors };

  // WTF is this bullshit? How it used to work? PV
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (field === "name" && isNameLengthOk(newValue as string, itemNameMaxLength)) {
    changed = true;
    newErrorState[field] = false;
  }

  if (
    (field === "bought" || field === "warrantyUntil") &&
    (!newValue || isValidDate(newValue as DateTime))
  ) {
    changed = true;
    newErrorState[field] = false;
  }

  return {
    changed,
    newErrorState,
  };
};

export { beforeSubmitValidation, onChangeValidation };
