const lastUsedWorkspace = (userId?: string): string => `lastUsedWorkspace-${userId}`;
const shownColumns = (type: string, userId?: string): string =>
  `shownColumns-${type}Table-${userId}`;
const itemsPerPage = (type: string, userId?: string): string =>
  `itemsPerPage-${type}Table-${userId}`;
const filters = (type: string, userId?: string): string => `filters-${type}Table-${userId}`;
const lastItems = (wsId: string, userId?: string): string => `lastItems-${wsId}-${userId}`;
const featureFlag = (flag: string): string => `feature-flag.${flag}`;

export const localStorageKey = {
  lastUsedWorkspace,
  shownColumns,
  itemsPerPage,
  filters,
  lastItems,
  featureFlag,
};

const currentUser = "currentUser";
const currentWsUser = "currentWsUser";
const wsUsers = "wsUsers";
const wsItems = "wsItems";
const currentWsDetail = "currentWsDetail";
const currentWsUserItems = "currentWsUserItems";

export const firestoreQuery = {
  currentUser,
  currentWsUser,
  wsUsers,
  wsItems,
  currentWsDetail,
  currentWsUserItems,
};
