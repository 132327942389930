import React from "react";

import Toggle, { ToggleProps } from "./toggle";
import { HelpIcon } from "../buttons";

interface Props {
  toggleName: string;
  toggleTitle: string;
  helperTitle: string;
  handleOnChange: (show: boolean, name: string) => void;
  value: boolean;
}

/**
 * Used for switching between regular and archived data or users and removed users in table toolbar.
 * @param toggleName
 * @param toggleTitle
 * @param helperTitle
 * @param props
 * @constructor
 */
const ShowSwitch: React.FC<Omit<ToggleProps, "title" | "name"> & Props> = ({
  toggleName,
  toggleTitle,
  helperTitle,
  ...props
}) => {
  return (
    <>
      <Toggle name={toggleName} title={toggleTitle} {...props} />
      <HelpIcon data-cy={"help-icon"} title={helperTitle} />
    </>
  );
};

export default ShowSwitch;
