import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  drawerWidth,
  minDrawerWidth,
  formLightBackgroundColor,
  textFieldLabelGrayLike,
  justifySpaceBetween,
} from "../globalStyles";

const useStyles = makeStyles((theme) => ({
  attachmentsWrap: {
    padding: theme.spacing(2, 1, 1, 2),
    borderRadius: theme.shape.borderRadius,

    maxHeight: 100,
    overflowY: "scroll",

    border: `1px solid #BCBCBC`,
    display: "flex",
    flexDirection: "column",

    marginBottom: theme.spacing(0.5),
  },
  attachmentItem: {
    minHeight: 24,
    alignItems: "center",
    display: "flex",
    ...justifySpaceBetween,
  },
  attachment: {
    whiteSpace: "nowrap",
    maxWidth: "min(20vw, 300px)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",
  },
  attachmentButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  attachmentsLabelWrap: {
    backgroundColor: formLightBackgroundColor,
    position: "absolute",
    zIndex: 2500,
    left: 6,
    top: -10,
    padding: "0 5px",
  },
  attachmentsLabel: {
    color: textFieldLabelGrayLike,
    fontSize: "0.9rem",
  },
  attachmentModal: {
    backgroundColor: formLightBackgroundColor,
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "95vh",
    display: "flex",
    flexDirection: "column",
  },
  attachmentModalHeader: {
    display: "flex",
    ...justifySpaceBetween,
  },
  attachmentModalContent: {
    display: "flex",
    flexGrow: 1,
  },
  relative: {
    position: "relative",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 240,
  },
  singleItemPaper: {
    width: drawerWidth, // vw
    minWidth: minDrawerWidth, //px
  },
  drawerHeader: {
    display: "flex",
    ...justifySpaceBetween,
    alignItems: "center",
    padding: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[3],
  },
  singleItemBodyWrap: {
    padding: theme.spacing(2),
  },
  addItemBody: {
    // wrap looking
    padding: theme.spacing(3, 1),
    borderRadius: theme.shape.borderRadius,
    // content layout
    display: "grid",
    grid: "auto-flow / 1fr 1fr",
    gridGap: theme.spacing(3, 4),
  },
  singleItemBody: {
    // wrap looking
    padding: theme.spacing(4),
    backgroundColor: formLightBackgroundColor,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    // content layout
    display: "grid",
    grid: "auto-flow / 1fr 1fr",
    gridGap: theme.spacing(3, 7),
  },
  subGridTwoCols: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3),
  },
  subGridIcon: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridColumnGap: theme.spacing(1),
  },
  subGridThreeCols: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(3, 8),
  },
  drawerActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(1, 2),
  },
  marginRight: {
    marginRight: theme.spacing(6),
  },
  addButton: {
    backgroundColor: theme.palette.secondary.light,
    minWidth: 100,
  },
  removeActionsMarginTop: {
    marginTop: -30,
  },
  checkboxItem: {
    margin: theme.spacing(1, 0),
    alignSelf: "start",
    justifySelf: "start",
  },
  checkboxClasses: {
    margin: 0,
  },
}));

export default useStyles;
