import React from "react";

// Material UI
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// TODO: Put into types.
interface RowData extends Record<string, unknown> {
  id: string;
  parentId?: string;
  tableData?: {
    checked?: boolean;
  };
}

const sortByIsSubItem = (a: RowData, b: RowData): number => {
  if (!!a.parent === !!b.parent) {
    return 0;
  }

  if (a.parent) {
    return -1;
  }

  return 1;
};
const defaultColumns = [
  {
    // To be able to see already selected items at the top by default.
    title: "SELECTED",
    render: (row: RowData): boolean => !!row?.tableData?.checked,
    hidden: true,
  },
  {
    title: "Is sub-item",
    customSort: sortByIsSubItem,
    defaultSort: "desc" as const,
    render: (row: RowData): React.ReactElement | null =>
      row.parent ? (
        <Tooltip title="Is already assigned to some item.">
          <ErrorOutlineIcon color="action" />
        </Tooltip>
      ) : null,
    tooltip: "Is assigned to another item as it's sub-item.",
    align: "center" as const,
    cellStyle: {
      maxWidth: 50,
      width: 50,
    },
    headerStyle: {
      maxWidth: 50,
      width: 50,
      textAlign: "center" as const,
    },
  },
  { title: "Name", field: "name" },
  { title: "Type", field: "type" },
  { title: "Internal Number", field: "internalNumber" },
];

export default defaultColumns;
