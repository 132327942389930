import { selectWorkspace } from "./actions";
import { initialState } from "./state";

// Types
import type { Action } from "redux-toolbelt";
import type { NavigationState } from "./state";

const reducer = (state = initialState, action: Action): NavigationState => {
  switch (action.type) {
    case selectWorkspace.TYPE:
      return { ...state, workspace: action.payload };

    default:
      return state;
  }
};

export default reducer;
