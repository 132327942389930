// Material UI
import { makeStyles, Theme } from "@material-ui/core/styles";

// Locals
import { tableBorderRadius } from "../../globalStyles";

type Props = {
  simpleToolbar?: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  regexIndicator: {
    width: "0.825em",
    display: "grid",
    placeItems: "center",
    position: (props) => (props.simpleToolbar ? "relative" : undefined),
  },
  trailing: {
    width: 0,
    position: (props) => (props.simpleToolbar ? "absolute" : "relative"),
    left: (props) => (props.simpleToolbar ? undefined : theme.spacing(28)),
    right: (props) => (props.simpleToolbar ? theme.spacing(12) : undefined),
    zIndex: 1,
  },
  resetButton: {
    marginLeft: -12,
  },
  removedButton: {
    backgroundColor: theme.palette.secondary.light,
    marginLeft: theme.spacing(1),
  },
  searchInput: {
    borderColor: "red",
  },
  notchedOutline: {
    borderColor: "red",
  },
  toolbarRoot: {
    padding: 0,
    paddingRight: theme.spacing(1),
    width: (props) => (props.simpleToolbar ? "100%" : undefined),
    justifyContent: (props) => (props.simpleToolbar ? "space-between" : undefined),
  },
  toolbarActions: {
    display: (props) => (props.simpleToolbar ? "none" : undefined),
    minWidth: 100,
  },
  toolbarSpacer: {
    display: (props) => (props.simpleToolbar ? "none" : undefined),
    flex: "unset",
  },
  toolBarWrap: {
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: tableBorderRadius,
  },
  searchField: {
    padding: 0,
    height: 36,
    marginLeft: 11,
    width: theme.spacing(32),
    "& .MuiInputAdornment-positionEnd": {
      position: "relative",
      right: theme.spacing(2),
    },
  },
  toolbarLeftSideWrap: {
    display: (props) => (props.simpleToolbar ? "none" : "flex"),
    padding: `${theme.spacing(1)}px`,
    justifyContent: "space-around",
    alignItems: "center",
  },
  toolbarRightSideWrap: {
    width: (props) => (props.simpleToolbar ? "100%" : undefined),
    minWidth: 350, // So the search input does not squash much
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: (props) => (props.simpleToolbar ? "right" : "space-around"),
    alignItems: "center",
  },
}));

export default useStyles;
